import React, { useState } from "react";
import { Button, Modal } from "reactstrap";
import SectionLoader from "../../../components/SectionLoader";
import useHook from "./use-hook";
import { hideLoading, showLoading } from "../../../utils/loading";
import ResourceOrderSplitItem from "../../SchoolResources/components/ResourceOrderSplitItem";
import "./styles.scss";

const SplitOrderModal = ({ order, toggle, refresher }) => {
  const [error, setError] = useState("");
  const {
    loading,
    requests,
    updateRequestQuantity,
    toggleSelectRequest,
    splitOrder
  } = useHook(order.id, order["Requests"], refresher);

  const split = async () => {
    try {
      showLoading("Spliting...");
      await splitOrder();
      hideLoading();
      refresher();
      toggle();
    } catch (error) {
      hideLoading();
      setError("Failed: unknown issue");
    }
  }

  return (
    <Modal
      className="modal-dialog-centered resource-order-split-modal"
      isOpen
      toggle={toggle}
      size="lg"
    >
      <div className="modal-header">
        <h5 className="modal-title" id="modal-title-default">Split Order</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={toggle}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <center><p>Please select the items you are able to ship to fulfill an initial order. Any items not selected will be split into a new order/request to await fulfillment.</p></center>
        {loading ? (
          <SectionLoader />
        ) : (
          <div className="review-request-list">
            {requests.map((request, i) => (
              <ResourceOrderSplitItem
                key={i}
                data={request}
                updateQuantity={(value) => updateRequestQuantity(request.id, value)}
                toggleRequest={(checked) => toggleSelectRequest(request.id, checked)}
              />
            ))}
          </div>
        )}
        <div className="error">{error}</div>
      </div>
      <div className="modal-footer">
        <Button
          color="outline-primary"
          type="button"
          onClick={() => toggle()}
        >Close</Button>
        <Button
          color="primary"
          type="button"
          onClick={() => split()}
        >Split Order</Button>
      </div>
    </Modal>
  )
}

export default SplitOrderModal;
