import airtable from '../../airtables';

export const updateVideoHistory = async (
  userType,
  userInfo,
  videoOwner,
  type
) => {

  let updateFunc;
  if (userType === 'Provider') updateFunc = airtable.providers.update;
  else if (userType === 'Presenter') updateFunc = airtable.presenters.update;
  else return;

  let prefix = "";
  if (type === 'starting') prefix = "Started";
  else if (type === 'finished') prefix = "Completed";
  else return;
  const res = await updateFunc(userInfo.id, {
    'PD Activities Completed': [
      ...(userInfo['PD Activities Completed'] ? userInfo['PD Activities Completed'] : []),
      `${prefix} Watching ${videoOwner} Video`
    ]
  });

  return {
    id: res.id,
    ...res.fields
  }
}