const RESPONSE_KEYS = {
	ID: "id",
	FORM_ID: "form_id",
	USER_ID: "user_id",
	SCHOOL_IDS: "school_ids",
	STATUS: "status",
	VALUES: "values",

	DEA_ID: "dea_user_id",
	DEA_COMMENT: "dea_comment",
	USER_COMMENT: "user_comment",

	SUBMITTED_AT: "submitted_at",
	CREATED_AT: "created_at",
	LAST_SAVED_AT: "last_saved_at",
};

const VALUES_KEYS = {
	ID: "id",
	RESPONSE_ID: "response_id",
	FIELD_ID: "field_id",
	INSTANCE_NUMBER: "instance_number",
	VALUE: "value",
	COMPLETED: "completed",
};

export { RESPONSE_KEYS, VALUES_KEYS };
