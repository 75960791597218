import React, { useState } from "react";
import { RepeatableAccordion } from "../RepeatableAccordion";
import bgColor from "../../constants/dayColor";
import { Box } from "@mui/material";

const calendarItems = [
	{ code: "FP", label: "First Day Principal" },
	{ code: "FT", label: "First Day Teachers" },
	{ code: "FS", label: "First Day Students" },
	{ code: "LP", label: "Last Day Principal" },
	{ code: "LT", label: "Last Day Teachers" },
	{ code: "LS", label: "Last Day Students" },
];

const CalendarKey = () => {
	const [expanded, setExpanded] = useState(true);
	return (
		<Box sx={{ mb: 2 }}>
			<RepeatableAccordion
				header="Calendar Key"
				id="calendar-key"
				expanded={expanded}
				onChange={() => setExpanded(!expanded)}
			>
				<div className="d-flex flex-row" style={{ gap: "0.5rem" }}>
					{calendarItems.map(({ code, label }) => (
						<div
							key={code}
							className="d-flex align-items-center"
							style={{ gap: "1rem" }}
						>
							<div
								className="rounded-circle d-flex justify-content-center align-items-center"
								style={{
									width: "2rem",
									height: "2rem",
									backgroundColor: bgColor[code],
								}}
							>
								{code}
							</div>
							<span className="ms-3">{label}</span>
						</div>
					))}
				</div>
			</RepeatableAccordion>
		</Box>
	);
};

export default CalendarKey;
