import React, { useState } from "react";
import { Button, Input, Label, Modal } from "reactstrap";
import "./styles.scss";
import { Rating } from "@mui/material";
import useHook from "./use-hook";
import Loading from "../../../components/Loading";

const SchoolResourceReview = ({ data, toggle, onDone }) => {
  const [review, setReview] = useState(0);
  const [feedback, setFeedback] = useState("");
  const { processing, makeReview } = useHook(data.id);

  return (
    <Modal
      isOpen
      className="modal-dialog-centered leave-feedback-modal"
      toggle={toggle}
    >
      <div className="modal-body">
        <div className="feedback-content">
          <h3>Did you find this resource useful?</h3>
          <p>Please take a moment to rate and review.</p>
          <Rating
            value={review}
            max={5}
            size="large"
            onChange={(event, newValue) => {
              setReview(newValue);
            }}
          />
          <div className="form-group feedback">
            <Label for="feedback">Comments</Label>
            <Input
              type="textarea"
              id="feedback"
              rows={5}
              onChange={e => setFeedback(e.target.value)}
            />
          </div>
        </div>
        <div className="modal-actions">
          <Button
            color="primary"
            type="button"
            disabled={!review}
            onClick={async () => {
              const updatedResource = await makeReview(review, feedback)
              onDone(updatedResource);
            }}
          >
            {processing && <Loading size={16} />}{`Submit`}
          </Button>
          <Button
            className="ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => toggle()}
          >
            Close
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default SchoolResourceReview;