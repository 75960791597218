import { useSelector } from "react-redux";
import airtable from "../../../airtables/PDAirtable";
import { useState } from "react";

const useHook = (resourceId) => {
  const [processing, setProcessing] = useState(false);
  const { gnUserInfo } = useSelector(state => state.appInfo);

  const makeReview = async (mark, comment) => {
    setProcessing(true);
    await airtable.resourceReviews.create({
      Resource: [resourceId],
      Reviewer: [gnUserInfo.id],
      Rating: mark,
      Review: comment
    })
    const uResource = await airtable.resources.select(resourceId);
    setProcessing(false);
    return uResource;
  }

  return {
    processing,
    makeReview
  }
}

export default useHook;