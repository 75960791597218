export function findInstanceValueIds(group, instance_number) {
	if (!group || !group.instances || !Array.isArray(group.instances)) {
		return [];
	}

	const instance = group.instances.find(
		(inst) => inst.instance_number === instance_number,
	);
	if (!instance || !instance.fields || !Array.isArray(instance.fields)) {
		return [];
	}

	return instance.fields.map((field) => field.id);
}
