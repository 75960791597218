export const getYearToDateReports = (reports) => {
	const currentDate = new Date();
	const currentYear = currentDate.getFullYear();
	const currentMonth = currentDate.getMonth() + 1;
	const schoolYearStart = new Date(
		currentMonth >= 9 ? currentYear : currentYear - 1,
		8,
	);

	return reports
		.filter((report) => {
			const reportDate = new Date(`${report.year}-${report.monthValue}-01`);
			return reportDate >= schoolYearStart && reportDate <= currentDate;
		})
		.sort((a, b) => {
			if (a.year !== b.year) return b.year - a.year;
			return b.monthValue - a.monthValue;
		});
};

export const combineFieldsForYearToDate = (reports) => {
	const combinedFields = {
		graduatingStudentsData: [],
		studentPopulationData: [],
		communityPopulationData: [],
		schoolClosureDateData: [],
		schoolClosureReasonData: {
			counts: Array(8).fill(0),
			rawData: [],
		},
		suspensionDateData: [],
		suspensionGradeData: Array(13).fill(0),
		expulsionGradeData: Array(13).fill(0),
		expulsionData: [],
		suspensionViolenceStudentsData: 0,
		suspensionViolenceStaffData: 0,
		suspensionHoursInSchoolData: Array(13).fill(0),
		suspensionHoursOutOfSchoolData: Array(13).fill(0),
	};

	reports.forEach((report) => {
		const { fields } = report;

		if (fields) {
			if (
				combinedFields.graduatingStudentsData.length === 0 &&
				fields.graduatingStudentsData &&
				fields.graduatingStudentsData.length > 0
			) {
				combinedFields.graduatingStudentsData = [
					fields.graduatingStudentsData[0],
				];
			}

			if (
				combinedFields.studentPopulationData.length === 0 &&
				fields.studentPopulationData &&
				fields.studentPopulationData.length > 0
			) {
				combinedFields.studentPopulationData = [
					fields.studentPopulationData[0],
				];
			}

			if (
				combinedFields.communityPopulationData.length === 0 &&
				fields.communityPopulationData &&
				fields.communityPopulationData.length > 0
			) {
				combinedFields.communityPopulationData = [
					fields.communityPopulationData[0],
				];
			}

			// Combine other fields as per their logic
			if (fields.schoolClosureDateData) {
				combinedFields.schoolClosureDateData.push(
					...fields.schoolClosureDateData.map((data) => data.value),
				);
			}

			if (fields.schoolClosureReasonData?.counts) {
				combinedFields.schoolClosureReasonData.counts =
					combinedFields.schoolClosureReasonData.counts.map(
						(count, index) =>
							count + (fields.schoolClosureReasonData.counts[index] || 0),
					);
			}

			if (fields.suspensionDateData) {
				combinedFields.suspensionDateData.push(
					...fields.suspensionDateData.map((data) => data.value),
				);
			}

			if (fields.suspensionGradeData) {
				combinedFields.suspensionGradeData =
					combinedFields.suspensionGradeData.map(
						(count, index) => count + (fields.suspensionGradeData[index] || 0),
					);
			}

			if (fields.expulsionGradeData) {
				combinedFields.expulsionGradeData =
					combinedFields.expulsionGradeData.map(
						(count, index) => count + (fields.expulsionGradeData[index] || 0),
					);
			}

			combinedFields.suspensionViolenceStudentsData +=
				fields.suspensionViolenceStudentsData || 0;

			combinedFields.suspensionViolenceStaffData +=
				fields.suspensionViolenceStaffData || 0;

			if (fields.suspensionHoursInSchoolData) {
				combinedFields.suspensionHoursInSchoolData =
					combinedFields.suspensionHoursInSchoolData.map(
						(hours, index) =>
							hours + (fields.suspensionHoursInSchoolData[index] || 0),
					);
			}

			if (fields.suspensionHoursOutOfSchoolData) {
				combinedFields.suspensionHoursOutOfSchoolData =
					combinedFields.suspensionHoursOutOfSchoolData.map(
						(hours, index) =>
							hours + (fields.suspensionHoursOutOfSchoolData[index] || 0),
					);
			}
		}
	});

	return combinedFields;
};
