import React, { Fragment, useState } from "react";
import { Pagination } from "../../../components/Pagination";
import ResourceOrderItem from "../components/ResourceOrderItem";
import noResultImg from "../../../assets/img/no-result.png";

const ResourceOrderList = ({ orders }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const pagesize = 10;

  if (!orders || orders.length === 0) {
    return (
      <div className="no-result">
        <img src={noResultImg} alt="No Requests" />
        <h1><b>No Requests</b></h1>
      </div>
    )
  }

  const pageCount = Math.ceil(orders.length / pagesize);
  const ordersToShow = orders.slice(currentPage, (currentPage + 1) * pagesize);

  return (
    <Fragment>
      <div className="resource-order-list">
        {ordersToShow.map((order, i) => (
          <ResourceOrderItem data={order} key={i} />
        ))}
      </div>
      <div className="paginate pb-4">
        {orders.length > pagesize && (
          <Pagination
            page={currentPage}
            setPage={(p) => {
              setCurrentPage(p);
            }}
            pageCount={pageCount}
          />
        )}
      </div>
    </Fragment>
  )
}

export default ResourceOrderList;