import React, { useState } from "react";
import InputWrapper from "./InputWrapper";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const DateInput = ({
	id,
	value = "",
	onChange = () => {},
	disabled,
	selectedDate,
	format = "YYYY-MM-DD",
	error = false,
	sx = {},
	heading,
	description,
	helperText,
}) => {
	const [inputValue, setInputValue] = useState(value);
	const [inputError, setInputError] = useState(error);

	const filledInput = inputValue.length > 0;

	const handleInputChange = (e) => {
		if (e === null) return;
		const formattedDateObject = e.format("YYYY-MM-DD");

		setInputValue(formattedDateObject);
		setInputError(false);

		onChange(formattedDateObject);
	};

	return (
		<InputWrapper
			title={heading}
			description={description}
			sx={{
				maxWidth: "22rem",
				...sx,
			}}
			id={id}
			inputError={inputError}
			filledInput={filledInput}
			helperText={helperText}
		>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DatePicker
					id={id}
					name={id}
					format={format}
					aria-describedby="helper-text"
					value={inputValue ? dayjs(inputValue) : dayjs(selectedDate)}
					onChange={(e) => handleInputChange(e)}
					disabled={disabled}
					slotProps={{
						textField: {
							error: inputError,
						},
					}}
				/>
			</LocalizationProvider>
		</InputWrapper>
	);
};

export default DateInput;
