import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, InputGroup } from "reactstrap";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputSearch from "@mui/material/Input";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import "./styles.scss";

const BrowserLayout = ({
  children,
  condition,
  update,
  visibleModal,
  toggleModal,
  searchWithKeyword,
  applyFilter,
  query
}) => {
  const { t } = useTranslation();
  const [gradesExpanded, setGradesExpanded] = useState(true);
  const [typesExpanded, setTypesExpanded] = useState(false);
  const [languagesExpanded, setLanguagesExpanded] = useState(false);
  const [strandsExpanded, setStrandsExpanded] = useState(false);
  const [applyButton, showApplyButton] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    setSearch(query)
  }, [query])

  if (!condition) return null;

  const {
    grades,
    types,
    languages,
    strands
  } = condition;

  const makeApplyFilter = () => {
    showApplyButton(false);
    applyFilter();
    toggleModal(false);
  }

  const renderFilterContent = () => {
    return (
      <div className="resource-filter-group">
        <Accordion
          expanded={gradesExpanded}
          onChange={(_, expanded) => setGradesExpanded(expanded)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            {t("grade-levels")}
          </AccordionSummary>
          <AccordionDetails>
            {grades.map((grade, index) => (
              <div key={index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={grade?.checked || false}
                      sx={{
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                      onClick={() => {
                        let gs = [...grades];
                        gs[index].checked = !grade.checked;
                        update("grades", gs);
                        showApplyButton(true);
                      }}
                    />
                  }
                  label={grade.name}
                />
              </div>
            ))}
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={typesExpanded}
          onChange={(_, expanded) => setTypesExpanded(expanded)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            Resource Type
          </AccordionSummary>
          <AccordionDetails>
            {types.map((type, index) => (
              <div key={index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={type?.checked ? true : false}
                      sx={{
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                      onClick={() => {
                        if (!type.checked) type.checked = false;
                        let ts = [...types];
                        ts[index].checked = !type.checked;
                        update("types", ts)
                        showApplyButton(true);
                      }}
                    />
                  }
                  label={type.name}
                />
              </div>
            ))}
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={languagesExpanded}
          onChange={(_, expanded) => setLanguagesExpanded(expanded)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            Language
          </AccordionSummary>
          <AccordionDetails>
            {languages.map((lang, index) => (
              <div key={index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={lang?.checked ? true : false}
                      sx={{
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                      onClick={() => {
                        if (!lang.checked) lang.checked = false;
                        let ls = [...languages];
                        ls[index].checked = !lang.checked;
                        update("languages", ls)
                        showApplyButton(true);
                      }}
                    />
                  }
                  label={lang.name}
                />
              </div>
            ))}
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={strandsExpanded}
          onChange={(_, expanded) => setStrandsExpanded(expanded)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            Strands
          </AccordionSummary>
          <AccordionDetails>
            {strands.map((strand, index) => (
              <div key={index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={strand?.checked ? true : false}
                      sx={{
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                      onClick={() => {
                        if (!strand.checked) strand.checked = false;
                        let ss = [...strands];
                        ss[index].checked = !strand.checked;
                        update("strands", ss)
                        showApplyButton(true);
                      }}
                    />
                  }
                  label={strand.name}
                />
              </div>
            ))}
          </AccordionDetails>
        </Accordion>
      </div>
    )
  }

  return (
    <div className="browser-layout">
      <div className="resource-filter">
        {renderFilterContent()}
        <Button
          color="primary"
          className={classNames({
            "not-activated": !applyButton,
            "desktop-filter-apply-btn": true
          })}
          onClick={() => makeApplyFilter()}
        >
          {t("apply-filter")}
        </Button>
      </div>
      <div className="resource-filter-result">
        <div className="resource-search">
          <div className="btn-filter" onClick={() => toggleModal(true)}>
            <i className="fa fa-filter" />
          </div>
          <InputGroup>
            <InputSearch
              type="text"
              size="large"
              variant="solid"
              placeholder={t("search-by-name")}
              aria-label={t("search-by-name")}
              disableUnderline={true}
              sx={{
                borderRadius: "1.625rem",
                width: "100%",
                color: "black",
                backgroundColor: "white",
                padding: 0,
                paddingLeft: "1.5rem",
              }}
              endAdornment={
                <div className="search-suffix">
                  {search && (
                    <IconButton
                      disableRipple
                      disableFocusRipple
                      onClick={() => setSearch("")}
                      style={{
                        padding: 0,
                        paddingRight: "10px",
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                  <Button
                    color="secondary"
                    style={{
                      borderRadius: "1.625rem",
                      padding: ".25rem 0.5rem 0.25rem",
                      textTransform: "none",
                      fontWeight: "400",
                    }}
                    onClick={() => searchWithKeyword(search)}
                  >
                    <SearchIcon />
                  </Button>
                </div>
              }
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={(event) => {
                if (event.keyCode === 13) {
                  searchWithKeyword(search)
                }
              }}
            />
          </InputGroup>
        </div>
        {children}
      </div>
      <Modal
        className="modal-dialog-centered"
        isOpen={visibleModal}
        toggle={() => toggleModal(false)}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="modal-title-default">Filter</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body modal-filter-body">
          {renderFilterContent()}
        </div>
        <div className="modal-footer">
          <Button
            className="ml-auto"
            color="default"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleModal(false)}
          >Cancel</Button>
          <Button
            color="primary"
            type="button"
            disabled={!applyButton}
            onClick={() => makeApplyFilter()}
          >Apply</Button>
        </div>
      </Modal>
    </div>
  )
}

export default BrowserLayout;