import React, { useState, useEffect } from "react";
import {
	Radio,
	RadioGroup,
	FormControl,
	FormLabel,
	FormControlLabel,
	Box,
	FormHelperText,
	Typography,
} from "@mui/material";

const RadioInput = ({
	id,
	value = "",
	selectedValue,
	onChange = () => {},
	error = false,
	options = {},
	disabled,
	sx = {},
	heading,
	description,
	placeholder,
	helperText,
}) => {
	const [inputValue, setInputValue] = useState(value);
	const [inputError, setInputError] = useState(error);

	useEffect(() => {
		setInputValue(value);
	}, [value]);

	const handleInputChange = (e) => {
		const { value } = e.target;
		setInputValue(value);
		setInputError(false);
		onChange(value);
	};

	return (
		<FormControl
			disabled={disabled}
			sx={{
				width: "100%",
				marginBottom: "1.88rem",
				...sx,
			}}
		>
			<Box mb="0.5rem">
				<FormLabel
					id={id}
					sx={{
						fontFamily: "Effra, sans-serif",
						fontSize: "1.125rem",
						fontWeight: "700",
						color: "#000",
						"&.Mui-focused": {
							color: "#000",
						},
					}}
				>
					{heading}
				</FormLabel>
			</Box>
			{description && (
				<Box mb="0.5rem">
					<Typography
						variant="body1"
						fontFamily="Effra, sans-serif"
						fontSize="1rem"
						fontWeight="400"
						color="#000"
					>
						{description}
					</Typography>
				</Box>
			)}

			<RadioGroup
				aria-labelledby={id}
				defaultValue={inputValue}
				name={id}
				onChange={handleInputChange}
				value={inputValue}
			>
				{options &&
					options?.map(({ value, label }) => {
						return (
							<FormControlLabel
								key={`${value}-${id}`}
								sx={{
									width: "100%",
									backgroundColor: "#fff",
									borderRadius: "1.25rem",
									border: "1px solid #36C0F0",
									outline: "1px solid transparent",
									boxShadow:
										"0.63rem 0.75rem 1.50rem 0 rgba(206, 222, 238, 0.47)",
									marginLeft: "0",
									padding: "1.25rem 1rem",
									marginBottom: "0.63rem",
									"& .MuiFormControlLabel-label": {
										paddingLeft: "0.63rem",
									},
									"&:focus-within, &:hover:not(.Mui-disabled), &.selectedLabel":
										{
											backgroundColor: "#F3F9FF",
											border: "1px solid #0E6BA8",
											outline: "1px solid #0E6BA8",
											".MuiTypography-root": {
												fontWeight: "700",
											},
										},
								}}
								className={value === inputValue ? "selectedLabel" : ""}
								value={value}
								control={<Radio sx={{ color: "#000" }} />}
								label={label}
							/>
						);
					})}
			</RadioGroup>

			{inputError && (
				<FormHelperText
					id="helper-text"
					sx={{
						margin: "0",
						paddingTop: "10px",
					}}
				>
					<Typography variant="body2" color="#CE2222" component="span">
						{helperText}
					</Typography>
				</FormHelperText>
			)}
		</FormControl>
	);
};

export default RadioInput;
