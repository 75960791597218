import React, { useEffect, useState } from "react";
import Airtable from "../../../airtables";
import LoadingBar from "../../../components/LoadingBar";
import { Breadcrumb } from "./components/Breadcrumbs";
import SessionInfo from "../../../components/SessionInfo";
import { useSelector } from "react-redux";
import { getDisplayInfos, getActionMenus } from "../../SessionInfoDisplay";
import Placeholder from "../../../assets/img/login-back.jpg";
import { Row, Col, Button } from "reactstrap";
import { Pagination } from "../../../components/Pagination";
import "./styles.scss"
import SendToTeacher from "../../modals/SendToTeacher";
import BookSessionModal from "../../modals/BookSession";
import { PDFManager } from "../../../libs/pdf";
import { toast } from "react-toastify";
import { FieldsOfStudy, SearchableGrades, WellBeingLinks } from "../../../utils/constants";

const SHOW_PER_PAGE = 10;

const Screen = (props) => {
	const tagId = props.match.params.tagId;
	const [loading, setLoading] = useState(true);
	const [tag, setTag] = useState(null);
	const [sessions, setSessions] = useState([]);
	const { userType, userInfo, totalSubjects, communities, appLocale } = useSelector((state) => state.appInfo);

	const [showSendMsgModal, setShowSendMsgModal] = useState(false);
	const [showBookSessionModal, setShowBookSessionModal] = useState(false);
	const [activeSession, setActiveSession] = useState(null);
	const [searchParams, setSearchParams] = useState("");

	useEffect(() => {
		(async () => {
			setLoading(true);
			try {
				const t = await Airtable.tags.select(tagId);
				setTag(t);
				if (t["Search Query"]) {
					setSearchParams(t["Search Query"]);
				} else {
					const data = await Airtable.providerSessions.getTagSessions(t, userInfo.Cluster);
					const ss = data.filter((s) => {
						return (
							s.Status === "Active (Open for booking)"
						);
					});
					setSessions(() => ss);
				}
			} catch (e) {
				console.error(e);
			} finally {
				setLoading(false);
			}
		})();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!tag) {
			window.document.title = `Collections - Connected North`;
		} else {
			window.document.title = `${tag.Tag} - Collections - Connected North`;
		}
		return () => window.document.title = "Connected North";
	}, [tag])

	useEffect(() => {
		if (!searchParams) return;
		if (!totalSubjects || !totalSubjects.length) return;
		if (!communities || !communities.length) return;

		const queryParams = new URLSearchParams(searchParams);
		const q = queryParams.get("q");
		const subs = queryParams.getAll("subjects");
		const subjects = [];
		if (subs && subs.length) {
			totalSubjects.forEach((sub) => {
				if (subs.indexOf(sub["Subject"]) >= 0) {
					subjects.push(sub);
				}
			})
		}

		const gds = queryParams.getAll("grades");
		const grades = [];
		if (gds && gds.length) {
			SearchableGrades.forEach(grade => {
				if (gds.includes(grade)) {
					grades.push({
						name: grade,
						checked: true,
					})
				}
			})
		}

		const wbls = queryParams.getAll("wellbeinglinks");
		const wbLinks = [];
		if (wbls && wbls.length) {
			WellBeingLinks.forEach(link => {
				if (wbls.indexOf(link) >= 0) {
					wbLinks.push({ name: link });
				}
			})
		}

		let lens = queryParams.getAll("length");
		if (lens && lens.length) {
			lens = lens.map((l) => parseInt(l));
		}

		const nosup = queryParams.getAll("nosupplies");

		const langs = queryParams.getAll("language");
		const crts = queryParams.get("credits");
		const credits = crts ? parseInt(crts) : 60;

		const igs = queryParams.getAll("indigenousGroups");
		var firstNationCommunities = communities.filter(
			(community) => community.Type === "First Nations",
		);
		var inuitCommunities = communities.filter(
			(community) => community.Type === "Inuit",
		);
		var metisCommunities = communities.filter(
			(community) => community.Type === "Métis",
		);
		let ingGroups = [];
		if (igs.indexOf("First Nations") >= 0) {
			ingGroups.push({
				name: "First Nations",
				group: firstNationCommunities,
				checked: true,
			})
		}
		if (igs.indexOf("Inuit") >= 0) {
			ingGroups.push({
				name: "Inuit",
				group: inuitCommunities,
				checked: true,
			});
		}
		if (igs.indexOf("Métis") >= 0) {
			ingGroups.push({
				name: "Métis",
				group: metisCommunities,
				checked: true,
			});
		}

		const cg = queryParams.get("culturalGroup");
		const ic = queryParams.get("indigenousCommunity");
		const fos = queryParams.getAll("fieldsOfStudy");
		const fieldOfStudy = [];
		if (fos && fos.length) {
			FieldsOfStudy.forEach(fs => {
				if (fos.includes(fs.id)) {
					fieldOfStudy.push({
						id: fs.id,
						name: fs.en,
						localizedName: appLocale === 'fr' ? fs.fr : fs.en,
						checked: true
					})
				}
			})
		}

		Airtable.providerSessions.filterProviderSessions(
			q,
			langs,
			lens,
			grades,
			(subjects && subjects.length) ? subjects : null,
			(wbLinks && wbLinks.length) ? wbLinks : null,
			ingGroups,
			ic,
			cg || "",
			credits === 60 ? null : credits,
			(nosup && nosup.length) ? true : false,
			[],
			[
				{ field: 'Indigenous Provider?', direction: 'desc' },
				{ field: 'Average Rating', direction: 'desc' },
			],
			userInfo.Cluster,
			fieldOfStudy.length === 0 ? null : fieldOfStudy,
		).then(({ results, searchTerm }) => {
			const prioritizedResults = searchTerm
				? Airtable.providerSessions.prioritizeResults(results, searchTerm)
				: results;
			setSessions(prioritizedResults);
		})
	}, [searchParams, totalSubjects, communities]) // eslint-disable-line react-hooks/exhaustive-deps

	const downloadPdf = () => {
		const pdfGenerator = new PDFManager();
		const p = pdfGenerator.generateCollectionContent(tag, sessions, `Collection - ${tag["Tag"]}.pdf`)
		toast.promise(p, {
			pending: 'Please wait while downloading is in progress. Do not change the current page.',
			success: 'The PDF has been downloaded successfully. 👌',
			error: 'Sorry, PDF download failed. Please try again later. 🤯'
		})
	}

	let thumbnail = tag ? tag["Thumbnail Image"] : undefined;
	if (thumbnail && thumbnail.length) {
		thumbnail = thumbnail[0]["url"];
	} else {
		thumbnail = Placeholder;
	}

	const { data, page, setPage, pageCount } = usePaginateSession(sessions);

	return (
		<div className="main-container collection-listing">
			<Row className="align-items-center">
				<Col md="8">
					<Breadcrumb pageTitle={tag?.Tag || ""} />
				</Col>
				<Col md="4" align="right"></Col>
			</Row>
			<Row className="align-items-center">
				{thumbnail && thumbnail !== Placeholder && (
					<Col lg="5">
						<div className="image-wrap mb-3 mb-lg-0">
							<img src={thumbnail} alt="" />
						</div>
					</Col>
				)}
				<Col lg={thumbnail && thumbnail !== Placeholder ? "7" : "12"}>
					<h1>{tag?.Tag}</h1>
					<p>{tag?.Description}</p>

					{!!tag && (
						(userType === "Team" && userInfo["Status"] !== 'Session Host') ||
						(userType === 'Teacher' && (userInfo["In-school Coordinator"] || userInfo["Cluster Leadership"]))
					) && (
							<Button
								color="primary"
								size="md"
								type="button"
								onClick={() => setShowSendMsgModal(true)}
							><i className="fa fa-envelope" />&nbsp;&nbsp;Send To Teacher(s)</Button>
						)}
					{(!!tag && !loading) && (
						<Button
							color="outline-primary"
							size="md"
							type="button"
							onClick={() => downloadPdf()}
						><i className="fa fa-file-pdf-o" />&nbsp;&nbsp;Download as PDF</Button>
					)}
				</Col>
			</Row>
			<div className="collection-listing-list mt-4">
				{loading ? (
					<LoadingBar />
				) : (
					<div>
						{data.map((s) => (
							<SessionInfo
								noMenu
								key={s["id"]}
								session={s}
								displayInfos={getDisplayInfos(s, userType)}
								actionMenu={getActionMenus(s, userType)}
								sessionDetailLink={s["id"]}
								bookSession={() => {
									setActiveSession(s);
									setShowBookSessionModal(true);
								}}
							/>
						))}
					</div>
				)}
			</div>

			{pageCount > 1 && (
				<Pagination
					pageCount={pageCount}
					setPage={setPage}
					page={page}
				/>
			)}

			{showSendMsgModal && !!tag && (
				<SendToTeacher
					data={tag}
					onToggle={() => setShowSendMsgModal(!showSendMsgModal)}
					type="Collection"
				/>
			)}

			{activeSession && showBookSessionModal && (
				<BookSessionModal
					providerSessionId={activeSession.id}
					providerName={activeSession["Provider Name Text"][0]}
					providerId={activeSession["Provider"[0]]}
					onToggle={() => {
						setShowBookSessionModal(false);
						setActiveSession(null);
					}}
				/>
			)}
		</div>
	);
};

export default Screen;

/**
 * @param {any[]} s
 */
function usePaginateSession(s) {
	/** @type {[number, React.Dispatch<React.SetStateAction<number>>]} */
	const [page, setPage] = useState(0);

	/** @type {[number, React.Dispatch<React.SetStateAction<number>>]} */
	const [pageCount, setPageCount] = useState(0);
	useEffect(() => {
		setPageCount(() => Math.ceil(s.length / SHOW_PER_PAGE));
	}, [s]);

	/** @type {[any[], React.Dispatch<React.SetStateAction<any[]>>]} */
	const [data, setData] = useState([]);
	useEffect(() => {
		const l = page * SHOW_PER_PAGE;
		const h = l + SHOW_PER_PAGE;
		setData(() => s.slice(l, h));
	}, [s, page]);

	return { page, pageCount, setPage, data };
}
