import { useState } from "react";
import Airtable from "../../../airtables/PDAirtable";
import { distinctArray } from "../../../utils/array";
import { PDFManager } from "../../../libs/pdf";
import { useSelect } from "@mui/base";
import { confirmResourceOrderShip } from "../../../libs/apis";

const useHook = (order) => {
  const { gnUserInfo } = useSelect(state => state.appInfo);
  const [processing, setProcessing] = useState(false);

  const submitShipOrder = async (waybill) => {
    setProcessing(true);
    try {
      await Airtable.resourceOrders.update(order.id, {
        "Waybill": waybill,
        "Status": "Shipped"
      });
      const requests = await Airtable.resourceRequests.listByIds(order["Requests"]);
      const rIds = requests.map(req => req["Resource"][0]);
      const resIds = distinctArray(rIds);
      const resources = await Airtable.resources.listByIds(resIds);
      const quantityMap = resources.reduce((result, res) => {
        result[res.id] = res.Quantity;
        return result;
      }, {});
      requests.forEach(req => {
        const resId = req["Resource"][0];
        quantityMap[resId] = quantityMap[resId] - req["Quantity"];
      });

      const params = Object.keys(quantityMap).map(k => ({
        id: k,
        param: {
          Quantity: quantityMap[k]
        }
      }));
      await Airtable.resources.multipleUpdate(params)

      const pdfGenerator = new PDFManager();
      pdfGenerator.generateSchoolResourceOrder(order, requests, `Resource Order #2000${order["Order ID"]}.pdf`);

      await confirmResourceOrderShip(
        gnUserInfo["Email"],
        gnUserInfo["Teacher Name"],
        order,
        requests
      );
      setProcessing(false);
    } catch (error) {
      setProcessing(false);
      return "Failed!"
    }
  }

  return {
    processing,
    submitShipOrder
  }
}

export default useHook;