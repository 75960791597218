import { FormControl, Select } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const ReportDropdown = ({
	children,
	onSelect = () => {},
	value = "",
	label = "",
}) => {
	const handleSelectChange = (event) => {
		const selectedValue = event.target.value;
		onSelect(selectedValue);
	};

	return (
		<FormControl
			sx={{
				width: "100%",
				backgroundColor: "#FFF",
				overflow: "hidden",
				borderRadius: "1.25rem",
				border: "1px solid #9DE0F8",
				padding: "0",
			}}
		>
			<Select
				labelId={label}
				value={value}
				onChange={handleSelectChange}
				defaultValue=""
				IconComponent={ExpandMoreIcon}
				displayEmpty
				sx={{
					color: "#0D1120",
					fontFamily: "Effra, sans-serif",
					fontWeight: "500",
					padding: "0",
					"& fieldset": {
						border: "none",
					},
					"& .MuiSelect-select": {
						padding: "0.5rem",
						marginLeft: "0.5rem",
					},
				}}
			>
				{children}
			</Select>
		</FormControl>
	);
};
