import { Box, Typography } from "@mui/material";
import DonutChart from "../../../../components/Graphs/Donut";
import ReportsBarGraph from "../../../../components/Graphs/ReportsBar";

export const ChartCard = ({
	title,
	subtitle,
	chartType,
	value,
	labels,
	primaryDataLabel,
	secondaryDataLabel,
	data,
	dataSecondary,
	showSecondaryData,
}) => {
	return (
		<Box
			sx={{
				width: "100%",
				border: "1px solid #9DE0F8",
				borderRadius: "1.25rem",
				backgroundColor: "#fff",
				padding: "1rem 1.5rem",
				marginBottom: "1.5rem",
			}}
		>
			<Typography
				sx={{
					fontFamily: "Effra, sans-serif",
					color: "#0E6BA8",
					fontSize: "1.875rem",
					fontWeight: "700",
				}}
			>
				{value && value} {title}
			</Typography>
			<Typography
				sx={{
					fontFamily: "Effra, sans-serif",
					color: "#0D1120",
					fontSize: "1.125rem",
					fontWeight: "400",
					marginBottom: "1rem",
				}}
			>
				{subtitle}
			</Typography>
			{chartType === "donut" && <DonutChart labels={labels} data={data} />}
			{chartType === "bar" && (
				<ReportsBarGraph
					label={labels}
					primaryData={data}
					secondaryData={dataSecondary}
					showdata2={showSecondaryData}
					primaryDataLabel={primaryDataLabel}
					secondaryDataLabel={secondaryDataLabel}
				/>
			)}
		</Box>
	);
};
