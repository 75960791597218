import supabase from "../../../database";
import { transformResponse } from "../util/transform-response";
import airtableInstance from "../../../airtables";

export const createFormResponse = async (formId, userId, schoolIds) => {
	try {
		const response = await supabase.formResponses.create({
			form_id: formId,
			user_id: userId,
			status: "draft",
			school_ids: schoolIds,
		});

		// Increment Principal Reports Started in Airtable Teachers table
		const teacher = await airtableInstance.teachers.select(userId);
		if (teacher) {
			await airtableInstance.teachers.update(teacher.id, {
				"Principal Reports Started": (teacher["Principal Reports Started"] || 0) + 1
			});
		}

		if (!response && response.length === 0) return {};
		return transformResponse(response[0]);
	} catch (e) {
		console.log("Error:", e);
		return {};
	}
};