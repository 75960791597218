import React from "react";
import "./styles.scss";
import { FormGroup, Input, Label } from "reactstrap";

const ResourceOrderSplitItem = ({
  data,
  updateQuantity,
  toggleRequest
}) => {
  const info = [];
  if (data["Resource Type"]) info.push(data["Resource Type"]);
  if (data["Resource Grade Level"]) info.push(data["Resource Grade Level"]);
  if (data["Resource Language"]) info.push(data["Resource Language"]);

  return (
    <div className="resource-order-split-item">
      <div className="request-select">
        <FormGroup check inline>
          <Input type="checkbox" onChange={e => toggleRequest(e.target.checked)} />
        </FormGroup>
      </div>
      <div className="request-info">
        <div className="title">
          {data["Resource Title"]}
        </div>
        <div className="request-info-content">
          <div className="beta-info">
            <span>{`By ${data["Resource Author"]}`}</span>
            <span>{info.join(" | ")}</span>
          </div>
          <div className="request-quantity">
            <Label for="quantity">Quantity:&nbsp;</Label>
            <Input
              id="quantity"
              type="number"
              min={1}
              max={data.Quantity}
              value={data.ChangedQuantity}
              onChange={e => {
                const v = parseInt(e.target.value);
                if (v <= data.Quantity) {
                  updateQuantity(parseInt(e.target.value))
                }
              }}
              readOnly={!data.selected}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResourceOrderSplitItem;
