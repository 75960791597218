import { Box, Typography } from "@mui/material";

export const NumberCard = ({ title, number, isLeftAlign, stats }) => {
	return (
		<Box
			sx={{
				width: "100%",
				border: "1px solid #9DE0F8",
				borderRadius: "1.25rem",
				backgroundColor: "#fff",
				padding: "1rem 1.5rem",
				textAlign: isLeftAlign ? "left" : "center",
				marginBottom: "1.5rem",
			}}
		>
			<Typography
				sx={{
					fontFamily: "Effra, sans-serif",
					color: "#0D1120",
					fontSize: "1.25rem",
					fontWeight: "700",
				}}
			>
				{title}
			</Typography>

			<Typography
				sx={{
					fontFamily: "Effra, sans-serif",
					color: "#1094CE",
					fontSize: "2.5rem",
					fontWeight: "400",
				}}
			>
				{number}
			</Typography>

			{stats && stats.length > 0 && (
				<Box
					sx={{
						marginTop: "1rem",
						display: "flex",
						flexDirection: "column",
					}}
				>
					{stats.map((stat, index) => (
						<Box key={index}>
							<Typography
								sx={{
									fontFamily: "Effra, sans-serif",
									color: "#0D1120",
									fontSize: "1.125rem",
									fontWeight: "700",
								}}
							>
								{stat.label}
							</Typography>

							<Typography
								sx={{
									fontFamily: "Effra, sans-serif",
									color: "#1094CE",
									fontSize: "2.5rem",
									fontWeight: "400",
								}}
							>
								{stat.value}
							</Typography>
						</Box>
					))}
				</Box>
			)}
		</Box>
	);
};
