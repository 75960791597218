import { Rating } from "@mui/material";
import React from "react";
import "./styles.scss";

const ResourceReview = ({ data }) => {
  return (
    <div className="resource-review-item">
      <Rating
        value={data["Rating"]}
        max={5}
        readOnly
      />
      <h4>{data["Reviewer Name"]}</h4>
      <p>{data["Review"]}</p>
    </div>
  )
}

export default ResourceReview;