import React from "react";
import { useSelector } from "react-redux";
import { Input, Label } from "reactstrap";
import "./styles.scss";

const ResourceRequestItem = ({
  data,
  updateQuantity,
  remove,
  edit
}) => {
  const { gnUserInfo } = useSelector(state => state.appInfo);
  const info = [];
  if (data["Resource Type"]) info.push(data["Resource Type"]);
  if (data["Resource Grade Level"]) info.push(data["Resource Grade Level"]);
  if (data["Resource Language"]) info.push(data["Resource Language"]);
  if (gnUserInfo["Resource Administrator"] && data["Shelf Location"]) info.push(data["Shelf Location"]);

  return (
    <div className="resource-request-item">
      <div className="resource-title">
        <span>{data["Resource Title"]}</span>
      </div>
      <div className="request-content">
        <div className="request-info">
          <span className="author">
            By {data["Resource Author"]}
          </span>
          <span className="beta-info">
            {info.join(" | ")}
          </span>
        </div>
        <div className="request-quantity">
          <Label for="quantity">Quantity:&nbsp;</Label>
          <Input
            id="quantity"
            type="number"
            min={1}
            value={data.Quantity}
            onChange={e => updateQuantity(parseInt(e.target.value))}
            readOnly={edit ? false : true}
          />
        </div>
      </div>
      <div className="request-actions">
        {edit && <i className="fa fa-trash-o" onClick={() => remove()} />}
      </div>
    </div>
  )
}

export default ResourceRequestItem;