import React, { useState } from "react";
import { Fade, Menu, MenuItem } from "@mui/material";
import { getIsPDPlanUser } from "../../PDPlan/util/get-is-pd-plan-user";
import { useSelector } from "react-redux";
import { StatusLabel } from "../../PDPlan/components/StatusLabel";

const Teacher = ({
	teacherInfo,
	even,
	openTeacherSessionsModal,
	allocate,
	handleAllocation,
	allocatedCredits,
	teacherNo,
	removeTeacher,
	editTeacher,
}) => {
	const { userInfo, userType } = useSelector((state) => state.appInfo);
	const [anchorEl, setAnchorEl] = useState(null);
	const menuOpen = Boolean(anchorEl);

	const isSSA = teacherInfo.grades?.includes("SSA");

	const handleMenuBtnClose = () => {
		setAnchorEl(null);
	};

	const handleMenuBtnClick = (event) => {
		event.preventDefault();

		setAnchorEl(event.currentTarget);
	};

	const sendEmailToTeacher = () => {
		handleMenuBtnClose();
		window.location.href = `mailto:${teacherInfo.email}`;
	};

	return (
		<li
			style={{ backgroundColor: even ? "transparent" : "#f7fbff" }}
			className="tableRow my-2"
		>
			<p className="font-weight-bold">
				<span
					className="teacher-name"
					aria-controls={menuOpen ? "basic-menu" : undefined}
					aria-haspopup="true"
					aria-expanded={menuOpen ? "true" : undefined}
					onClick={handleMenuBtnClick}
				>
					{teacherInfo.name}
				</span>
				<Menu
					id="menu-session-details"
					MenuListProps={{
						"aria-labelledby": "btn-teacher-menu",
					}}
					anchorEl={anchorEl}
					open={menuOpen}
					onClose={handleMenuBtnClose}
					TransitionComponent={Fade}
				>
					<MenuItem onClick={sendEmailToTeacher}>
						E-mail {teacherInfo.email}
					</MenuItem>
					<MenuItem
						onClick={() => {
							handleMenuBtnClose();
							editTeacher(teacherInfo);
						}}
					>
						Edit Teacher
					</MenuItem>
					<MenuItem
						onClick={() => {
							handleMenuBtnClose();
							removeTeacher(teacherInfo.id);
						}}
					>
						Remove from School
					</MenuItem>
				</Menu>
			</p>
			{getIsPDPlanUser(userInfo) && (
				<>
					<p className="font-weight-bold">
						{isSSA ? (
							<StatusLabel label="N/A" component="span" />
						) : (
							<a href={`/cn/pd-plan/${teacherInfo.id}`}>
								<StatusLabel
									label={teacherInfo.planStatus || "Not started"}
									component="span"
								/>
							</a>
						)}
					</p>
					<p className="font-weight-bold">
						{isSSA ? (
							<StatusLabel label="N/A" component="span" />
						) : (
							<a href={`/cn/pd-log/${teacherInfo.id}`}>
								<StatusLabel
									logged={teacherInfo.pdHoursLogged}
									unacknowledged={teacherInfo.pdHoursUnacknowledged}
									component="span"
								/>
							</a>
						)}
					</p>
				</>
			)}
			{userType === "Team" && userInfo.Administrator && false && (
				<p>
					{teacherInfo.daysago === 0
						? "Never"
						: teacherInfo.daysago === 1
							? "1 day ago"
							: teacherInfo.daysago + " days ago"}
				</p>
			)}
			<p
				className="detail-link"
				onClick={() =>
					openTeacherSessionsModal({
						teacherInfo: teacherInfo,
						sessionsType: "upcoming",
					})
				}
			>
				{teacherInfo.upcomingSessions}
			</p>

			<p
				className="detail-link"
				onClick={() =>
					openTeacherSessionsModal({
						teacherInfo: teacherInfo,
						sessionsType: "completed",
					})
				}
			>
				{teacherInfo.completedSessions}
			</p>

			{teacherInfo.completedSessions > 0 ? (
				<p>{teacherInfo.percentIndigenous}%</p>
			) : (
				<p></p>
			)}
			<p>{teacherInfo.credits}</p>
			<Allocate on={allocate}>
				<div className="allocateCell">
					<input
						onChange={handleAllocation(teacherNo)}
						value={allocatedCredits[teacherNo]}
					/>
				</div>
			</Allocate>
		</li>
	);
};

export default Teacher;

/**
 * @param {Object} props
 * @param {boolean} props.on
 * @param {React.ReactNode} props.children
 */
function Allocate({ on, children }) {
	return on ? <>{children}</> : <></>;
}
