import React, { useState, useEffect } from "react";
import {
	Checkbox,
	Box,
	Typography,
	FormHelperText,
	FormControlLabel,
} from "@mui/material";

const CheckboxInput = (props) => {
	const {
		id,
		manualLabel,
		value = false,
		onChange = () => {},
		disabled = false,
		error = false,
		heading,
		description,
		helperText,
		sx = {},
	} = props;
	const [checked, setChecked] = useState(value);
	const [inputError, setInputError] = useState(error);

	useEffect(() => {
		setChecked(value);
	}, [value]);

	const handleInputChange = () => {
		setChecked(!checked);
		setInputError(false);

		onChange(!checked);
	};

	return (
		<Box
			sx={{
				width: "100%",
				marginBottom: "1.88rem",
				...sx,
			}}
		>
			{heading && (
				<Box mb="0.5rem">
					<Typography
						variant="h4"
						fontFamily="Effra, sans-serif"
						fontSize="1.125rem"
						fontWeight="700"
						color="#000"
					>
						{heading}
					</Typography>
				</Box>
			)}
			{description && (
				<Box mb="0.5rem">
					<Typography
						variant="body1"
						fontFamily="Effra, sans-serif"
						fontSize="1rem"
						fontWeight="400"
						color="#000"
					>
						{description}
					</Typography>
				</Box>
			)}
			<Box>
				<FormControlLabel
					label={manualLabel}
					sx={{
						marginBottom: 0,
						marginRight: 0,
						".MuiFormControlLabel-label": {
							paddingTop: "0.3125rem",
							color: "#0D1120",
							fontFamily: "Effra, sans-serif",
							fontWeight: "400",
							fontSize: "1.125rem",
						},
					}}
					control={
						<Checkbox
							id={id}
							name={id}
							disabled={disabled}
							size="large"
							inputProps={{
								"aria-label": description,
							}}
							onChange={handleInputChange}
							checked={checked}
						/>
					}
				/>
			</Box>

			{helperText && inputError && (
				<FormHelperText
					id="helper-text"
					sx={{
						margin: "0",
						paddingTop: "10px",
					}}
				>
					<Typography variant="body2" color="#CE2222" component="span">
						{helperText}
					</Typography>
				</FormHelperText>
			)}
		</Box>
	);
};

export default CheckboxInput;
