import React, { useState } from "react";
import useAdminResourceOrders from "./hooks/use-admin-orders";
import AdminOrderItem from "./components/AdminOrderItem";
import SectionLoader from "../../components/SectionLoader";
import { Pagination } from "../../components/Pagination";
import ReviewResourceRequestModal from "../modals/ReviewResourceRequest";
import ResourceShipOrder from "../modals/ResourceShipOrder";
import SplitOrderModal from "../modals/SplitOrder";
import noResultImg from "../../assets/img/no-result.png";

const Screen = () => {
  const pagesize = 10;
  const [activeOrder, setActiveOrder] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showShipModal, setShowShipModal] = useState(false);
  const [showSplitOrderModal, setShowSplitOrderModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [refresh, setRefresh] = useState(0);
  const { loading, orders } = useAdminResourceOrders(refresh);

  const renderContent = () => {
    if (loading) return <SectionLoader />

    if (!orders || orders.length === 0) {
      return (
        <div className="no-result">
          <img src={noResultImg} alt="No Result" />
          <h1><b>No Orders</b></h1>
        </div>
      )
    }

    const pageCount = Math.ceil(orders.length / pagesize);
    const ordersToShow = orders.slice(currentPage, (currentPage + 1) * pagesize);
    return (
      <div className="admin-resource-order-list">
        {ordersToShow.map((order, i) => (
          <AdminOrderItem
            key={i}
            data={order}
            onView={() => {
              setActiveOrder(order);
              setShowModal(true);
            }}
            onShip={() => {
              setActiveOrder(order);
              setShowShipModal(true);
            }}
            onSplit={() => {
              setActiveOrder(order);
              setShowSplitOrderModal(true);
            }}
          />
        ))}
        <div className="paginate pb-4">
          {orders.length > pagesize && (
            <Pagination
              page={currentPage}
              setPage={(p) => {
                setCurrentPage(p);
              }}
              pageCount={pageCount}
            />
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="main-container admin-resource-orders">
      <h1>Resource Orders</h1>
      {renderContent()}

      {showModal && !!activeOrder && (
        <ReviewResourceRequestModal
          resourceOrder={activeOrder}
          toggle={() => {
            setActiveOrder(null);
            setShowModal(false);
          }}
          refresher={() => setRefresh(refresh + 1)}
        />
      )}

      {showShipModal && !!activeOrder && (
        <ResourceShipOrder
          resourceOrder={activeOrder}
          toggle={() => {
            setActiveOrder(null);
            setShowShipModal(false);
          }}
          refresher={() => setRefresh(refresh + 1)}
        />
      )}

      {showSplitOrderModal && !!activeOrder && (
        <SplitOrderModal
          order={activeOrder}
          toggle={() => {
            setActiveOrder(null);
            setShowSplitOrderModal(false);
          }}
          refresher={() => setRefresh(refresh + 1)}
        />
      )}
    </div>
  )
}

export default Screen;