import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { TEACHER_FIELDS } from "../constants/teacher-fields";
import { useUpdateUserInfo } from "./use-update-user-info";

const { INDIGENOUS_GROUPS, LANGUAGE, PRONOUNS } = TEACHER_FIELDS;

const getIndigenousStatusDisplayOption = (userInfo) => {
	if (userInfo && userInfo[INDIGENOUS_GROUPS]) {
		return userInfo[INDIGENOUS_GROUPS];
	}
	return userInfo && userInfo[LANGUAGE] ? [""] : [];
};

const getIndigenousStatusUpdateOption = (indigenousStatus) => {
	if (indigenousStatus.length === 0) return indigenousStatus;
	return indigenousStatus.filter((status) => status !== "") || [];
};

export const useAccountDetails = () => {
	const { search: searchParamsString } = useLocation();
	const { userInfo } = useSelector((state) => state.appInfo);
	const { loading, updateUserInfo } = useUpdateUserInfo();
	const [formValues, setFormValues] = useState({
		pronouns: userInfo && userInfo[PRONOUNS] ? userInfo[PRONOUNS] : "",
		preferredLanguage:
			userInfo && userInfo[LANGUAGE] ? userInfo[LANGUAGE] : "",
		indigenousStatus: getIndigenousStatusDisplayOption(userInfo),
	});

	const updateTeacher = async (nextLink = "") => {
		const { preferredLanguage, indigenousStatus, pronouns } = formValues;

		const updateBody = {
			[TEACHER_FIELDS.INDIGENOUS_GROUPS]:
				getIndigenousStatusUpdateOption(indigenousStatus),
			[TEACHER_FIELDS.LANGUAGE]: preferredLanguage,
			[TEACHER_FIELDS.PRONOUNS]: pronouns,
		};

		await updateUserInfo(updateBody, `${nextLink}${searchParamsString}`);
	};

	return { formValues, setFormValues, loading, updateTeacher };
};
