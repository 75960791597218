import React, { useMemo } from "react";
import { Scatter } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

// Utility function to process data
const stripEmptyData = (rawLabels, rawData, rawSecondaryData, rawTertiaryData) => {
  const labels = [];
  const data = [];
  const secondaryData = [];
  const tertiaryData = [];

  if (!rawLabels || !rawData) {
    return { labels, data, secondaryData, tertiaryData };
  }

  rawLabels.forEach((label, index) => {
    const primaryValue = rawData[index] || 0;
    const secondaryValue = rawSecondaryData?.[index] || 0;
    const tertiaryValue = rawTertiaryData?.[index] || 0;

    if (primaryValue !== 0 || secondaryValue !== 0 || tertiaryValue !== 0) {
      labels.push(label);
      data.push(primaryValue);
      secondaryData.push(secondaryValue);
      tertiaryData.push(tertiaryValue);
    }
  });

  return { labels, data, secondaryData, tertiaryData };
};

const ReportsScatter = ({
  label: rawLabel,
  primaryData: rawPrimaryData,
  secondaryData: rawSecondaryData,
  tertiaryData: rawTertiaryData,
  primaryDataLabel,
  secondaryDataLabel,
  tertiaryDataLabel,
  displayLegend = true,
  title = "",
}) => {
  // Process data using memoization
  const { labels, data, secondaryData, tertiaryData } = useMemo(
    () => stripEmptyData(rawLabel, rawPrimaryData, rawSecondaryData, rawTertiaryData),
    [rawLabel, rawPrimaryData, rawSecondaryData, rawTertiaryData]
  );

  const transformToScatterData = (data) => {
    if (!data) return [];
    return data.map((value, i) => ({
      x: i,
      y: value,
    }));
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: true,
          color: "rgba(0, 0, 0, 0.1)",
          drawTicks: false
        },
        ticks: {
          font: { weight: "400", size: 14, family: "Effra, sans-serif" },
          color: "#0D1120",
        },
      },
      x: {
        grid: {
          display: true,
          color: "rgba(0, 0, 0, 0.1)",
          drawTicks: false
        },
        ticks: {
          font: { weight: "400", size: 14, family: "Effra, sans-serif" },
          color: "#0D1120",
          callback: function(value) {
            return labels[value];
          }
        }
      }
    },
    plugins: {
      legend: {
        display: displayLegend,
        position: "bottom",
        align: "start",
        labels: {
          font: { weight: "400", size: 14, family: "Effra, sans-serif" },
          color: "#0D1120",
          usePointStyle: true,
          pointStyle: "circle",
          boxWidth: 5,
          boxHeight: 5,
        },
      },
      title: {
        display: Boolean(title),
        text: title,
        position: "left",
        font: { weight: "500", size: 14, family: "Effra, sans-serif" },
        color: "#0D1120",
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            return `${context.dataset.label}: ${context.parsed.y} (${labels[context.parsed.x]})`;
          }
        }
      }
    }
  };

  const chartData = {
    datasets: [
      data.length > 0 && {
        label: primaryDataLabel || "Dataset 1",
        data: transformToScatterData(data),
        backgroundColor: "#63ABFD",
        borderColor: "#63ABFD",
        pointBackgroundColor: "#63ABFD",
        borderWidth: 2,
        pointRadius: 6,
        pointHoverRadius: 8,
        showLine: false
      },
      secondaryData.length > 0 && {
        label: secondaryDataLabel || "Dataset 2",
        data: transformToScatterData(secondaryData),
        backgroundColor: "#E697FF",
        borderColor: "#E697FF",
        pointBackgroundColor: "#E697FF",
        borderWidth: 2,
        pointRadius: 6,
        pointHoverRadius: 8,
        showLine: false
      },
      tertiaryData.length > 0 && {
        label: tertiaryDataLabel || "Dataset 3",
        data: transformToScatterData(tertiaryData),
        backgroundColor: "#FFB947",
        borderColor: "#FFB947",
        pointBackgroundColor: "#FFB947",
        borderWidth: 2,
        pointRadius: 6,
        pointHoverRadius: 8,
        showLine: false
      }
    ].filter(Boolean)
  };

  return (
    <div style={{ height: "400px", width: "100%" }}>
      <Scatter options={options} data={chartData} />
    </div>
  );
};

export default ReportsScatter;
