import { PAGE_KEYS, GROUP_KEYS, FIELD_KEYS } from "../constants/form-keys";

export const consolidateFormLayout = (rawPages, rawGroups, rawFields) => {
	if (!rawPages || !rawGroups || !rawFields) return [];
	const pages = rawPages
		.sort((a, b) => a[PAGE_KEYS.PAGE_ORDER] - b[PAGE_KEYS.PAGE_ORDER])
		.map((page) => {
			const groups = rawGroups
				.filter((group) => group[GROUP_KEYS.PAGE_ID] === page[PAGE_KEYS.ID])
				.sort((a, b) => a[GROUP_KEYS.GROUP_ORDER] - b[GROUP_KEYS.GROUP_ORDER])
				.map((group) => {
					const fields = rawFields
						.filter(
							(field) => field[FIELD_KEYS.GROUP_ID] === group[GROUP_KEYS.ID],
						)
						.sort(
							(a, b) => a[FIELD_KEYS.FIELD_ORDER] - b[FIELD_KEYS.FIELD_ORDER],
						);

					return {
						...group,
						[GROUP_KEYS.FIELDS]: fields,
					};
				});
			return { ...page, [PAGE_KEYS.GROUPS]: groups };
		});

	return pages;
};
