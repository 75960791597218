import { useEffect, useState } from "react";
import Airtable from "../../../airtables/PDAirtable";
import { useLocation } from "react-router-dom";
import { PTLanguages, PTResourceTypes, CurriculumGradeLevels, PTStrands } from "../../../utils/constants";
import { useSelector } from "react-redux";

const useExploreResources = () => {
  const location = useLocation();
  const { userInfo, userType } = useSelector(state => state.appInfo);

  const [loadingFeatured, setLoadingFeatured] = useState(true);
  const [featuredResources, setFeaturedResources] = useState([]);
  const [loadingFiltered, setLoadingFiltered] = useState(true);
  const [filteredResources, setFilteredResources] = useState([]);
  const [filterCondition, setFilterCondition] = useState(() => {
    // Initialize grades with teacher's grades pre-selected if available
    const teacherGrades = userType === 'Teacher' ? (userInfo?.["Grade(s) Taught"] || []) : [];
    const grades = CurriculumGradeLevels.map((g) => ({
      name: g,
      checked: teacherGrades.includes(g)
    }));

    return {
      grades,
      types: PTResourceTypes.map((g) => ({ name: g, checked: false })),
      languages: PTLanguages.map(l => ({ name: l, checked: false })),
      strands: PTStrands.map(s => ({ name: s, checked: false }))
    };
  });
  const [appliedFilterCondition, setAppliedFilterCondition] = useState(null);
  const [search, setSearch] = useState("");
  const [visibleFilterModal, setVisibleFilterModal] = useState(false);
  const [currentPage, setCurrentPage] = useState();

  useEffect(() => {
    Airtable.resources.getFeaturedResources().then(res => {
      setFeaturedResources(res);
      setLoadingFeatured(false);
    }).catch(error => {
      setLoadingFeatured(false);
    })

    const cond = { ...filterCondition };
    const queryParams = new URLSearchParams(location.search);

    const q = queryParams.get("q");
    if (q) setSearch(q);

    // Only update grades from URL if there are grade parameters
    const gds = queryParams.getAll("grades");
    if (gds && gds.length) {
      cond.grades.forEach((grade, i) => {
        cond.grades[i].checked = gds.includes(grade.name);
      });
    }

    const tps = queryParams.getAll("types");
    if (tps && tps.length) {
      cond.types.forEach((tp, i) => {
        if (tps.indexOf(tp.name) >= 0) {
          cond.types[i].checked = true;
        }
      });
    }

    const lngs = queryParams.getAll("languages");
    if (lngs && lngs.length) {
      cond.languages.forEach((l, i) => {
        if (lngs.indexOf(l.name) >= 0) {
          cond.languages[i].checked = true;
        }
      });
    }

    const sts = queryParams.getAll("strands");
    if (sts && sts.length) {
      cond.strands.forEach((s, i) => {
        if (sts.indexOf(s.name) >= 0) {
          cond.strands[i].checked = true;
        }
      });
    }

    let pn = queryParams.get("pageno");
    if (pn) setCurrentPage(parseInt(pn) - 1);

    setFilterCondition(cond);
    setAppliedFilterCondition(cond);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!appliedFilterCondition) return;

    setLoadingFiltered(true);
    const {
      grades,
      types,
      languages,
      strands
    } = appliedFilterCondition;

    const cgs = grades.filter(g => g.checked);
    const cts = types.filter(t => t.checked);
    const cls = languages.filter(l => l.checked);
    const css = strands.filter(s => s.checked);
    Airtable.resources.getBrowserResources({
      grades: cgs.map(g => g.name),
      types: cts.map(t => t.name),
      languages: cls.map(l => l.name),
      strands: css.map(s => s.name)
    }, search).then(res => {
      setFilteredResources(res);
      setLoadingFiltered(false);
      updateQueryParams(0);
    }).catch(error => {
      setLoadingFiltered(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedFilterCondition, search])

  useEffect(() => {
    if (currentPage) {
      updateQueryParams(currentPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  const updateQueryParams = (pn) => {
    const params = new URLSearchParams();

    const {
      grades,
      types,
      languages,
      strands
    } = appliedFilterCondition;

    if (search) params.append("q", search);

    let gs = grades.filter((g) => g.checked);
    if (gs && gs.length) {
      gs.forEach((g) => params.append("grades", g.name));
    }

    let ts = types.filter((t) => t.checked);
    if (ts && ts.length) {
      ts.forEach((t) => params.append("types", t.name));
    }

    let ls = languages.filter((l) => l.checked);
    if (ls && ls.length) {
      ls.forEach((l) => params.append("languages", l.name));
    }

    let ss = strands.filter((s) => s.checked);
    if (ss && ss.length) {
      ss.forEach((s) => params.append("strands", s.name));
    }

    if (pn !== undefined && pn !== 0) {
      params.append("pageno", pn + 1);
    }

    const paramString = params.toString();
    if (paramString) {
      window.history.replaceState(null, "", `/cn/school-resources?${paramString}`);
    } else {
      window.history.replaceState(null, "", `/cn/school-resources`);
    }
  }

  const updateFilterCondition = (field, value) => {
    const newCond = { ...filterCondition };
    newCond[field] = value;
    setFilterCondition(newCond);
  }

  const pageChanged = (pn) => {
    setCurrentPage(pn);
  }

  const applyFilter = () => {
    setAppliedFilterCondition(filterCondition);
  }

  return {
    loadingFeatured,
    featuredResources,
    loadingFiltered,
    filteredResources,
    filterCondition,
    search,
    searchWithKeyword: setSearch,
    visibleFilterModal,
    setVisibleFilterModal,
    pageChanged,
    applyFilter,
    updateFilterCondition,
    userType,
    userInfo
  }
}

export default useExploreResources;