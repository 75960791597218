import AirtableTable from "../../AirtableTable";

export class ResourceOrderTable extends AirtableTable {
	constructor(instance) {
		super(instance, "Resource Orders");

		this.fields = {
			"Order ID": "fldc70Xz4jLRlUlFq",
			"Status": "fld1wM6cfq5aznZ0P",
			"Teacher": "fldWDsWzx6vQjTdGh",
			"Teacher Name": "fldJWSstBFtSnIy5l",
			"Requests": "fld8pCrZSuDJ3W9IV",
			"Approved by": "fld9XH4BtMNwGXcst",
			"# Resources": "fldO6EFpR9AIurQmC",
			"# Items": "fldrvzyKIJJiLjWFS",
			"Date": "fldmmLumh5XrvY3sj",
			"School Name": "fldLcCn4s784xxON3",
			"School Name Text": "fldjLIGn24iXzPNhK",
			"Waybill": "fldf8NY5lXW3sAYF1"
		};
	}

	getOrdersForTeachers = (teacherNames, teacherIds) => {
		return this.list({
			filterByFormula: `FIND({Teacher}, "${teacherNames.join(",")}")`,
			sort: [
				{ field: 'Date', direction: 'desc' }
			]
		}, (order) => {
			return teacherIds.indexOf(order["Teacher"][0]) >= 0
		})
	}

	getAdminOrders = () => {
		return this.list({
			filterByFormula: `OR({Status} = "Approved", {Status} = "Shipped", {Status} = "Awaiting Inventory")`,
			sort: [
				{ field: 'Date', direction: 'desc' }
			]
		})
	}
}
