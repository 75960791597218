import AirtableTable from "../../AirtableTable";

export class ProviderTable extends AirtableTable {
	constructor(instance) {
		super(instance, "Connected North Providers");

		this.fields = {
			"Record ID": "fldteTGvueDPleYI7",
		};
	}
}
