import { useEffect, useState, useMemo } from "react";

const overview = {
	id: "overview",
	translation_id: "overview",
	completed_percentage: 100,
};

export const useFormNavigation = (form) => {
	const { pages, progress, response_id } = form;

	// Memoize the initial routes
	const initialRoutes = useMemo(() => [overview, ...pages], [pages]);
	const [routes, setRoutes] = useState(initialRoutes);
	const [activeRoute, setActiveRoute] = useState(null);

	const handleRouteChange = (id) => {
		setActiveRoute(routes.find((route) => route.id === id));
	};

	const handleRouteNext = () => {
		const index = routes.findIndex((route) => route.id === activeRoute.id);
		if (index < routes.length - 1) {
			handleRouteChange(routes[index + 1].id);
		}
	};

	const handleRoutePrev = () => {
		const index = routes.findIndex((route) => route.id === activeRoute.id);
		if (index > 0) {
			handleRouteChange(routes[index - 1].id);
		}
	};

	// Update routes when progress changes
	useEffect(() => {
		if (progress && progress.page_progress) {
			const overviewProgress = {
				page_id: "overview",
				completed_percentage: progress?.agreed_to_terms ? 100 : 0,
			};
			const allProgress = [overviewProgress, ...progress.page_progress];

			const updatedRoutes = initialRoutes.map((route) => {
				const pageProgress = allProgress?.find((p) => p.page_id === route.id);
				return {
					...route,
					completed_percentage: pageProgress
						? pageProgress.completed_percentage
						: 0,
				};
			});

			setRoutes(updatedRoutes);
		}
	}, [progress, initialRoutes]);

	// Handle localStorage and set initial active route
	useEffect(() => {
		if (routes.length > 1) {
			const storedRoute = localStorage.getItem(response_id);
			const route = routes.find((route) => route.id === storedRoute);
			if (!route && storedRoute) {
				localStorage.removeItem(response_id);
			}
			if (route && progress?.agreed_to_terms) {
				setActiveRoute(route);
			} else {
				setActiveRoute(routes[0]);
			}
		}
	}, [response_id, routes, progress?.agreed_to_terms]);

	useEffect(() => {
		if (activeRoute && activeRoute.id !== localStorage.getItem(response_id)) {
			localStorage.setItem(response_id, activeRoute.id);
			const container = document.getElementById("main-frame");
			if (container) {
				container.scrollTo({
					top: 0,
					behavior: "smooth",
				});
			}
		}
	}, [activeRoute, response_id]);

	return {
		routes,
		activeRoute,
		handleRouteChange,
		handleRouteNext,
		handleRoutePrev,
	};
};
