import { GROUP_KEYS, INSTANCE_KEYS } from "../constants/form-keys";
import { VALUES_KEYS } from "../constants/response-keys";

export const transformFieldGroups = (rawFieldGroups) => {
	const {
		PAGE_ID,
		GROUP_ORDER,
		REPEATABLE,
		TRANSLATION_ID,
		ID,
		FIELDS,
		INSTANCES,
		RESPONSE_ID,
	} = GROUP_KEYS;

	const { id, page_id, group_order, repeatable, translation_id, required } =
		rawFieldGroups || {};

	return {
		[ID]: id,
		[PAGE_ID]: page_id,
		[RESPONSE_ID]: undefined,
		[GROUP_ORDER]: group_order,
		[REPEATABLE]: repeatable,
		required: required,
		[TRANSLATION_ID]: translation_id,
		[FIELDS]: [],
		[INSTANCES]: [],
		is_completed: false,

		updateField: function (field) {
			const fieldValueId = field.id;
			const value = field.value;
			const oldField = this[INSTANCES].find((instance) =>
				instance[INSTANCE_KEYS.FIELDS].find(
					(field) => field[VALUES_KEYS.ID] === fieldValueId,
				),
			);
			if (oldField) {
				oldField[VALUES_KEYS.VALUE] = value;
				oldField[VALUES_KEYS.COMPLETED] = true;
			}
			return oldField;
		},
		addInstance: function (instance) {
			this[INSTANCES].push(instance);
		},
		deleteInstance: function (instance_number) {
			if (!instance_number) return;
			const instances = this[INSTANCES].filter(
				(instance) => instance.instance_number !== instance_number,
			);
			this[INSTANCES] = instances;
		},
		createNewInstance: function () {
			const instanceNumbers = this[INSTANCES].map(
				(instance) => instance[INSTANCE_KEYS.INSTANCE_NUMBER],
			);

			const instanceNumber = Math.max(...instanceNumbers) + 1;
			const fields = this[FIELDS].map((field) => ({
				...field,
				value: "",
				completed: false,
				id: undefined,
			}));
			const newInstance = {
				instance_number: instanceNumber,
				completed: false,
				fields,
			};
			this[INSTANCES].push(newInstance);

			return newInstance;
		},
	};
};
