import AirtableTable from "../../AirtableTable";

export class NunavutTeachersTable extends AirtableTable {
	constructor(instance) {
		super(instance, "tbljALpvSMnM64XNf");

		this.fields = {
			"Teacher Name": "fldyrCCG2GtgCnu3I",
			"School Name": "fldGWWPKHPo8VhGqP",
			"Record ID": "fldzr7xkAko0jPzQY",
			Principal: "fldtjPSwH1zYmD92b",
			"PD Plans": "fldfEh2h3fHpRoybG",
			"PD Log Activities": "fldpSPJu3tuuz5mLt",
			"Review 1 Administrator": "fldHGADiY9PxtC5vB",
			"Review 2 Administrator": "fldoTZyXzK5C6IbSR",
			"Review 3 Administrator": "fldNqkUOeMuoTYlv1",
			"Cluster Record IDs": "fldYFHgxUCi25WP17",
			"License Issued": "fldFC7rQfb5xnFvUP",
			"License Expiry": "fldvwnVHDU9FFnqvx",
			"Certificate Number": "fldW5O1HXBexKhNaW",
			"Principal License Issued": "fldAEui9XuYOvHdwd",
			"Principal License Expiry": "fldxrN1Bt8HFRNqqt",
			"Principal Certificate Number": "fldmkgWToKGeJvfLr",
			"# PD Week Sessions": "fldEG33hWJjqqdqH0",
			"Allow Custom Calendar": "fldJP7iPP2pSAu3ej",
			"Resource Administrator": "flda7NknOdrQ6g2VD"
		};
	}

	getTeacherByOriginTeacherId = (teacherId) => {
		return this.selectOneByCondition({
			filterByFormula: `{Record ID} = "${teacherId}"`
		});
	}

	getTeacherByRecordId = (teacherId, linkedField1, linkedField2) => {
		return new Promise((resolve, reject) => {
			this.selectOneByCondition({
				filterByFormula: `{Record ID} = "${teacherId}"`,
			})
				.then(async (teacherRecord) => {
					if (!teacherRecord) {
						resolve(null);
						return;
					}

					const plans = teacherRecord[linkedField1];
					if (plans && plans.length > 0) {
						const planRecords = await Promise.all(
							plans.map(async (planId) => {
								const planRecord = await this.table.find(
									planId,
								);

								const goals = planRecord.fields[linkedField2];
								let goalRecords = goals
									? await Promise.all(
										goals.map(async (goalId) => {
											const goalRecord =
												await this.table.find(
													goalId,
												);
											return {
												id: goalRecord.id,
												...goalRecord.fields,
											};
										}),
									)
									: [];

								return {
									id: planRecord.id,
									...planRecord.fields,
									Goals: goalRecords.sort((a, b) => (a.ID > b.ID)),
								};
							}),
						);

						teacherRecord[linkedField1] = planRecords;
					}

					resolve(teacherRecord);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	getTeachersByIds = (ids = []) => {
		if (ids.length === 0) return [];
		const filter = ids.map((id) => `RECORD_ID() = "${id}"`);
		return this.list({
			filterByFormula: `OR(${filter})`,
		});
	};

	getTeachersBySyncedId = (ids = []) => {
		if (ids.length === 0) return [];
		const filter = ids.map((id) => `{Record ID} = "${id}"`);
		return this.list({
			filterByFormula: `OR(${filter})`,
		});
	};

	getTeachersInSchool = (schoolName, schoolId) => {
		return this.list({
			filterByFormula: `{School Name} = "${schoolName}"`,
		}, (teacher) => {
			if (teacher["School Name"].indexOf(schoolId) >= 0) return true;
			return false;
		})
	}
}
