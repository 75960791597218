import AirtableBase from "./AirtableBase";
import {
  PDPlanGoalsTable,
  PDPlansTable,
  StandardsTable,
  SchoolsTable,
  SchoolCalendarTable,
  SchoolScheduleTable,
  NunavutTeachersTable,
  PDLogTable,
  ResourceTable,
  ResourceReviewTable,
  ResourceRequestTable,
  ResourceOrderTable,
  SessionTable,
  ProviderTable,
  CalendarDaysTable,
} from "./tables/PDTables";

class Airtable extends AirtableBase {
  constructor(apiKey, base) {
    super(apiKey, base);

    this.pdPlanGoals = new PDPlanGoalsTable(this.instance);
    this.pdPlans = new PDPlansTable(this.instance);
    this.standards = new StandardsTable(this.instance);
    this.nunavutTeachers = new NunavutTeachersTable(this.instance);
    this.schools = new SchoolsTable(this.instance);
    this.schoolCalendar = new SchoolCalendarTable(this.instance);
    this.schoolSchedule = new SchoolScheduleTable(this.instance);
    this.calendarDays = new CalendarDaysTable(this.instance);
    this.pdLog = new PDLogTable(this.instance);
    this.resources = new ResourceTable(this.instance);
    this.resourceReviews = new ResourceReviewTable(this.instance);
    this.resourceRequests = new ResourceRequestTable(this.instance);
    this.resourceOrders = new ResourceOrderTable(this.instance);
    this.sessions = new SessionTable(this.instance);
    this.providers = new ProviderTable(this.instance);
  }
}

const AIRTABLE_APP = "appsE1Vb11AxA0Vim";

export const AIRTABLE_KEY =
  "pat7CUBSnLFZZubN9.c02a9410da258b5dd2306be643e7fbcd9ef2a569d0a66523b24634e4e3a5ae30";

export const AIRTABLE_BASE_URL =
  "https://api.airtable.com/v0/appsE1Vb11AxA0Vim";

const airtableInstance = new Airtable(AIRTABLE_KEY, AIRTABLE_APP);

export default airtableInstance;
