import React, { useState } from 'react';
import { Button, Form, FormGroup, Input, Modal } from 'reactstrap'
import * as EmailValidator from 'email-validator';
import './styles.scss';
import { useTranslation } from 'react-i18next';

const AddTeacherModal = ({ onToggle, addTeacher, confirmBtnLabel }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
  });

  const validateEmail = (em) => {
    if (!em) {
      return 'E-mail Address is required.';
    } else if (!EmailValidator.validate(em)) {
      return 'Invalid E-mail Address Format';
    } else {
      return ''
    }
  };

  const validateName = (name) => {
    if (!name) return 'Name is required.';
    return '';
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, email } = formData;
    const newErrors = {
      name: validateName(name),
      email: validateEmail(email)
    };
    setErrors(newErrors);

    if (!newErrors.name && !newErrors.email) {
      addTeacher(name, email)
    }
  };

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={true}
      toggle={() => onToggle()}
    >
      <Form onSubmit={handleSubmit}>
        <div className="modal-header">
          <h5 className="modal-title" id="modal-title-default">{t("principal.add-new-teacher")}</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => onToggle()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">

          <FormGroup>
            <label>{t("principal.teacher-name")}</label>
            <Input
              type="text"
              className="form-style"
              name="name"
              id="name"
              value={formData.name}
              onChange={handleChange}
              invalid={!!errors.name}
            />
          </FormGroup>
          <FormGroup>
            <label>{t("principal.teacher-email")}</label>
            <Input
              type="text"
              className="form-style"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              invalid={!!errors.email}
            />
          </FormGroup>
        </div>
        <div className="modal-footer">
          <Button
            className="ml-auto"
            color="danger"
            data-dismiss="modal"
            type="button"
            onClick={() => onToggle()}
          >{t("cancel")}</Button>
          <Button
            color="success"
            type="submit"
          >{confirmBtnLabel || t("continue")}</Button>
        </div>
      </Form>
    </Modal>
  )
}

export default AddTeacherModal;
