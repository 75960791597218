import AirtableTable from "../../AirtableTable";

export class ResourceRequestTable extends AirtableTable {
	constructor(instance) {
		super(instance, "Resource Requests");

		this.fields = {
			"Teacher": "fldeTCzYkZzCLZWa9",
			"Resource": "fldA9c2A0rYRBZxJb",
			"Quantity": "fld5m5w7fvNaMOnQj",
			"Resource Orders": "fldnlWxq9Py1gLzOO",
			"Date": "fldbJXsJ8YwI5HzYd",
			"Resource Title": "fldr7XEdhhR3S5Ys4",
			"Resource Grade Level": "fldcSYE7ztsUFxgJO",
			"Resource Language": "fld7OJdn3KnVWXGZI",
			"Resource Type": "fldB6Vmeku9UbbdUn",
			"Resource Author": "fld9F0RdOgWODasM6",
			"Shelf Location": "fld0RCrGS48DboNQX"
		};
	}

	getTeacherRequest = (name, id) => {
		return this.list({
			filterByFormula: `AND({Resource Orders} = BLANK(), {Teacher} = "${name}")`
		}, (request) => {
			if (request["Teacher"].indexOf(id) >= 0) return true;
			return false;
		})
	}
}
