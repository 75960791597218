import React from "react";
import { Box, Typography } from "@mui/material";
import { PrevNextButton } from "./PrevNextButton";

export const FormPage = ({
	children,
	heading,
	description,
	handleRouteNext,
	handleRoutePrev,
	hasNext,
}) => {
	return (
		<Box
			sx={{
				backgroundColor: "#FFF",
				border: "1px solid #9ce0f8",
				borderRadius: "1rem",
				padding: { xs: "1.25rem", md: "1.875rem" },
			}}
		>
			<Typography
				variant="h2"
				sx={{
					fontSize: "1.875rem",
					fontFamily: "Effra, sans-serif",
					fontWeight: "700",
					color: "#0D1120",
					marginBottom: "0.625rem",
				}}
			>
				{heading}
			</Typography>

			<Typography
				variant="body1"
				sx={{
					fontSize: "1.125rem",
					fontFamily: "Effra, sans-serif",
					fontWeight: "400",
					color: "#0D1120",
				}}
			>
				{description}
			</Typography>

			<Box sx={{ marginY: { xs: "1.25rem", md: "1.875rem" } }}>{children}</Box>
			<Box sx={{ marginTop: { xs: "1.25rem", md: "1.875rem" } }}>
				<PrevNextButton
					onNextClick={handleRouteNext}
					onPrevClick={handleRoutePrev}
					hasNext={hasNext}
				/>
			</Box>
		</Box>
	);
};
