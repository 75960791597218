import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

const BarGraph = ({ sessions, title }) => {
  const { t } = useTranslation();

  const grades = new Array(14).fill(0)
  sessions.forEach((s) => {
    s['Grade(s)'] &&
      s['Grade(s)'].forEach((g) => {
        switch (g.toLowerCase()) {
          case 'k':
            grades[0] = grades[0] + 1
            break
          case 'professional development':
            grades[13] = grades[13] + 1
            break
          default:
            grades[Number(g)] = grades[Number(g)] + 1
            break
        }
      })
  })
  const state = {
    labels: [
      'K',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12',
      'PD',
    ],
    datasets: [
      {
        label: 'Sessions',
        backgroundColor: '#1094CE',
        lineTension: 1,
        borderColor: '#1094CE',
        borderWidth: 2,
        data: grades,
      },
    ],
  }
  const options = {
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: t("sessions"),
        position: 'left',
      },
    },
  }

  return (
    <>
      <h3 style={{
        display: 'flex',
        justifyContent: 'space-between',
        fontWeight: 700,
        fontSize: 25,
        marginBottom: 10,

      }}
      >{title}</h3>
      <Bar data={state} options={options} />
    </>
  )
}
export default BarGraph
