import React from 'react';
import { Button, Box } from '@mui/material'
import { Row, Col } from "reactstrap";
import CalendarKey from "../components/calendar/CalendarKey";
import CalendarDays from "../components/calendar/CalendarDays";
import YearlyCalendar from '../components/calendar/YearlyCalendar';
import styled from "@mui/material/styles/styled";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useTranslation } from "react-i18next";

const StyledButton = styled(Button)({
	border: "1px solid #0E6BA8",
	width: "100%",
	backgroundColor: "#0E6BA8",
	color: "#FFF",
	borderRadius: "1rem",
	textTransform: "none",
	fontFamily: "Effra, sans-serif",
	fontSize: "1rem",
	fontWeight: "400",
	padding: "0.625rem",
	borderWidth: "0.15rem",
	position: "relative",
	"&:hover": {
		backgroundColor: "#085492",
		borderWidth: "0.15rem",
		transform: "translateY(-2px)",
	},
});

const StyledText = styled("span")({
	overflow: "hidden",
	textOverflow: "ellipsis",
	whiteSpace: "nowrap",
	width: "90%",
	textAlign: "center",
});

const ClosedDays = ({year, calendarStartDay, calendarEndDay, events, schoolClosedDays, updateClosedDays, handlePrev, disable = false, disableODays}) => {
	const { t } = useTranslation();

  return (
    <>
      <Row className="justify-content-between mt-4">
				<Col md={4}>
					<CalendarKey />
          <CalendarDays
            type="A - School administration"
            description="A days include staff activities such as report card preparation and distribution, administrative staff meetings, and instructional preparation."
            maxDays={2}
            dates={schoolClosedDays ? schoolClosedDays.filter(day => day.Type === "A - School administration") : []}
            minDate={calendarStartDay}
            maxDate={calendarEndDay}
            onFinished={updateClosedDays}
          />

          <CalendarDays
            type="C - School/community orientation"
            description="C days include activities or presentations centered around: school operations and culture, community services, and orientation to the community as a whole. These activities should integrate Inuit societal values and the principles and concepts of Inuit Qaujimajatuqangit."
            maxDays={2}
            dates={schoolClosedDays ? schoolClosedDays.filter(day => day.Type === "C - School/community orientation") : []}
            minDate={calendarStartDay}
            maxDate={calendarEndDay}
            onFinished={updateClosedDays}
          />

          <CalendarDays
            type="D - Department in-service"
            description="D days are placeholders and can be used when it is determined to be most appropriate by the Department of Education in collaboration with the DEA/CSFN. The DEA/CSFN is responsible for informing the community, at least one month in advance, when the department has scheduled a D day."
            maxDays={4}
            dates={schoolClosedDays ? schoolClosedDays.filter(day => day.Type === "D - Department in-service") : []}
            minDate={calendarStartDay}
            maxDate={calendarEndDay}
            onFinished={updateClosedDays}
          />

          <CalendarDays
            type="O - Staff development"
            description="The O days/hours are placeholders and can be used when it is determined to be most appropriate by the school principal in collaboration with the DEA/CSFN. The DEA/CSFN is responsible for informing the community, at least one month in advance, when the principal has scheduled a O day/hours."
            dates={schoolClosedDays ? schoolClosedDays.filter(day => day.Type === "O - Staff development") : []}
            minDate={calendarStartDay}
            maxDate={calendarEndDay}
            onFinished={updateClosedDays}
            disable={disableODays}
          />

          {/* Temporarily hidden
          <CalendarDays
            type="N - Principal's extra days"
            description="The principal is required to work five (5) extra calendar days before the Teacher/School Staff's start date and two (2) extra days after the Teacher/School Staff's end date. The principal must work during regular business days, i.e., Monday to Friday. These extra seven (7) days are outside of the regular 195 teaching staff days and are coded as N days."
            maxDays={7}
            dates={schoolClosedDays ? schoolClosedDays.filter(day => day.Type === "N - Principal's extra work") : []}
            minDate={calendarStartDay}
            maxDate={calendarEndDay}
            onFinished={updateClosedDays}
          />
          */}

          <CalendarDays
            type="H - Extended holidays"
            description="If there is a day set aside for community holidays such as Hamlet days in the spring, indicate this in the calendar with an H."
            dates={schoolClosedDays ? schoolClosedDays.filter(day => day.Type === "H - Extended holidays") : []}
            minDate={calendarStartDay}
            maxDate={calendarEndDay}
            onFinished={updateClosedDays}
          />

          <Box sx={{ marginTop: { xs: "1.25rem", md: "1.875rem" }, cursor: disable ? "progress" : "auto" }}>
            <StyledButton
              onClick={handlePrev}
              aria-label={`Go to previous section: Step 2`}
            >
              <KeyboardArrowLeftIcon
                sx={{
                  position: "absolute",
                  left: "0",
                }}
              />
              <StyledText>{t(`form_buttons.prev`)}</StyledText>
            </StyledButton>
          </Box>
				</Col>

				<Col md={8}>
          <div style={{ padding: '20px' }}>
            <YearlyCalendar year={year} startDate={calendarStartDay} events={events} />
          </div>
				</Col>
			</Row>
    </>
  )
}

export default ClosedDays
