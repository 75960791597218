import { useEffect, useState } from "react";
import airtable from "../../../airtables";

export const useTeacher = (schoolId, refresh) => {
	const [loading, setLoading] = useState(true);
	const [teachers, setTeachers] = useState([]);
	const [leaders, setLeaders] = useState([]);
	const [school, setSchool] = useState(null);

	useEffect(() => {
		reload();
	}, [schoolId, refresh]); // eslint-disable-line react-hooks/exhaustive-deps

	const castData = (originTeacherData) => {
		return {
			id: originTeacherData["id"],
			name: originTeacherData["Teacher Name"],
			credits: originTeacherData["Credit Balance"] ?? 0,
			email: originTeacherData["Email"],
			daysago: originTeacherData["Last E-mail Days"] ?? 0,
			allocatedCredits: 0,
			percentIndigenous: Math.round(
				(originTeacherData["Indigenous % TY"] ?? 0) * 100,
			),
			upcomingSessions: originTeacherData["Upcoming TY"] ?? 0,
			completedSessions: originTeacherData["Completed TY"] ?? 0,
			planStatus: originTeacherData["PD Plan Status"] ?? "",
			pdHoursLogged: originTeacherData["PD Hours Logged"] ?? 0,
			pdHoursUnacknowledged: originTeacherData["PD Hours Unacknowledged"] ?? 0,
			grades: originTeacherData["Grade(s) Taught"] ?? [],
			subjects: originTeacherData["Subject(s) Taught"] ?? [],
			phone: originTeacherData["Personal Phone"] ?? "",
			isLeader: originTeacherData["School Leader?"] === "true",
		};
	};

	const reload = async () => {
		setLoading(true);

		try {
			const sc = await airtable.schools.select(schoolId);
			setSchool(sc);

			const ts = await airtable.teachers.getSchoolTeachers(sc.School);
			const allTeachers = ts.map((r) => castData(r));

			// Separate leaders and regular teachers
			setLeaders(allTeachers.filter((t) => t.isLeader));
			setTeachers(allTeachers.filter((t) => !t.isLeader));
		} catch (error) {
			console.log("Error:", error);
		}
		setLoading(false);
	};

	const removeTeacher = async (teacherId) => {
		await airtable.teachers.update(teacherId, {
			Status: "Alumni",
		});
	};

	const updateTeacher = async (teacherId, data) => {
		const updatedTeacher = await airtable.teachers.update(teacherId, {
			"Teacher Name": data.name,
			Email: data.email,
			"Personal Phone": data.phone,
			"Grade(s) Taught": data.grades,
			"Subject(s) Taught": data.subjects,
		});
		const tId = teachers.findIndex((t) => t.id === updatedTeacher.id);
		const newTeachers = [...teachers];
		newTeachers[tId] = castData(updatedTeacher);
		setTeachers(newTeachers);
	};

	return {
		loading,
		teachers,
		leaders,
		schoolInfo: school,
		removeTeacher,
		updateTeacher,
	};
};
