import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography, Grid, Paper } from "@mui/material";
import bgColor from "../../constants/dayColor";

const DateCell = styled(Box)(({ theme }) => ({
	minHeight: "40px",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	margin: "2px",
	fontSize: "12px",
	color: theme.palette.text.primary,
}));

const DateTag = styled(Box)(({ color }) => ({
	padding: "5px",
	borderRadius: "12px",
	backgroundColor: color || "#e0e0e0",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	zIndex: 1,
}));

const WeekDayHeader = styled(Typography)({
	textAlign: "center",
	fontSize: "12px",
	color: "#666",
	textTransform: "lowercase",
	padding: "8px 0",
});

const MonthCalendar = ({ monthName, month, year, dateTags = [] }) => {
	const weekDays = ["s", "m", "t", "w", "t", "f", "s"];

	const getDaysInMonth = (month, year) => {
		return new Date(year, month + 1, 0).getDate();
	};

	const getFirstDayOfMonth = (month, year) => {
		return new Date(year, month, 1).getDay();
	};

	const renderCalendar = () => {
		const daysInMonth = getDaysInMonth(month, year);
		const firstDay = getFirstDayOfMonth(month, year);
		const totalCells = Math.ceil((daysInMonth + firstDay) / 7) * 7;
		const cells = [];

		for (let i = 0; i < totalCells; i++) {
			const dayNumber = i - firstDay + 1;
			const dateTag = dateTags.find(
				(tag) =>
					tag["Date"] ===
					`${year}-${(month + 1).toString().padStart(2, "0")}-${dayNumber.toString().padStart(2, "0")}`,
			);

			const isWeekendDay = i % 7 === 0 || i % 7 === 6;

			if (i < firstDay || dayNumber > daysInMonth) {
				cells.push(<DateCell key={i} />);
			} else {
				cells.push(
					<DateCell
						key={i}
						sx={{ color: isWeekendDay ? "#9ba1a8" : "inherit" }}
					>
						{dateTag ? (
							<DateTag color={bgColor[dateTag["Type"].split(" - ")[0]]}>
								<Typography variant="caption" color="text.primary">
									{dateTag["Type"].split(" - ")[0]}
								</Typography>
								<Typography variant="caption" color="text.primary">
									{dayNumber.toString().padStart(2, "0")}
								</Typography>
							</DateTag>
						) : (
							dayNumber.toString().padStart(2, "0")
						)}
					</DateCell>,
				);
			}
		}
		return cells;
	};

	return (
		<Paper elevation={0} sx={{ p: 1, maxWidth: 350 }}>
			<Typography variant="h6" align="center" gutterBottom>
				{monthName}
			</Typography>

			<Grid container>
				{weekDays.map((day, index) => (
					<Grid item xs={12 / 7} key={index}>
						<WeekDayHeader>{day}</WeekDayHeader>
					</Grid>
				))}
			</Grid>

			<Grid container>
				{renderCalendar().map((cell, index) => (
					<Grid item xs={12 / 7} key={index}>
						{cell}
					</Grid>
				))}
			</Grid>
		</Paper>
	);
};

const YearlyCalendar = ({ year = "2024-25", startDate, events }) => {
	const [months, setMonths] = useState([]);

	const monthNames = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];

	useEffect(() => {
		const start = new Date(startDate);
		start.setDate(1);

		const monthArray = Array.from({ length: 12 }, (_, i) => {
			const month = new Date(start);
			month.setMonth(start.getMonth() + i);
			month.setDate(1);
			return month;
		});
		setMonths(monthArray);
	}, [startDate]);

	return (
		<Paper elevation={0} sx={{ p: 2 }}>
			<Typography
				variant="h4"
				align="center"
				gutterBottom
				sx={{ borderBottom: "1px solid darkgray" }}
			>
				{year}
			</Typography>
			<Grid container spacing={3}>
				{months.map((month, index) => (
					<Grid item xs={12} md={4} key={index}>
						<MonthCalendar
							month={month.getMonth()}
							year={month.getFullYear()}
							monthName={monthNames[month.getMonth()]}
							dateTags={events[monthNames[month.getMonth()]]}
						/>
					</Grid>
				))}
			</Grid>
		</Paper>
	);
};

export default YearlyCalendar;
