import React, { useEffect, useLayoutEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";

import { useFormData } from "./hooks/use-form-data.hook";

import { ProgressProvider } from "./components/ProgressContext";
import { FormLayout } from "./components/FormLayout";
import { useSchoolTeacherData } from "./hooks/use-school-teachers-data.hook";
import { StyledButton } from "./components/SideBar";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { useTranslation } from "react-i18next";
import GNlogo from "../../assets/img/GN-logo.jpg";

const Screen = () => {
	const { t } = useTranslation();
	const {
		form,
		reportSchool,
		loading,
		restricted,
		submitted,
		isParamResponse,
	} = useFormData();

	const { teachersBySchoolId, inuktutTeachersBySchoolId, getGradeRangeByUser } =
		useSchoolTeacherData();

	const [options, setOptions] = useState({
		teachersBySchoolId: [],
		inuktutTeachersBySchoolId: [],
		gradesRangeByUser: [],
	});

	useEffect(() => {
		const fetchOptions = async () => {
			try {
				const teachers = await teachersBySchoolId();
				const inuktutTeachers = await inuktutTeachersBySchoolId();
				const grades = await getGradeRangeByUser();
				setOptions({
					teachersBySchoolId: teachers,
					inuktutTeachersBySchoolId: inuktutTeachers,
					gradesBySchoolId: grades,
				});
			} catch (error) {
				console.error("Error fetching teacher data:", error);
			}
		};
		fetchOptions();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// const getCurrentMonthYear = () => {
	// 	return new Date().toLocaleDateString("default", {
	// 		month: "long",
	// 		year: "numeric"
	// 	});
	// };

	useLayoutEffect(() => {
		window.document.title = `Monthly School Report - Connected North`;
	});

	const handleDownloadPDF = () => {
		// Initialize PDF document
		const doc = new jsPDF({
			orientation: "portrait",
			unit: "mm",
			format: "letter", // Standard A4 format
		});

		// Define constants for layout
		const layout = {
			margins: {
				right: 10,
				left: 10,
				bottom: 10,
				group: 15, // Left margin for group content
			},
			spacing: {
				afterTitle: 20,
				afterHeading: 10,
				afterGroup: 5,
			},
			fontSize: {
				title: 28,
				pageHeading: 24,
				groupHeading: 20,
			},
		};

		// Calculate available widths
		const pageWidth = doc.internal.pageSize.getWidth();
		const contentWidth = pageWidth - layout.margins.left - layout.margins.right;
		const groupWidth = pageWidth - layout.margins.group - layout.margins.right;

		// Helper function to check and add new page if needed
		function ensureSpace(requiredSpace) {
			// Check if there's enough space on current page
			if (yPosition + requiredSpace > doc.internal.pageSize.getHeight()) {
				// Add new page
				doc.addPage();
				// Reset yPosition to top of new page (with margin)
				yPosition = 20;
				return yPosition;
			}
			// If there's enough space, return current position
			return yPosition;
		}

		// Add logo
		const imgWidth = 28;
		const imgHeight = 35;
		doc.addImage(GNlogo, "jpg", 10, 10, imgWidth, imgHeight);

		// Add school name
		doc.setFontSize(layout.fontSize.title);
		doc.setFont("helvetica", "bold");
		const title = reportSchool?.School;
		doc.text(title, 10 + imgWidth + 2, 32);
		doc.setFont("helvetica", "normal");

		// Add Report for MONTH YEAR
		const subtitle = "Report for " + form["name"];
		doc.text(subtitle, 10 + imgWidth + 2, 44);

		let yPosition = 65;

		// Process each page
		form.pages.forEach((page) => {
			// Add page heading
			doc.setFontSize(layout.fontSize.pageHeading);
			doc.setFont("helvetica", "bold");
			const pageHeading = t(
				`db_group_pages.${page.translation_id || "default"}.heading`,
			);
			const pageLines = doc.splitTextToSize(pageHeading, contentWidth);

			// Check if we need a new page for the section
			yPosition = ensureSpace(
				pageLines.length * layout.spacing.afterHeading + 15,
			);

			// Add page heading
			pageLines.forEach((line) => {
				doc.text(line, layout.margins.left, yPosition);
				yPosition += layout.spacing.afterHeading;
			});
			doc.setFont("helvetica", "normal");

			// Process groups
			page.groups.forEach((group) => {
				doc.setFontSize(layout.fontSize.groupHeading);
				const groupHeading = t(
					`db_field_groups.${group.translation_id || "default"}.heading`,
				);
				if (groupHeading.trim().length > 0) {
					const groupLines = doc.splitTextToSize(groupHeading, groupWidth);

					// Check if we need a new page for the group
					yPosition = ensureSpace(
						groupLines.length * layout.spacing.afterHeading + 5,
					);

					// Add group heading
					groupLines.forEach((line) => {
						doc.text(line, layout.margins.left, yPosition);
						yPosition += layout.spacing.afterHeading;
					});
				}

				const fields = group.fields.map((field) => {
					let d = { ...field };
					group.instances.forEach((i) => {
						d[`instance${i["instance_number"]}`] = i.fields.find(
							(f) => f.field_id === field.id,
						);
					});
					return d;
				});

				group["instances"].forEach((instance) => {
					autoTable(doc, {
						startY: yPosition,
						head: [["Field", "Details"]],
						body: fields.map((field) => {
							const fieldHeading = t(
								`db_fields.${field.translation_id || "default"}.heading`,
							);
							let value =
								field[`instance${instance["instance_number"]}`]?.value;

							if (field.type === "file") {
								value = value ? "Submitted" : "Not Provided";
							} else if (["single select", "radio"].includes(field.type)) {
								value = value
									? t(
											`db_fields.${field.translation_id || "default"}.options.${value}`,
										)
									: "";
							} else if (field.type === "multi select") {
								if (value && typeof value === "string") {
									try {
										value = JSON.parse(value);
										value = value.map((v) => {
											const translation = t(
												`db_fields.${field.translation_id || "default"}.options.${v}`,
											);
											return translation ===
												`db_fields.${field.translation_id || "default"}.options.${v}`
												? v
												: translation;
										});
									} catch {
										value = [];
									}
								}
								value = Array.isArray(value) ? value.join(", ") : "";
							}

							return [fieldHeading, value || ""];
						}),
						theme: "grid",
						margin: { top: 10, left: 14, right: 14 },
						headStyles: {
							fillColor: [3, 93, 152],
						},
						columnStyles: {
							0: {
								cellWidth: doc.internal.pageSize.getWidth() * 0.3, // 30% of page width
								minCellWidth: doc.internal.pageSize.getWidth() * 0.15, // 15% of page width
							},
							1: { cellWidth: "auto" }, // Will take remaining space
						},
						didDrawPage: (data) => {
							yPosition = data.cursor.y + 10;
						},
					});
				});

				// Add light blue line after group
				doc.setDrawColor(173, 216, 230); // Light blue color
				doc.setLineWidth(0.5);
				const lineWidth = 100; // Width of the line in mm
				const startX = (pageWidth - lineWidth) / 2; // Center the line
				doc.line(startX, yPosition, startX + lineWidth, yPosition);

				yPosition += 15; // Add some space after the line
			});
		});

		doc.save(title + " " + subtitle);
	};

	return (
		<Box sx={{ m: 3, display: "flex", flexDirection: "column", gap: 3 }}>
			<Box sx={{ display: "flex", justifyContent: "space-between" }}>
				<Typography
					variant="h1"
					sx={{
						fontSize: "2.5rem",
						fontFamily: "Effra, sans-serif",
						fontWeight: "400",
					}}
				>
					{"Monthly School Report"}
				</Typography>
				<StyledButton
					variant="contained"
					sx={{ width: "max-content" }}
					onClick={handleDownloadPDF}
					disabled={loading}
					hidden={loading || form?.response_status !== "submitted"}
				>
					Download as PDF
				</StyledButton>
			</Box>
			{!loading ? (
				!restricted && form ? (
					<ProgressProvider form={form}>
						<FormLayout
							restricted={restricted}
							submitted={submitted}
							isParamResponse={isParamResponse}
							options={options}
						/>
					</ProgressProvider>
				) : (
					<p>Restricted</p>
				)
			) : (
				<CircularProgress />
			)}
		</Box>
	);
};

export default Screen;
