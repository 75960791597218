import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const RepeatableAccordion = ({
	header,
	children,
	id,
	expanded = false,
	onChange = () => {},
}) => {
	return (
		<Accordion
			expanded={expanded}
			onChange={onChange}
			sx={{
				border: "1px solid #96ddf8",
				backgroundColor: "#f3f9ff",
				boxShadow: "0.625rem 0.75rem 1.5rem 0rem rgba(206, 222, 238, 0.47)",
				"&.Mui-focused, & .MuiAccordionSummary-root:focus-visible": {
					outline: "2px solid #1976d2",
					borderRadius: "0.625rem",
				},
				"&.MuiAccordion-root": {
					borderRadius: "0.625rem",
				},
				marginBottom: "1.25rem",
			}}
		>
			<AccordionSummary
				expandIcon={
					<ExpandMoreIcon
						sx={{
							color: "#2b4f70",
							fontSize: "1.875rem",
						}}
					/>
				}
				aria-controls={`${header}-content`}
				id={id}
				sx={{
					fontFamily: "Effra, sans-serif",
					fontSize: "1.25rem",
					fontWeight: "700",
					color: "#0D1120",
				}}
			>
				{header}
			</AccordionSummary>
			<AccordionDetails>{children}</AccordionDetails>
		</Accordion>
	);
};
