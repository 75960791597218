import AirtableTable from "../../AirtableTable";

export class SessionTable extends AirtableTable {
	constructor(instance) {
		super(instance, "Connected North Provider Sessions");

		this.fields = {
			"Record ID": "fldL76atFh7F7a6bA",
		};
	}
}
