import React, { useState } from "react";
import "./styles.scss";
import { Button, FormGroup, Input, Label, Modal } from "reactstrap";
import useHook from "./use-hook";
import Loading from "../../../components/Loading";
import { toast } from "react-toastify";

const ResourceShipOrder = ({ resourceOrder, toggle, refresher }) => {
  const [waybill, setWaybill] = useState("");
  const [errors, setErrors] = useState({});
  const { processing, submitShipOrder } = useHook(resourceOrder)

  const apply = async () => {
    let validations = {};
    if (!waybill) validations.waybill = "This field is required";
    if (Object.keys(validations).length > 0) {
      setErrors(validations);
      return;
    }

    const error = await submitShipOrder(waybill);
    refresher();
    toggle();
    toast.error(error);
  }

  return (
    <Modal
      isOpen
      className="modal-dialog-centered review-ship-order"
      toggle={() => {
        if (!processing) toggle();
      }}
    >
      <div className="modal-header">
        <h4 className="modal-title" id="modal-title-default">Ship Order</h4>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            if (!processing) toggle();
          }}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <FormGroup>
          <Label for="waybill">Waybill</Label>
          <Input
            type="text"
            className="form-style"
            name="waybill"
            id="waybill"
            value={waybill}
            disabled={processing}
            onChange={(e) => setWaybill(e.target.value)}
            invalid={!!errors.waybill}
          />
          <div className="invalid-feedback">{errors.waybill}</div>
        </FormGroup>
      </div>
      <div className="modal-footer">
        <Button
          color="outline-primary"
          type="button"
          disabled={processing}
          onClick={() => toggle()}
        >Close</Button>
        <Button
          className="btn-submit-print-desktop"
          color="primary"
          type="button"
          disabled={processing}
          onClick={() => apply()}
        >{processing && <Loading size={16} />}&nbsp;Submit & Print Packing Slip</Button>
        <Button
          className="btn-submit-print-mobile"
          color="primary"
          type="button"
          disabled={processing}
          onClick={() => apply()}
        >{processing && <Loading size={16} />}&nbsp;Submit & Print</Button>
      </div>
    </Modal>
  )
}

export default ResourceShipOrder;