import { jsPDF } from "jspdf";
import logoImg from '../../assets/img/logo1.png';
import GNLogoImg from '../../assets/img/GN.jpg';
import GNIlogo from '../../assets/img/GNlogo.png';
import autoTable from 'jspdf-autotable';
import axios from 'axios';
import { Buffer } from 'buffer';
import Placeholder from '../../assets/img/login-back.jpg';
import { font } from "./fonts";

const getImageBase64 = async (imageField) => {
  if (!imageField) {
    return {
      imgData: Placeholder,
      format: "jpeg",
      imgWidth: 2201,
      imgHeight: 1318
    }
  }

  let imgUrl, imgWidth, imgHeight;
  if (imageField[0].thumbnails) {
    imgUrl = imageField[0].thumbnails.large.url;
    imgWidth = imageField[0].thumbnails.large.width
    imgHeight = imageField[0].thumbnails.large.height;
  } else {
    imgUrl = imageField[0].url;
    imgWidth = imageField[0].width
    imgHeight = imageField[0].height;
  }

  const format = imageField[0].type.split("/")[1];
  try {
    const response = await axios.get(imgUrl, {
      responseType: 'arraybuffer'
    });

    const base64Data = Buffer.from(response.data, 'binary').toString('base64');
    return {
      imgData: base64Data,
      format,
      imgWidth,
      imgHeight
    }
  } catch (error) {
    console.error('Error fetching image:', error);
    return null;
  }
};

export class PDFManager {

  /**
   *
   * @param {jsPDF} doc
   */
  addLogoImageToEveryPage(doc) {
    var totalPages = doc.internal.getNumberOfPages();
    for (var i = 1; i <= totalPages; i++) {
      doc.setPage(i);

      doc.addImage(logoImg, "png", 170, 4, 33.7, 4, "");

      doc.setFontSize(12);
      doc.setTextColor("gray");
      const txt = 'Page ' + i + ' of ' + totalPages;
      var textWidth = doc.getStringUnitWidth(txt) * doc.internal.getFontSize() / doc.internal.scaleFactor;
      var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
      doc.text(txt, textOffset, doc.internal.pageSize.height - 7);
    }
  }

  addNunavutLogoImageToEveryPage(doc) {
    var totalPages = doc.internal.getNumberOfPages();
    for (var i = 1; i <= totalPages; i++) {
      doc.setPage(i);

      doc.addImage(GNLogoImg, "jpeg", 170, 10, 33.7, 40, "");

      doc.setFontSize(12);
      doc.setTextColor("gray");
      const txt = 'Page ' + i + ' of ' + totalPages;
      var textWidth = doc.getStringUnitWidth(txt) * doc.internal.getFontSize() / doc.internal.scaleFactor;
      var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
      doc.text(txt, textOffset, doc.internal.pageSize.height - 7);
    }
  }

  generatePDF(doc, title, subTitle, userInfo, contentFn) {
    doc.addFileToVFS('segoe-ui-symbol-2-normal.ttf', font);
    doc.addFont('segoe-ui-symbol-2-normal.ttf', 'segoe-ui-symbol-2', 'normal');
    doc.setFont("helvetica", "bold");

    const imgWidth = 60;
    const imgHeight = 24;
    doc.addImage(GNIlogo, 'png', 130, 10, imgWidth, imgHeight);

    // Add the title and teacher information
    doc.setFontSize(24);
    const textWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    const textOffset = (doc.internal.pageSize.width - textWidth) / 2;
    doc.text(title, textOffset, 50);

    doc.setFontSize(18);
    doc.text(`${userInfo["Teacher Name"]}'s ${subTitle}`, 14, 70);

    // Call content function to handle dynamic content generation
    contentFn(doc);

    // Add page numbers
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(12);
      doc.setTextColor("gray");
      const pageText = `Page ${i} of ${pageCount}`;
      const pageTextWidth = doc.getStringUnitWidth(pageText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
      const pageTextOffset = (doc.internal.pageSize.width - pageTextWidth) / 2;
      doc.text(pageText, pageTextOffset, doc.internal.pageSize.height - 7);
    }
  };

  generateContentForGoals(doc, pdGoals, standardOptions) {
    let currentY = 85;
    const maxWidth = 180;

    pdGoals.forEach((goal) => {
      let goalTitle = `Goal: ${goal["Goal Name"]}`;

      doc.setFontSize(14);
      const splitTitle = doc.splitTextToSize(goalTitle, maxWidth);

      if (currentY + splitTitle.length * 10 > doc.internal.pageSize.height - 20) {
        doc.addPage();
        currentY = 20;
      }

      doc.text(splitTitle, 14, currentY);
      currentY += splitTitle.length * 6;

      autoTable(doc, {
        startY: currentY,
        head: [["Field", "Details"]],
        body: [
          ["Specific", goal["Specific"]],
          ["Standard", goal["Standard"] ? (standardOptions?.find(s => s.value === goal["Standard"][0])?.label || "Standard not found") : ""],
          ["Time-Phased", new Date(goal["Time-Phased"]).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })],
          ["Measurable", goal["Measurable"]?.join(", ")],
          ["Achievable", goal["Achievable"]],
          ["Relevant", goal["Relevant"]],
          ["School Supports", goal["School Supports"]],
          ["Last Updated", new Date(goal["Last Updated"]).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })],
        ],
        theme: "grid",
        headStyles: { fillColor: [93, 137, 168] },
        margin: { top: 10, left: 14, right: 14 },
        columnStyles: {
          0: { cellWidth: 45 },
          1: { cellWidth: 'auto' }
        },
        didDrawPage: (data) => {
          currentY = data.cursor.y + 15;
        },
      });

      currentY += 5;
    });
  };

  generateContentForLogs(doc, pdLogs, goals) {
    let currentY = 85;
    const maxWidth = 180;

    pdLogs.forEach((log) => {
      let logTitle = `${log["Activity Name"]}`;

      doc.setFontSize(14);
      const splitTitle = doc.splitTextToSize(logTitle, maxWidth);

      if (currentY + splitTitle.length * 10 > doc.internal.pageSize.height - 20) {
        doc.addPage();
        currentY = 20;
      }

      doc.text(splitTitle, 14, currentY);
      currentY += splitTitle.length * 6;

      let relatedGoals = [];
      if (log["Related PD Plan Goal"]) {
        log["Related PD Plan Goal"].forEach(goalId => relatedGoals.push(goals.find(s => s.value === goalId)?.label));
      }

      const formatDate = (date) => date ? new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : "";

      let body = [
        ["Description", log["Description"]],
        relatedGoals.length > 0 && ["Related PD Plan Goal", relatedGoals.join(", ")],
        log["End Date"] ? ["Start Date", formatDate(log["Start Date"])] : ["Activity Date", formatDate(log["Start Date"])],
        log["End Date"] && ["End Date", formatDate(log["End Date"])],
        ["Hours", log["Hours"]]
      ].filter(Boolean);

      autoTable(doc, {
        startY: currentY,
        head: [["Field", "Details"]],
        body: body,
        theme: "grid",
        headStyles: { fillColor: [93, 137, 168] },
        margin: { top: 10, left: 14, right: 14 },
        columnStyles: {
          0: { cellWidth: 45 },
          1: { cellWidth: 'auto' }
        },
        didDrawPage: (data) => {
          currentY = data.cursor.y + 15;
        },
      });

      currentY += 5;
    });
  };

  generateGoalsPDF(pdGoals, standardOptions, userInfo) {
    const doc = new jsPDF({
      orientation: "portrait",
      unit: 'mm',
      format: [210, 297]
    });

    this.generatePDF(doc, "Professional Development Framework", "PD Plan", userInfo, (doc) => {
      this.generateContentForGoals(doc, pdGoals, standardOptions);
    });

    doc.save(`${userInfo["Teacher Name"]} - Professional Development Plan.pdf`);
  };

  generateLogsPDF(pdLogs, goals, userInfo) {
    const doc = new jsPDF({
      orientation: "portrait",
      unit: 'mm',
      format: [210, 297]
    });

    this.generatePDF(doc, "Professional Development Activities", "PD Log", userInfo, (doc) => {
      this.generateContentForLogs(doc, pdLogs, goals);
    });

    doc.save(`${userInfo["Teacher Name"]} - Professional Development Activities.pdf`);
  };

  async generateCollectionContent(tag, sessions, filename) {
    try {
      const doc = new jsPDF({
        orientation: "portrait",
        unit: 'mm',
        format: [210, 297]
      });

      doc.addFileToVFS('segoe-ui-symbol-2-normal.ttf', font);
      doc.addFont('segoe-ui-symbol-2-normal.ttf', 'segoe-ui-symbol-2', 'normal');

      // render collection content such as title, logo and description
      doc.setFont("helvetica", "bold");
      doc.setFontSize(16);

      const tagTextWidth = doc.getStringUnitWidth(tag.Tag) * doc.internal.getFontSize() / doc.internal.scaleFactor;
      const tagTextOffset = (doc.internal.pageSize.width - tagTextWidth) / 2;
      doc.text(tag.Tag, tagTextOffset, 14);

      const tagImgInfo = await getImageBase64(tag["Thumbnail Image"]);
      var offset = 0;
      autoTable(doc, {
        body: [[
          { content: ``, styles: { halign: 'center' } },
          { content: tag.Description, styles: { halign: 'left', font: 'segoe-ui-symbol-2' } }
        ]],
        margin: { top: 20 },
        columnStyles: {
          0: { cellWidth: 64 },
          1: { cellWidth: 120 }
        },
        didDrawCell: (data) => {
          if (data.column.index === 0) {
            const imgHeight = 60 * tagImgInfo.imgHeight / tagImgInfo.imgWidth

            doc.addImage(
              tagImgInfo.imgData,
              tagImgInfo.format,
              data.cell.x + 2,
              data.cell.y + 2,
              60,
              imgHeight
            );
            if (imgHeight > data.cell.height) offset = imgHeight - data.cell.height;
          }
        }
      });

      // divider
      let finalYPosition = doc.autoTable.previous.finalY;
      doc.setDrawColor(157, 224, 248);
      doc.line(14, finalYPosition + offset + 5, 198, finalYPosition + offset + 5);

      offset = 0;
      for (let i = 0; i < sessions.length; i++) {
        const session = sessions[i];
        const sessionImgInfo = await getImageBase64(session["Image/Photo"]);
        const startY = doc.autoTable.previous.finalY + offset + 12;

        autoTable(doc, {
          body: [[
            { content: ``, rowSpan: 3, styles: { halign: 'center' } },
            { content: session["Session Title"], styles: { halign: 'left', fontSize: 14, fontStyle: 'bold' } }
          ], [
            { content: session["Description"], styles: { halign: 'left' } }
          ], [
            { content: "", styles: { halign: 'right', textColor: '#0f6aa8', fontSize: 12.5 } }
          ]],
          startY,
          columnStyles: {
            0: { cellWidth: 64 },
            1: { cellWidth: 120 }
          },
          // eslint-disable-next-line
          didDrawCell: (data) => {
            if (data.column.index === 0) {

              const rows = data.table.allRows();
              let tableHeight = 0;
              rows.forEach(row => tableHeight += row.cells[1].height);

              const imgHeight = 60 * sessionImgInfo.imgHeight / sessionImgInfo.imgWidth;

              doc.addImage(
                sessionImgInfo.imgData,
                sessionImgInfo.format,
                data.cell.x + 2,
                data.cell.y + 2,
                60,
                imgHeight
              );

              if (imgHeight > tableHeight) offset = imgHeight - tableHeight;
              else offset = 0;
            } else if (data.row.index === 2) {
              const txt = "Learn more";
              const textWidth = doc.getStringUnitWidth(txt) * doc.internal.getFontSize() / doc.internal.scaleFactor;

              doc.textWithLink(
                txt,
                data.cell.x + data.cell.width - textWidth - 2,
                data.cell.y + 3,
                { url: `https://sessions.connectednorth.org/cn/session/${session.id}` }
              )
            }
          }
        });
      }

      this.addLogoImageToEveryPage(doc)
      doc.save(filename)
    } catch (error) {
      console.log("Error:", error)
    }
  }

  async generateSchoolResourceOrder(order, requests, filename) {
    try {
      const doc = new jsPDF({
        orientation: "portrait",
        unit: 'mm',
        format: [210, 297]
      });
      doc.addFileToVFS('segoe-ui-symbol-2-normal.ttf', font);
      doc.addFont('segoe-ui-symbol-2-normal.ttf', 'segoe-ui-symbol-2', 'normal');

      // render collection content such as title, logo and description
      doc.setFont("helvetica", "bold");
      doc.setFontSize(16);

      doc.text(order["Teacher Name"], 14, 20);
      doc.text(order["School Name Text"], 14, 30);

      const dateString = new Date(order["Date"]).toLocaleString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      });
      doc.setFont("helvetica", "normal");
      doc.setFontSize(13);
      doc.text(dateString, 14, 40);

      autoTable(doc, {
        startY: 55,
        head: [["Resource Name", "Quantity"]],
        body: requests.map(req => ([req["Resource Title"], req["Quantity"]])),
        theme: "grid",
        headStyles: { fillColor: [93, 137, 168] },
        margin: { top: 10, left: 14, right: 14 },
        columnStyles: {
          0: { cellWidth: 'auto' },
          1: { cellWidth: 64 }
        },
      });
      
      this.addNunavutLogoImageToEveryPage(doc)
      doc.save(filename);
    } catch (error) {
      console.log("Error: PDF generation for resource order was failed.");
    }
  }
}
