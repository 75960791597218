import React, { useState, useEffect } from "react";
import { Button, ButtonGroup } from "reactstrap";
import { Box, Typography, TextField, InputAdornment } from "@mui/material";
import { RepeatableAccordion } from '../RepeatableAccordion';
import bgColor from '../../constants/dayColor';
import AddIcon from "@mui/icons-material/Add";
import DoneIcon from "@mui/icons-material/Done";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import airtable from "../../../../airtables/PDAirtable";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";

const CalendarDays = ({
  type,
  description,
  maxDays = 0,
  dates,
  minDate,
  maxDate,
  onFinished,
  disable = false,
}) => {
  const title = type.split(" - ")[1].trim();
  const dayType = type.split("-")[0].trim();
  const displayQuarter = false;
  const displayCustom = dayType === "O";
  const subtitle = maxDays > 0 ? `(Choose ${maxDays})` : "(Number of Days Vary)"

  const [dateFields, setDateFields] = useState(dates && dates.length > 0 ? dates : [{ Date: null, Length: displayCustom ? null : "Half day", Type: type, Name: title, Minutes: null }]);
  const [totalDays, setTotalDays] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [deleteIconVisible, setDeleteIconVisible] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (dates && dates.length > 0) {
      setDateFields(dates);
    }
  }, [dates])

  useEffect(() => {
    if(dateFields && dateFields.length > 0) {
     setTotalDays( dateFields.reduce((sum, field) => sum + ( field.Length === "Full day" ? 1 : field.Length === "Half day" ? 0.5 : field.Length === "Quarter day" ? 0.25 : 0 ), 0) );
    }
  }, [dateFields])

  const handleAddDate = () => {
    if (maxDays === 0 || totalDays <= maxDays) {
      setDateFields([...dateFields, { Date: null, Length: displayCustom ? null : "Half day", Type: type, Name: title, Minutes: null }]);
    }
  };

  const handleDateChange = (index, newDate) => {
    if (!newDate || !newDate.isValid()) return;
    const updatedFields = [...dateFields];
    updatedFields[index].Date = newDate.toISOString().split("T")[0];
    setDateFields(updatedFields);
  };

  const handleDurationChange = (index, newDuration, minutes = false) => {
    const updatedFields = [...dateFields];
    if (minutes) {
      updatedFields[index].Minutes = newDuration;
      updatedFields[index].Length = null;
    }
    else {
      updatedFields[index].Length = newDuration;
    }
    setDateFields(updatedFields);
  };

  const handleFinished = async () => {
    setLoading(true);
    await onFinished(dateFields);
    setLoading(false);
  };

  const handleDelete = async (index) => {
    if (!window.confirm("Are you sure you want to delete this date?")) {
      return;
    }
    setLoading(true);
    const date = dateFields.find((_, i) => i === index);
    setDateFields(dateFields.filter((_, i) => i !== index));
    if(date.id) await airtable.calendarDays.delete(date.id);
    setLoading(false);
  }

  return (
    <Box sx={{ mb: 2 }}>
      <RepeatableAccordion
        id="calendar-key"
        header={
          <div className="d-flex align-items-center" style={{ gap: "0.5rem" }}>
            <div
              className="rounded-circle d-flex justify-content-center align-items-center"
              style={{ width: '3rem', backgroundColor: bgColor[`${dayType}`] }}
            >
              {dayType}
            </div>
            <Typography variant="subtitle2" fontWeight="700" fontSize="1.25rem" lineHeight="1.25rem">{title} {subtitle}</Typography>
          </div>
        }
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
      >
        <p>{description}</p>
        {dateFields.map((field, index) => (
          <div key={index} className="mb-4 position-relative" onMouseEnter={() => setDeleteIconVisible(index)} onMouseLeave={() => setDeleteIconVisible(null)}>
            {/* Date Picker */}
            <div className="form-group">
              <label className="font-weight-bold mb-0">Choose Day</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="w-100"
                  value={field.Date ? dayjs(field.Date) : null}
                  onChange={(newValue) => handleDateChange(index, newValue)}
                  format="YYYY-MM-DD"
                  minDate={minDate ? dayjs(minDate) : null}
                  maxDate={maxDate ? dayjs(maxDate) : null}
                />
              </LocalizationProvider>
              <small className="form-text text-muted">YYYY-MM-DD</small>
            </div>

            {/* Duration Buttons */}
            <label className="font-weight-bold mb-0">Duration</label>
            {
              displayCustom ?
              <TextField
                type="number"
                variant="outlined"
                size="small"
                className="w-100"
                value={field.Minutes}
                onChange={(e) => handleDurationChange(index, Math.max(0, Math.min(e.target.value, 360)), true)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">minutes</InputAdornment>
                  ),
                }}
              />
              :
              <ButtonGroup className="w-100">
              {
                displayQuarter &&
                <Button
                  color={field.Length === "Quarter day" ? "primary" : "outline-primary"}
                  style={{
                    borderRadius: "20px 0 0 20px"
                  }}
                  onClick={() => handleDurationChange(index, "Quarter day")}
                >
                  Quarter Day
                </Button>
              }
              <Button
                color={field.Length === "Half day" ? "primary" : "outline-primary"}
                style={{
                  borderRadius: displayQuarter ? "0" : "20px 0 0 20px",
                }}
                onClick={() => handleDurationChange(index, "Half day")}
              >
                Half Day
              </Button>
              <Button
                color={field.Length === "Full day" ? "primary" : "outline-primary"}
                style={{
                  borderRadius: "0 20px 20px 0"
                }}
                onClick={() => handleDurationChange(index, "Full day")}
              >
                Full Day
              </Button>

            </ButtonGroup>
            }
            {index < dateFields.length - 1 && <Box sx={{ borderTop: '1px solid #96ddf8', my: 4 }} />}
            {deleteIconVisible === index && (
              <IconButton
                aria-label="delete"
                className="position-absolute right-0 text-danger"
                sx={{top: "-0.5rem"}}
                onClick={() => handleDelete(index)}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </div>
        ))}

        {( maxDays > 0 &&
          <div className="mb-4">
            <label className={`font-weight-bold mb-0 ${totalDays < maxDays && 'text-danger'}`}>Total Days</label>
            <div className="mt-2 w-100 pr-3">
              <input className="font-weight-bold text-lg rounded-lg p-2 w-100 border-0" style={{backgroundColor: totalDays < maxDays ? "#e2ced2" : "transparent"}} value={totalDays} disabled />
              {totalDays < maxDays && (
                <p className="mt-2 text-danger">
                  Add {maxDays - totalDays} more Days
                </p>
              )}
            </div>
          </div>
        )}
        <Button color="primary" className="w-100 mb-3" onClick={handleAddDate} disabled={loading || disable} >
          <AddIcon /> Add “{title}”
        </Button>
        <Button color="primary" outline className="w-100" onClick={handleFinished} disabled={loading || totalDays < maxDays}>
          {loading ? "Saving..." : <><DoneIcon /> Save</>}
        </Button>
      </RepeatableAccordion>
    </Box>
  );
};

export default CalendarDays;
