import { Box, Button, styled } from "@mui/material";
import { CheckboxInput } from "./inputs";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const StartButton = styled(Button)({
	border: "1px solid #0E6BA8",
	backgroundColor: "#0E6BA8",
	color: "#FFF",
	borderRadius: "2rem",
	textTransform: "none",
	fontFamily: "Effra, sans-serif",
	fontSize: "1rem",
	fontWeight: "400",
	padding: "0.625rem 1.25rem",
	borderWidth: "0.15rem",
	position: "relative",
	width: "7.75rem",
	"&:hover": {
		backgroundColor: "#085492",
		borderWidth: "0.15rem",
		transform: "translateY(-2px)",
	},
	"&.Mui-disabled": {
		backgroundColor: "#dee7fd",
		borderColor: "#dee7fd",
		color: "#676a70",
	},
});

export const Start = ({
	handleButtonClick,
	buttonLabel,
	header,
	description,
	isChecked: isCheckedInitial,
	isDisabled: isDisabledInitial,
	handleCheck,
}) => {
	const [isChecked, setIsChecked] = useState(isCheckedInitial);
	const [isDisabled, setIsDisabled] = useState(isDisabledInitial);
	const { t } = useTranslation();

	const handleCheckboxToggle = () => {
		handleCheck(!isChecked);
		setIsChecked(!isChecked);
		setIsDisabled(!isDisabled);
	};

	return (
		<Box>
			<CheckboxInput
				type="checkbox"
				id="start-checkbox"
				value={isChecked}
				onChange={handleCheckboxToggle}
				disabled={false}
				manualLabel={t("db_group_pages.overview.checkmarks.heading") || "Start"}
				handleFieldUpdate={(id, value) => {
					console.log(`Field updated: ${id} = ${value}`);
				}}
				aria-label={t("principal.overview-page.description")}
			/>
			<Box
				sx={{
					width: "100%",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<StartButton
					variant="contained"
					onClick={handleButtonClick}
					disabled={isDisabled}
					aria-label="Start"
				>
					{buttonLabel}
				</StartButton>
			</Box>
		</Box>
	);
};
