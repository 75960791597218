import supabase from "../../../database";
import { FIELD_KEYS, GROUP_KEYS, INSTANCE_KEYS } from "../constants/form-keys";
import { transformResponseValues } from "../util/transform-response-values";

export const createInstance = async (group, responseId) => {
	try {
		const instanceNumbers = group[GROUP_KEYS.INSTANCES].map(
			(instance) => instance[INSTANCE_KEYS.INSTANCE_NUMBER],
		);
		const instanceNumber = Math.max(...instanceNumbers) + 1;
		const fields = group[GROUP_KEYS.FIELDS].map((field) => {
			return {
				field_id: field[FIELD_KEYS.ID],
				value: "",
				response_id: responseId,
				completed: false,
				instance_number: instanceNumber,
			};
		});
		const response = await supabase.formResponseValues.createMultiple(fields);

		if (!response && response.length === 0) return {};
		const newFields = response.map(transformResponseValues);
		return {
			[INSTANCE_KEYS.INSTANCE_NUMBER]: instanceNumber,
			[INSTANCE_KEYS.FIELDS]: newFields,
		};
	} catch (e) {
		console.log("Error:", e);
		return {};
	}
};
