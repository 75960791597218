import React, { useState, useMemo } from "react";
import { Box, Typography, MenuItem, styled } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import ReportsBarGraph from "../../../components/Graphs/ReportsBar";
import ReportsLineGraph from "../../../components/Graphs/ReportsLine";
import ReportsScatter from "../../../components/Graphs/ReportsScatter";
import ReportsArea from "../../../components/Graphs/ReportsArea";
import { ReportDropdown } from "./ReportDropdown";
import { useTranslation } from "react-i18next";

const DropdownItem = styled(MenuItem)(() => ({
	color: "#0D1120",
	fontFamily: "Effra, sans-serif",
	fontWeight: "500",
}));

const DropDownHeader = styled(Typography)(() => ({
	color: "#0D1120",
	fontFamily: "Effra, sans-serif",
	fontWeight: "400",
	fontSize: "1.125rem",
	marginBottom: "0.5rem",
}));

const DropDownSection = styled(Box)(() => ({
	marginBottom: "1rem",
}));

const withinAYearByDate = (date) => {
	const today = new Date();
	const lastYear = new Date();
	lastYear.setFullYear(today.getFullYear() - 1);
	return date > lastYear;
};

const withinAYearByMonthAndYear = (month, year) => {
	const today = new Date();
	const lastYear = new Date();
	lastYear.setFullYear(today.getFullYear() - 1);
	return new Date(year, month - 1) > lastYear;
};

const transformData = (data, sessions) => {
	const emptyNumbers = new Array(12).fill(0);
	const dataKeys = [
		"sessionNumbers",
		"studentPopulation",
		"graduatingStudents",
		"communityPopulation",
		"schoolClosures",
		"expulsions",
		"suspensions",
		"violenceStudents",
		"violenceStaff",
	];

	const transformedData = dataKeys.reduce((acc, key) => {
		acc[key] = [...emptyNumbers];
		return acc;
	}, {});

	emptyNumbers.forEach((_, index) => {
		const month = index + 1;

		const sessionMonthData = sessions.filter((d) => {
			const sessionDate = new Date(d["Session Start Date/Time"]);
			if (!withinAYearByDate(sessionDate)) {
				return false;
			}
			return sessionDate.getMonth() + 1 === month;
		});
		transformedData.sessionNumbers[index] = sessionMonthData.length;

		const monthData = data.filter(
			(d) =>
				d.monthValue === month &&
				withinAYearByMonthAndYear(d.monthValue, d.year),
		);
		monthData.forEach((item) => {
			if (!item.fields) return;

			transformedData.studentPopulation[index] +=
				item.fields.studentPopulationData.reduce(
					(acc, field) => acc + Number(field.value || 0),
					0,
				);
			transformedData.graduatingStudents[index] +=
				item.fields.graduatingStudentsData.reduce(
					(acc, field) => acc + Number(field.value || 0),
					0,
				);
			transformedData.communityPopulation[index] +=
				item.fields.communityPopulationData.reduce(
					(acc, field) => acc + Number(field.value || 0),
					0,
				);
			transformedData.schoolClosures[index] +=
				item.fields.schoolClosureDateData.length;
			transformedData.expulsions[index] += item.fields.expulsionData.length;
			transformedData.suspensions[index] +=
				item.fields.suspensionDateData.length;
			transformedData.violenceStudents[index] +=
				item.fields.suspensionViolenceStudentsData.length;
			transformedData.violenceStaff[index] +=
				item.fields.suspensionViolenceStaffData.length;
		});
	});

	const currentMonthIndex = new Date().getMonth();
	const keys = Object.keys(transformedData);
	keys.forEach((key) => {
		transformedData[key] = [
			...transformedData[key].slice(currentMonthIndex + 1),
			...transformedData[key].slice(0, currentMonthIndex + 1),
		];
	});
	return transformedData;
};

export const UserControlledGraph = ({ data, sessions }) => {
	const { t } = useTranslation();
	const allData = useMemo(
		() => transformData(data, sessions),
		[data, sessions],
	);

	const filteredSources = useMemo(
		() =>
			[
				{
					name: t("comparative_graph.sources.session_numbers"),
					value: "sessionNumbers",
				},
				{
					name: t("comparative_graph.sources.student_population"),
					value: "studentPopulation",
				},
				{
					name: t("comparative_graph.sources.graduating_students"),
					value: "graduatingStudents",
				},
				{
					name: t("comparative_graph.sources.community_population"),
					value: "communityPopulation",
				},
				{
					name: t("comparative_graph.sources.school_closures"),
					value: "schoolClosures",
				},
				{
					name: t("comparative_graph.sources.expulsions"),
					value: "expulsions",
				},
				{
					name: t("comparative_graph.sources.suspensions"),
					value: "suspensions",
				},
				{
					name: t("comparative_graph.sources.violence_students"),
					value: "violenceStudents",
				},
				{
					name: t("comparative_graph.sources.violence_staff"),
					value: "violenceStaff",
				},
			].filter((source) => allData[source.value]?.some((num) => num > 0)),
		[allData, t],
	);

	const [selectedGraph, setSelectedGraph] = useState("bar");
	const [selectedTimeframe, setSelectedTimeframe] = useState("year-to-date");
	const [selectedSources, setSelectedSources] = useState(["", "", ""]);

	const monthTimeFrames = useMemo(() => {
		const months = [
			"january",
			"february",
			"march",
			"april",
			"may",
			"june",
			"july",
			"august",
			"september",
			"october",
			"november",
			"december",
		];

		const currentMonthIndex = new Date().getMonth();
		const reorderedMonths = [
			...months.slice(currentMonthIndex + 1),
			...months.slice(0, currentMonthIndex + 1),
		];

		return reorderedMonths.map((month) =>
			t(`principal.user-graph.timeframes.${month}`),
		);
	}, [t]);

	const selectedData = useMemo(() => {
		if (selectedTimeframe === "year-to-date") {
			return selectedSources.map((source) => allData[source] || []);
		}

		const selectedMonthIndex = monthTimeFrames.indexOf(
			t(`principal.user-graph.timeframes.${selectedTimeframe}`),
		);

		return selectedSources.map(
			(source) =>
				allData[source]?.map((value, index) =>
					index === selectedMonthIndex ? value : 0,
				) || [],
		);
	}, [selectedSources, selectedTimeframe, allData, monthTimeFrames, t]);

	const getAvailableSources = (currentIndex) => {
		return filteredSources.filter(
			(source) =>
				!selectedSources.some(
					(selected, index) =>
						selected === source.value && index !== currentIndex,
				),
		);
	};

	const handleSourceChange = (index, value) => {
		setSelectedSources((prev) => {
			const newSources = [...prev];
			newSources[index] = value;
			return newSources;
		});
	};

	const handleTimeframeChange = (value) => {
		if (value !== "year-to-date" && selectedGraph === "line") {
			setSelectedGraph("bar");
		}
		setSelectedTimeframe(value);
	};

	const handleGraphChange = (value) => {
		if (value === "line" && selectedTimeframe !== "year-to-date") {
			setSelectedTimeframe("year-to-date");
		}
		setSelectedGraph(value);
	};

	const chartOptions = {
		bar: t("principal.user-graph.chart-types.bar"),
		line: t("principal.user-graph.chart-types.line"),
		scatter: t("principal.user-graph.chart-types.scatter", "Scatter Plot"),
		area: t("principal.user-graph.chart-types.area", "Area Chart"),
	};

	const filteredChartOptions = useMemo(
		() => ["bar", "line", "scatter", "area"],
		[],
	);

	const displayedGraph =
		selectedGraph === "bar" ? (
			<ReportsBarGraph
				label={monthTimeFrames}
				primaryData={selectedData[0] || null}
				secondaryData={selectedData[1] || null}
				tertiaryData={selectedData[2] || null}
				showdata2
				showdata3
				primaryDataLabel={
					selectedSources[0]
						? filteredSources.find((s) => s.value === selectedSources[0])?.name
						: ""
				}
				secondaryDataLabel={
					selectedSources[1]
						? filteredSources.find((s) => s.value === selectedSources[1])?.name
						: ""
				}
				tertiaryDataLabel={
					selectedSources[2]
						? filteredSources.find((s) => s.value === selectedSources[2])?.name
						: ""
				}
			/>
		) : selectedGraph === "line" ? (
			<ReportsLineGraph
				label={monthTimeFrames}
				primaryData={selectedData[0]}
				secondaryData={selectedData[1]}
				tertiaryData={selectedData[2]}
				showdata2
				showdata3
				primaryDataLabel={
					selectedSources[0]
						? filteredSources.find((s) => s.value === selectedSources[0])?.name
						: ""
				}
				secondaryDataLabel={
					selectedSources[1]
						? filteredSources.find((s) => s.value === selectedSources[1])?.name
						: ""
				}
				tertiaryDataLabel={
					selectedSources[2]
						? filteredSources.find((s) => s.value === selectedSources[2])?.name
						: ""
				}
			/>
		) : selectedGraph === "scatter" ? (
			<ReportsScatter
				label={monthTimeFrames}
				primaryData={selectedData[0]}
				secondaryData={selectedData[1]}
				tertiaryData={selectedData[2]}
				primaryDataLabel={
					selectedSources[0]
						? filteredSources.find((s) => s.value === selectedSources[0])?.name
						: ""
				}
				secondaryDataLabel={
					selectedSources[1]
						? filteredSources.find((s) => s.value === selectedSources[1])?.name
						: ""
				}
				tertiaryDataLabel={
					selectedSources[2]
						? filteredSources.find((s) => s.value === selectedSources[2])?.name
						: ""
				}
			/>
		) : (
			<ReportsArea
				label={monthTimeFrames}
				primaryData={selectedData[0]}
				secondaryData={selectedData[1]}
				tertiaryData={selectedData[2]}
				primaryDataLabel={
					selectedSources[0]
						? filteredSources.find((s) => s.value === selectedSources[0])?.name
						: ""
				}
				secondaryDataLabel={
					selectedSources[1]
						? filteredSources.find((s) => s.value === selectedSources[1])?.name
						: ""
				}
				tertiaryDataLabel={
					selectedSources[2]
						? filteredSources.find((s) => s.value === selectedSources[2])?.name
						: ""
				}
			/>
		);

	const timeframeOptions =
		t("principal.user-graph.timeframes", { returnObjects: true }) || {};

	return (
		<Box
			sx={{
				padding: "3.375rem 1.5rem",
				backgroundColor: "#fff",
				border: "1px solid #9ce0f8",
				borderRadius: "1.25rem",
				margin: "1rem 0",
				display: "flex",
				flexDirection: { xs: "column", md: "row" },
				gap: "2rem",
			}}
		>
			<Box sx={{ flex: 2 }}>
				<Box sx={{ marginBottom: "2rem" }}>
					<Typography
						variant="h2"
						sx={{ color: "#0E6BA8", fontWeight: "700", fontSize: "1.875rem" }}
					>
						{t("principal.user-graph.header")}
					</Typography>
					<Typography
						variant="p"
						sx={{ color: "#0D1120", fontSize: "1.125rem" }}
					>
						{t("principal.user-graph.description")}
					</Typography>
				</Box>
				{displayedGraph}
			</Box>

			<Box
				sx={{ flex: 1, display: "flex", flexDirection: "column", gap: "1rem" }}
			>
				<Typography
					variant="h3"
					sx={{ color: "#0D1120", fontWeight: "700", fontSize: "1.25rem" }}
				>
					{t("principal.user-graph.data-header")}
				</Typography>

				{[0, 1, 2].map((index) => (
					<DropDownSection key={index}>
						<DropDownHeader variant="h3" sx={index === 0 ? {} : visuallyHidden}>
							{t("principal.user-graph.source-header")}
						</DropDownHeader>
						<ReportDropdown
							value={selectedSources[index] || ""}
							onSelect={(value) => handleSourceChange(index, value)}
						>
							<DropdownItem value="">
								{t("comparative_graph.sources.select_source")}
							</DropdownItem>
							{getAvailableSources(index).map((source) => (
								<DropdownItem key={source.value} value={source.value}>
									{source.name}
								</DropdownItem>
							))}
						</ReportDropdown>
					</DropDownSection>
				))}

				<DropDownSection>
					<DropDownHeader variant="h3">
						{t("principal.user-graph.time-header")}
					</DropDownHeader>
					<ReportDropdown
						onSelect={handleTimeframeChange}
						disabled={selectedGraph === "line"}
						value={selectedTimeframe}
					>
						{Object.keys(timeframeOptions).map((timeframe) => (
							<DropdownItem key={timeframe} value={timeframe}>
								{timeframeOptions[timeframe]}
							</DropdownItem>
						))}
					</ReportDropdown>
				</DropDownSection>

				<DropDownSection>
					<DropDownHeader variant="h3">
						{t("principal.user-graph.chart-type-header")}
					</DropDownHeader>
					<ReportDropdown
						onSelect={handleGraphChange}
						disabled={selectedTimeframe !== "year-to-date"}
						value={selectedGraph}
					>
						{filteredChartOptions.map((chartType) => (
							<DropdownItem key={chartType} value={chartType}>
								{chartOptions[chartType]}
							</DropdownItem>
						))}
					</ReportDropdown>
				</DropDownSection>
			</Box>
		</Box>
	);
};

export default UserControlledGraph;
