import classNames from "classnames";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import ResourceList from "./sections/ResourceList";
import ResourceOrderList from "./sections/ResourceOrderList";

const Screen = () => {
  const [activeTab, setActiveTab] = useState("mine");
  const {
    myResources,
    favouritedResources,
    resourceOrders
  } = useSelector(state => state.appInfo);

  return (
    <div className="main-container school-resources">
      <Row>
        <Col md="10" lg="8">
          <h1>My Resources</h1>
          <p>Below, you can explore the learning resources you have ordered or downloaded, favourited, and review your resource order history.</p>
        </Col>
      </Row>

      <div className="resource-tabs-container">
        <Nav tabs className="resource-tabs">
          <NavItem>
            <NavLink
              className={classNames({ active: activeTab === "mine" })}
              onClick={() => setActiveTab("mine")}
            >My Resources</NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames({ active: activeTab === "favorites" })}
              onClick={() => setActiveTab("favorites")}
            >Favourited Resources</NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames({ active: activeTab === "requests" })}
              onClick={() => setActiveTab("requests")}
            >My Requests</NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="mine">
            <ResourceList resources={myResources} />
          </TabPane>
          <TabPane tabId="favorites">
            <ResourceList resources={favouritedResources} featuredLabel="Favourited" />
          </TabPane>
          <TabPane tabId="requests">
            <ResourceOrderList orders={resourceOrders} />
          </TabPane>
        </TabContent>
      </div>
    </div>
  )
}

export default Screen;