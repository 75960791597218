import { useEffect, useState } from "react";
import Airtable from "../../../airtables/PDAirtable";

const useHook = (orderId, requestIds, refresher) => {
  const [loading, setLoading] = useState(true);
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialize = async () => {
    const res = await Airtable.resourceRequests.listByIds(requestIds);
    const rss = res.map(req => ({
      ...req,
      ChangedQuantity: req["Quantity"]
    }))
    setRequests([...rss]);
    setLoading(false);
  }

  const updateRequestQuantity = (reqId, value) => {
    const reqNo = requests.findIndex(req => req.id === reqId);
    const reqlist = [...requests];
    reqlist[reqNo]["ChangedQuantity"] = value;
    setRequests(reqlist);
  }

  const toggleSelectRequest = (reqId, checked) => {
    const reqNo = requests.findIndex(req => req.id === reqId);
    const reqlist = [...requests];
    reqlist[reqNo].selected = checked;
    setRequests(reqlist);
  }

  const splitOrder = async () => {
    const selectedOnes = requests.filter(req => req.selected);
    const unSelectedOnes = requests.filter(req => !req.selected);
    await Airtable.resourceRequests.multipleUpdate(
      selectedOnes.map(req => ({
        id: req.id,
        param: {
          "Quantity": req.ChangedQuantity
        }
      }))
    );
    await Airtable.resourceOrders.update(orderId, {
      "Requests": selectedOnes.map(req => req.id)
    });

    let diffRequests = [];
    for (let i = 0; i < selectedOnes.length; i++) {
      const req = selectedOnes[i];
      if (req.Quantity !== req.ChangedQuantity) {
        diffRequests.push({
          Resource: req.Resource,
          Quantity: req.Quantity - req.ChangedQuantity
        })
      }
    }

    let newReqs = [];
    if (diffRequests.length) {
      const results = await Airtable.resourceRequests.createMultiple(
        diffRequests.map(item => ({
          Resource: item.Resource,
          Quantity: item.Quantity,
          Teacher: selectedOnes[0].Teacher,
        }))
      );
      newReqs = results.map(req => req.id);
    }

    await Airtable.resourceOrders.create({
      Requests: [
        ...newReqs,
        ...unSelectedOnes.map(req => req.id)
      ],
      Status: "Awaiting Inventory",
      Teacher: selectedOnes[0].Teacher
    })
  }

  return {
    loading,
    requests,
    updateRequestQuantity,
    toggleSelectRequest,
    splitOrder
  }
}

export default useHook;