import React from "react";
import "./styles.scss";

export function SliderNextArrow(props) {
  const { className, onClick } = props;
  return (
    <button
      className={className + " slider-next-arrow"}
      onClick={onClick}
    />
  );
}

export function SliderPrevArrow(props) {
  const { className, onClick } = props;
  return (
    <button
      className={className + " slider-prev-arrow"}
      onClick={onClick}
    />
  );
}