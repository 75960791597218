import { Button } from "reactstrap";
import "./styles.scss";

const StatusClass = {
  "Pending Approval": "new-request",
  "Shipped": "received",
  "Rejected": "denied",
  "Approved": "ordered",
  "Awaiting Inventory": "awaiting"
}

const AdminOrderItem = ({ data, onView, onShip, onSplit }) => {
  const dateString = new Date(data["Date"]).toLocaleString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });

  const handleTrackOrder = () => {
    if (data["Waybill"] && !isNaN(data["Waybill"])) {
      window.open(`https://cargo.canadiannorth.com/TrackAndTrace.html?waybillNumber=${data["Waybill"]}`, '_blank');
    }
  };

  const resourceCount = data["Requests"].length;
  const itemCount = data["# Items"];

  return (
    <div className="admin-resource-order-item">
      <div className="order-item order-info">
        <span className="order-no">Order #2000{data["Order ID"]}</span>
        <span className="order-count">{resourceCount} Resource{resourceCount !== 1 ? 's' : ''}, {itemCount} Item{itemCount !== 1 ? 's' : ''}</span>
      </div>
      <div className="order-item order-teacher">
        <span className="name">{data["Teacher Name"]}</span>
        <span className="school">{data["School Name Text"]}</span>
      </div>
      <div className="order-item order-date">{dateString}</div>
      <div className="order-item order-status">
        <span className={StatusClass[data["Status"]]}>
          {data["Status"]}
        </span>
      </div>
      <div className="order-item order-actions">
        <Button
          size="sm"
          color="outline-primary"
          type="button"
          onClick={() => onView()}
        >View Request</Button>
        {data["Status"] !== "Shipped" && resourceCount > 1 &&  (
          <Button
            size="sm"
            color="lightblue"
            type="button"
            onClick={() => onSplit()}
          >Split Order</Button>
        )}
        {data["Status"] === "Shipped" ? (
          data["Waybill"] && !isNaN(data["Waybill"]) && (
            <Button
              size="sm"
              color="primary"
              type="button"
              onClick={handleTrackOrder}
            >Track Shipment</Button>
          )
        ) : (
          <Button
            size="sm"
            color="primary"
            type="button"
            onClick={() => onShip()}
          >Ship Order</Button>
        )}
      </div>
    </div>
  )
}

export default AdminOrderItem;