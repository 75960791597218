import { useEffect, useState } from "react";
import airtable from "../../../airtables/PDAirtable";
import { useSelector } from "react-redux";

const useSchoolOrders = (refresh) => {
  const { gnUserInfo, userInfo } = useSelector(state => state.appInfo);
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    if (!gnUserInfo) return;
    hookInitialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gnUserInfo, refresh])

  const hookInitialize = async () => {
    const schoolTeachers = await airtable.nunavutTeachers.getTeachersInSchool(
      userInfo["School Name Text"][0],
      gnUserInfo["School Name"][0]
    );
    const tNames = schoolTeachers.map(t => t["Teacher Name"]);
    const tIds = schoolTeachers.map(t => t.id);
    const os = await airtable.resourceOrders.getOrdersForTeachers(tNames, tIds);
    setOrders(os);
    setLoading(false);
  }

  return {
    loading,
    orders
  }
}

export default useSchoolOrders;