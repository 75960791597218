import { Box, Typography, CircularProgress } from "@mui/material";
import { LongTextInput, ShortTextInput } from "./inputs";
import { useTranslation } from "react-i18next";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import UpdateIcon from "@mui/icons-material/Update";
import GradingIcon from "@mui/icons-material/Grading";
import { useSelector } from "react-redux";
import { useUserName } from "../hooks/useUserName";

export const SubmittedOverviewResponse = ({
	response,
	handleUpdateResponse,
}) => {
	const { t } = useTranslation();
	const { userInfo } = useSelector((state) => state.appInfo);
	const dea_mode = userInfo["District Leader?"];
	const dea_id = dea_mode ? userInfo.id : null;

	const { userName: submitterName, loading: submitterLoading } = useUserName(
		response.user_id,
	);
	const { userName: reviewerName, loading: reviewerLoading } = useUserName(
		response.dea_user_id,
	);

	return (
		<Box sx={{ marginTop: "1.25rem" }}>
			<Typography
				variant="body1"
				component="p"
				sx={{
					display: "flex",
					gap: "10px",
					flexWrap: "wrap",
					mb: "0.9375rem",
					color: "#000",
				}}
			>
				<PersonAddIcon sx={{ color: "#1094CE" }} />
				<strong>{t(`principal.overview-page.response.submittedBy`)}:</strong>
				{submitterLoading ? <CircularProgress size={16} /> : submitterName}
			</Typography>

			<Typography
				variant="body1"
				component="p"
				sx={{
					display: "flex",
					gap: "10px",
					flexWrap: "wrap",
					mb: "0.9375rem",
					color: "#000",
				}}
			>
				<AddCircleIcon sx={{ color: "#1094CE" }} />
				<strong>{t(`principal.overview-page.response.submittedOn`)}:</strong>
				{new Date(response.submitted_at).toLocaleDateString("en-US", {
					year: "numeric",
					month: "short",
					day: "numeric",
				})}
			</Typography>

			<Typography
				variant="body1"
				component="p"
				sx={{
					display: "flex",
					gap: "10px",
					flexWrap: "wrap",
					mb: "0.9375rem",
					color: "#000",
				}}
			>
				<UpdateIcon sx={{ color: "#1094CE" }} />
				<strong>{t(`principal.overview-page.response.lastEditedOn`)}:</strong>
				{new Date(response.last_saved_at).toLocaleDateString("en-US", {
					year: "numeric",
					month: "short",
					day: "numeric",
				})}
			</Typography>

			{response.dea_user_id && (
				<Typography
					variant="body1"
					component="p"
					sx={{ display: "flex", gap: "10px", flexWrap: "wrap", color: "#000" }}
				>
					<GradingIcon sx={{ color: "#1094CE" }} />
					<strong>{t(`principal.overview-page.response.reviewedBy`)}:</strong>
					{reviewerLoading ? <CircularProgress size={16} /> : reviewerName}
				</Typography>
			)}

			<Box
				sx={{
					backgroundColor: "#F3F9FF",
					padding: "2.50rem",
					mt: "1.88rem",
					mb: "1.25rem",
					boxShadow: "0.625rem 0.75rem 1.5rem 0px rgba(206, 222, 238, 0.47)",
					borderRadius: "1rem",
					border: "1px solid rgba(54, 192, 240, 0.49)",
				}}
			>
				{dea_mode ? (
					<>
						<Typography
							variant="h3"
							sx={{
								fontSize: "1.88rem",
								fontFamily: "Effra, sans-serif",
								fontWeight: "700",
								color: "#0D1120",
								marginBottom: "0.625rem",
							}}
						>
							{t(`principal.overview-page.review-card.heading`)}
						</Typography>
						<Typography fontSize="1rem" color="#000" mb="1.50rem">
							{t(`principal.overview-page.review-card.description`)}
						</Typography>

						<ShortTextInput
							heading={t(`principal.overview-page.review-card.dea-motion`)}
							value={response.dea_motion_number || ""}
							onChange={(val) => {
								handleUpdateResponse({
									dea_motion_number: val,
									dea_user_id: dea_id,
									dea_comment_date: new Date(),
								});
							}}
						/>

						<LongTextInput
							heading={t(`principal.overview-page.review-card.comments`)}
							value={response.dea_comment}
							onChange={(val) => {
								handleUpdateResponse({
									dea_comment: val,
									dea_user_id: dea_id,
									dea_comment_date: new Date(),
								});
							}}
							multiple={true}
						/>
						{response.user_comment && (
							<Box mb="1.88rem">
								<Typography
									variant="h4"
									fontFamily="Effra, sans-serif"
									fontSize="1.125rem"
									fontWeight="700"
									mb="0.625rem"
									color="#000"
								>
									{t(`principal.overview-page.review-card.response`)}
								</Typography>
								<Typography fontSize="1rem" color="#000">
									{response.user_comment
										? response.user_comment
										: "Response TBD"}
								</Typography>
							</Box>
						)}
					</>
				) : (
					<>
						<Box mb="1.88rem">
							<Typography
								variant="h4"
								fontFamily="Effra, sans-serif"
								fontSize="1.125rem"
								fontWeight="700"
								mb="0.625rem"
								color="#000"
							>
								{t(`principal.overview-page.review-card.dea-date`)}
							</Typography>

							<Typography fontSize="1rem" color="#000">
								{response.dea_comment_date
									? new Date(response.dea_comment_date).toLocaleDateString(
											"en-US",
											{
												year: "numeric",
												month: "short",
												day: "numeric",
											},
										)
									: "TBD"}
							</Typography>
						</Box>
						<Box mb="1.88rem">
							<Typography
								variant="h4"
								fontFamily="Effra, sans-serif"
								fontSize="1.125rem"
								fontWeight="700"
								mb="0.625rem"
								color="#000"
							>
								{t(`principal.overview-page.review-card.dea-motion`)}
							</Typography>
							<Typography fontSize="1rem" color="#000">
								{response.dea_motion_number
									? response.dea_motion_number
									: "Motion # TBD"}
							</Typography>
						</Box>
						<Box mb="1.88rem">
							<Typography
								variant="h4"
								fontFamily="Effra, sans-serif"
								fontSize="1.125rem"
								fontWeight="700"
								mb="0.625rem"
								color="#000"
							>
								{t(`principal.overview-page.review-card.comments`)}
							</Typography>
							<Typography fontSize="1rem" color="#000">
								{response.dea_comment ? response.dea_comment : "Comment TBD"}
							</Typography>
						</Box>
						{!dea_mode && (
							<LongTextInput
								heading={t(`principal.overview-page.review-card.add-response`)}
								value={response.user_comment}
								isDisabled={!!response.user_comment}
								onChange={(val) => {
									handleUpdateResponse({ user_comment: val });
								}}
								multiple={true}
							/>
						)}{" "}
					</>
				)}
			</Box>
		</Box>
	);
};
