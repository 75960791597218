import SupabaseTable from "../SupabaseTable";

export class FormResponsesTable extends SupabaseTable {
	listByFormAndSchoolTemplate = async (formTemplateId, school_id) => {
		try {
			const { data, error } = await this.supabase
				.from(this.tableName)
				.select(`
					*,
					forms!inner (
						year,
						month
					)
				`)
				.eq('forms.template_id', formTemplateId)
				.eq('school_id', school_id);

			if (error) {
				throw new Error(error.message);
			}

			return data;
		} catch (error) {
			throw error;
		}
	}
	listAllBySchoolId = async (schoolId) => {
		try {
			const { data, error } = await this.supabase
				.from(this.tableName)
				.select("*")
				.contains("school_ids", [schoolId])
				.order("created_at", { ascending: false });

			if (error) {
				throw new Error(error.message);
			}

			return data;
		} catch (error) {
			throw error;
		}
	};
	listBySchoolAndForm = async (schoolId, formId) => {
		try {
			const { data, error } = await this.supabase
				.from(this.tableName)
				.select("*")
				.contains("school_ids", [schoolId])
				.eq("form_id", formId)
				.order("created_at", { ascending: false });

			if (error) {
				throw new Error(error.message);
			}

			return data;
		} catch (error) {
			throw error;
		}
	};
	selectLatest = async (schoolId) => {
		try {
			const { data, error } = await this.supabase
				.from(this.tableName)
				.select("*")
				.contains("school_ids", [schoolId])
				.order("created_at", { ascending: false })
				.limit(1)
				.maybeSingle();

			if (error) {
				throw new Error(error.message);
			}

			return data;
		} catch (error) {
			throw error;
		}
	};
	selectByFormIdAndUserId = async (formId, userId) => {
		try {
			const { data, error } = await this.supabase
				.from(this.tableName)
				.select("*")
				.eq("form_id", formId)
				.eq("user_id", userId)
				.single();

			if (error) {
				throw new Error(error.message);
			}

			return data;
		} catch (error) {
			throw error;
		}
	};

	selectLatestByFormIdAndUserId = async (formId, userId) => {
		try {
			const { data, error } = await this.supabase
				.from(this.tableName)
				.select("*")
				.eq("form_id", formId)
				.eq("user_id", userId)
				.order("created_at", { ascending: false })
				.single();

			if (error) {
				throw new Error(error.message);
			}

			return data;
		} catch (error) {
			throw error;
		}
	};
}
