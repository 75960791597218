import Axios from "axios";

const passkeyAPI = {
  apiKey: process.env.REACT_APP_PASSKEYS_API_KEY,
  tenantId: process.env.REACT_APP_PASSKEYS_TENANT_ID,
};

export const sendVerifySms = (phone) => {
  return new Promise((resolve, reject) => {
    const url = "https://onsendverifysms-raj5gg67oa-uc.a.run.app";
    Axios.post(url, {
      phonenumber: '+1 ' + phone
    }).then(res => {
      if (res.data.suceess) {
        resolve(res.data.data)
      } else {
        reject(res.data.error)
      }
    }).catch(error => {
      reject(error)
    })
  })
}

export const verifySmsCode = (phone, code) => {
  return new Promise((resolve, reject) => {
    const url = "https://onverifysmscode-raj5gg67oa-uc.a.run.app";
    Axios.post(url, {
      phonenumber: '+1 ' + phone,
      code
    }).then(res => {
      if (res.data.suceess) {
        resolve(res.data.data)
      } else {
        reject(res.data.error)
      }
    }).catch(error => {
      reject(error)
    })
  })
}

export const checkFreeBusy = (email, start, end) => {
  return new Promise((resolve, reject) => {
    const url = `https://research.tigweb.org/connectednorth/calendar-invite/service-account.html?email=${email}&start=${start}&end=${end}`;
    Axios.get(url).then(res => {
      resolve(res.data.status)
    }).catch(error => {
      reject(error)
    })
  })
}

export const sendToTeacher = (
  emails,
  type, // Collection/Session/Event
  teamName,
  teamEmail,
  teacherNames,
  targetTitle,
  targetDescription,
  targetLink,
  customMessage,
  ccEmail,
  attachments,
  niceDate,
  userType,
  appLocale
) => {
  return new Promise((resolve, reject) => {
    const url = "https://sendtoteacher-raj5gg67oa-uc.a.run.app";

    Axios.post(url, {
      emails,
      type, // Collection/Session/Event
      teamName,
      teamEmail,
      teacherNames,
      targetTitle,
      targetDescription: targetDescription || "",
      targetLink,
      customMessage,
      ccEmail: ccEmail ? teamEmail : '',
      attachments: attachments || "",
      niceDate: niceDate || "",
      userType,
      appLocale
    }).then(res => {
      resolve(res.data)
    }).catch(error => {
      reject(error)
    })
  })
}

export const reuqestOTPUri = (userEmail, serviceName) => {
  return new Promise((resolve, reject) => {
    const url = "https://reuqestotpuri-raj5gg67oa-uc.a.run.app";
    Axios.post(url, {
      userEmail,
      serviceName
    }).then(res => {
      if (res.data.suceess) {
        resolve(res.data.data)
      } else {
        reject(res.data.error)
      }
    }).catch(error => {
      reject(error)
    })
  })
}

export const verifyOTP = (userSecret, token) => {
  return new Promise((resolve, reject) => {
    const url = "https://verifyotp-raj5gg67oa-uc.a.run.app";
    Axios.post(url, {
      userSecret,
      token
    }).then(res => {
      if (res.data.suceess) {
        resolve(res.data.data.isValid)
      } else {
        reject(res.data.error)
      }
    }).catch(error => {
      reject(error)
    })
  })
}

export const requestSigninPasscode = (name, email, id) => {
  return new Promise((resolve, reject) => {
    const url = "https://requestpasscode-raj5gg67oa-uc.a.run.app";
    Axios.post(url, {
      userName: name,
      userEmail: email,
      userId: id,
    }).then(res => {
      if (res.data.suceess) {
        resolve(res.data.passcode);
      }
    }).catch(error => {
      reject(error)
    })
  })
}

const passkeyBaseUrl = `https://passkeys.hanko.io/${passkeyAPI.tenantId}`;

export const startServerPasskeyRegistration = (
  userId,
  userEmail,
  userName
) => {
  return new Promise((resolve, reject) => {
    Axios.post(`${passkeyBaseUrl}/registration/initialize`, {
      user_id: userId,
      username: userEmail,
      display_name: userName
    }, {
      headers: {
        apiKey: passkeyAPI.apiKey,
        'Content-Type': 'application/json'
      }
    }).then(res => {
      resolve(res.data);
    }).catch(error => {
      reject(error)
    })
  })
}

export const finishServerPasskeyRegistration = (credential) => {
  return new Promise((resolve, reject) => {
    Axios.post(`${passkeyBaseUrl}/registration/finalize`, credential, {
      headers: {
        apiKey: passkeyAPI.apiKey,
        'Content-Type': 'application/json'
      }
    }).then(res => {
      resolve(res.data);
    }).catch(error => {
      reject(error)
    })
  })
}

export const startServerPasskeyLogin = () => {
  return new Promise((resolve, reject) => {
    Axios.post(`${passkeyBaseUrl}/login/initialize`, {}, {
      headers: {
        apiKey: passkeyAPI.apiKey,
        'Content-Type': 'application/json'
      }
    }).then(res => {
      resolve(res.data);
    }).catch(error => {
      reject(error)
    })
  });
}

export const finishServerPasskeyLogin = (credential) => {
  return new Promise((resolve, reject) => {
    Axios.post(`${passkeyBaseUrl}/login/finalize`, credential, {
      headers: {
        apiKey: passkeyAPI.apiKey,
        'Content-Type': 'application/json'
      }
    }).then(res => {
      resolve(res.data);
    }).catch(error => {
      reject(error)
    })
  });
}

export const confirmResourceRequest = (
  email,
  name,
  order,
  requests
) => {
  return new Promise((resolve, reject) => {
    Axios.post(`https://confirmresourcerequest-raj5gg67oa-uc.a.run.app`, {
      email,
      name,
      resourceOrder: order,
      resourceRequests: requests
    }).then(res => {
      resolve(res.data);
    }).catch(error => {
      reject(error)
    })
  })
}

export const confirmResourceOrderShip = (
  email,
  name,
  order,
  requests
) => {
  return new Promise((resolve, reject) => {
    Axios.post(`https://confirmresourceordership-raj5gg67oa-uc.a.run.app`, {
      email,
      name,
      resourceOrder: order,
      resourceRequests: requests
    }).then(res => {
      resolve(res.data);
    }).catch(error => {
      reject(error)
    })
  })
}