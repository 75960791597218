import { Box, styled } from "@mui/material";
import { ChartCard } from "./cards/ChartCard";
import { NumberCard } from "./cards/NumberCard";
import { useTranslation } from "react-i18next";

const MetricsRow = styled(Box)(() => ({
	display: "flex",
	gap: "1rem",
}));

const sumCount = (data) => data.reduce((acc, curr) => acc + curr, 0);

export const MetricsGrid = ({ data }) => {
	const {
		studentPopulationData,
		graduatingStudentsData,
		communityPopulationData,
		schoolClosureData,
		schoolClosureReasonData,
		suspensionGradeData,
		expulsionGradeData,
		suspensionViolenceStudentsData,
		suspensionViolenceStaffData,
		suspensionHoursInSchoolData,
		suspensionHoursOutOfSchoolData,
	} = data;

	const { t } = useTranslation();

	const gradeLabels = [
		"a",
		"b",
		"c",
		"d",
		"e",
		"f",
		"g",
		"h",
		"i",
		"j",
		"k",
		"l",
		"m",
	].map((key) => t(`principal.metrics-grid.grade-list.${key}`));

	const closureLabels = ["a", "b", "c", "d", "e", "f", "g", "h"].map((key) =>
		t(`db_fields.closureReason.options.${key}`),
	);

	const stats = [
		{
			label: t("principal.metrics-grid.stats-card.violence"),
			value: suspensionViolenceStudentsData || 0,
		},
		{
			label: t("principal.metrics-grid.stats-card.incidents"),
			value: suspensionViolenceStaffData || 0,
		},
	];

	return (
		<Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
			<MetricsRow
				sx={{
					flexDirection: { xs: "column", md: "row" },
					gap: "1rem",
				}}
			>
				<NumberCard
					title={t("principal.metrics-grid.number-card.school-population")}
					number={studentPopulationData?.[0]?.value || 0}
				/>
				<NumberCard
					title={t("principal.metrics-grid.number-card.graduates")}
					number={graduatingStudentsData?.[0]?.value || 0}
				/>
				<NumberCard
					title={t("principal.metrics-grid.number-card.community-population")}
					number={communityPopulationData?.[0]?.value || 0}
				/>
			</MetricsRow>

			<MetricsRow
				sx={{
					flexDirection: { xs: "column", md: "row" },
				}}
			>
				{/* Attendance is TBD */}
				{/* <ChartCard
					title={t("principal.metrics-grid.attendance-card.title")}
					subtitle={t("principal.metrics-grid.attendance-card.description")}
					chartType="bar"
					value="85%"
				/>
				<ChartCard
					title={t("principal.metrics-grid.absences-card.title")}
					subtitle={t("principal.metrics-grid.absences-card.description")}
					chartType="donut"
					value="3"
					labels={["Group 1", "Group 2"]}
				/> */}
				{schoolClosureData?.length !== 0 && (
					<ChartCard
						title={t("principal.metrics-grid.closures-card.title")}
						subtitle={t("principal.metrics-grid.closures-card.description")}
						chartType="donut"
						value={sumCount(schoolClosureReasonData?.counts)}
						data={schoolClosureReasonData?.counts || []}
						labels={closureLabels}
					/>
				)}
			</MetricsRow>
			<MetricsRow
				sx={{
					flexDirection: { xs: "column", md: "row" },
				}}
			>
				<ChartCard
					title={t("principal.metrics-grid.suspensions-card.title")}
					subtitle={t("principal.metrics-grid.suspensions-card.description")}
					chartType="bar"
					value={sumCount(suspensionGradeData)}
					data={suspensionHoursInSchoolData}
					dataSecondary={suspensionHoursOutOfSchoolData}
					labels={gradeLabels}
					showSecondaryData={true}
					primaryDataLabel={"In School Days"}
					secondaryDataLabel={"Out of School Days"}
				/>
				<ChartCard
					title={t("principal.metrics-grid.expulsions-card.title")}
					subtitle={t("principal.metrics-grid.expulsions-card.description")}
					chartType="bar"
					value={sumCount(expulsionGradeData)}
					data={expulsionGradeData}
					labels={gradeLabels}
					primaryDataLabel={"Expulsions"}
				/>
				<NumberCard isLeftAlign={true} stats={stats} />
			</MetricsRow>
		</Box>
	);
};
