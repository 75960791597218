import { useEffect, useState } from "react";
import Airtable from "../../../airtables/PDAirtable";

const useAdminResourceOrders = (refresh) => {
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    (async () => {
      const os = await Airtable.resourceOrders.getAdminOrders();
      setOrders(os);
      setLoading(false);
    })();
  }, [refresh])

  return {
    loading,
    orders
  }
}

export default useAdminResourceOrders;