import { useDispatch, useSelector } from "react-redux";
import Airtable from "../../../airtables/PDAirtable";
import {
  setFavouriteResources,
  setResourceCart,
  setResourceOrders
} from "../../../redux/actions";
import { confirmResourceRequest } from "../../../libs/apis";

const useResource = (resourceId) => {
  const { gnUserInfo } = useSelector(state => state.appInfo);
  const dispatch = useDispatch();

  const createResourceRequest = async (amount) => {
    await Airtable.resourceRequests.create({
      "Teacher": [gnUserInfo.id],
      "Resource": [resourceId],
      "Quantity": amount
    });

    const cart = await Airtable.resourceRequests.getTeacherRequest(gnUserInfo["Teacher Name"], gnUserInfo.id);
    dispatch(setResourceCart(cart));
  }

  const createResourceOrder = async (amount) => {
    const req = await Airtable.resourceRequests.create({
      "Teacher": [gnUserInfo.id],
      "Resource": [resourceId],
      "Quantity": amount
    });
    const resourceOrder = await Airtable.resourceOrders.create({
      "Requests": [req.id],
      "Status": "Pending Approval",
      "Teacher": [gnUserInfo.id],
    });

    await confirmResourceRequest(
      gnUserInfo["Email"],
      gnUserInfo["Teacher Name"],
      resourceOrder,
      [req]
    );

    const ui = await Airtable.nunavutTeachers.select(gnUserInfo.id);
    const orderIds = ui["Resource Orders"];
    const orders = await Airtable.resourceOrders.listByIds(orderIds);
    dispatch(setResourceOrders(orders));

    const cart = await Airtable.resourceRequests.getTeacherRequest(gnUserInfo["Teacher Name"], gnUserInfo.id);
    dispatch(setResourceCart(cart));
  }

  const createResourceOrderByMultipleRequests = async (requests) => {
    const reqs = await Airtable.resourceRequests.multipleUpdate(requests.map(req => ({
      id: req.id,
      param: {
        "Quantity": req.Quantity
      }
    })));
    const resourceOrder = await Airtable.resourceOrders.create({
      "Requests": requests.map(req => req.id),
      "Status": "Pending Approval",
      "Teacher": [gnUserInfo.id],
    });

    await confirmResourceRequest(
      gnUserInfo["Email"],
      gnUserInfo["Teacher Name"],
      resourceOrder,
      reqs
    );

    const ui = await Airtable.nunavutTeachers.select(gnUserInfo.id);
    const orderIds = ui["Resource Orders"];
    const orders = await Airtable.resourceOrders.listByIds(orderIds);
    dispatch(setResourceOrders(orders));

    const cart = await Airtable.resourceRequests.getTeacherRequest(gnUserInfo["Teacher Name"], gnUserInfo.id);
    dispatch(setResourceCart(cart));
  }

  const removeRequest = async (reqId) => {
    await Airtable.resourceRequests.delete(reqId);

    const cart = await Airtable.resourceRequests.getTeacherRequest(gnUserInfo["Teacher Name"], gnUserInfo.id);
    dispatch(setResourceCart(cart));
  }

  const toggleFavourite = async () => {
    const res = await Airtable.resources.select(resourceId);
    let favourites = res["Favourited By"] || [];
    if (favourites.indexOf(gnUserInfo.id) < 0) {
      favourites.push(gnUserInfo.id);
    } else {
      favourites = favourites.filter(f => f !== gnUserInfo.id);
    }
    const updatedResource = await Airtable.resources.update(resourceId, {
      "Favourited By": favourites
    });
    const ui = await Airtable.nunavutTeachers.select(gnUserInfo.id);
    const resourceIds = ui["Favourited Resources"];
    if (resourceIds) {
      Airtable.resources.listByIds(resourceIds).then(rss => {
        dispatch(setFavouriteResources(rss));
      })
    } else {
      dispatch(setFavouriteResources([]));
    }
    return updatedResource;
  }

  return {
    toggleFavourite,
    createResourceRequest,
    createResourceOrder,
    createResourceOrderByMultipleRequests,
    removeRequest
  }
}

export default useResource;