export const getGrade = (key) => {
  return key === "Kindergarten" ? key : `Grades ${key.replace("To", "-")}`;
}

export const setGrade = (grade) => {
  return grade.includes("Grades") ? grade.split(" ")[1].replace("-", "To") : "Kindergarten"
}

export const minHours = {
  "Kindergarten": 485,
  "1To6": 997,
  "7To12": 1045,
}

export const maxDailyMinutes = {
  "Kindergarten": 360,
  "1To6": 360,
  "7To12": 390,
}
