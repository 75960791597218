import SupabaseTable from "../SupabaseTable";
const TEST_MODE = process.env.REACT_APP_TEST_MODE === "TRUE";

export class FormsTable extends SupabaseTable {
	selectActive = async () => {
		try {
			const { data, error } = await this.supabase
				.from("forms")
				.select("*")
				.eq("status", TEST_MODE ? "test" : "active")
				.order("created_at", { ascending: false })
				.single();
			if (error) {
				throw new Error(error.message);
			}

			return data;
		} catch (error) {
			throw error;
		}
	};

	listActiveAndInactive = async () => {
		try {
			const { data, error } = await this.supabase
				.from("forms")
				.select("*")
				.in("status", ["active", "inactive"])
				.order("created_at", { ascending: false });

			if (error) {
				throw new Error(error.message);
			}

			return data;
		} catch (error) {
			throw error;
		}
	};
	getFormById = async (formId) => {
		try {
			const { data, error } = await this.supabase
				.from("forms")
				.select("name")
				.eq("id", formId)
				.single();
			console.log("formId", formId);
			if (error) {
				throw new Error(error.message);
			}

			return data?.name || null;
		} catch (error) {
			throw error;
		}
	};

	getFormIdsByTemplateId = async (templateId, month, year) => {
		try {
			const { data, error } = await this.supabase
				.from("forms")
				.select(
					`
        id
      `,
				)
				.eq("template_id", templateId)
				.eq("month", month)
				.eq("year", year);

			if (error) {
				throw new Error(error.message);
			}

			return data;
		} catch (error) {
			throw error;
		}
	};
}
