import AirtableTable from "../../AirtableTable";

export class ResourceReviewTable extends AirtableTable {
	constructor(instance) {
		super(instance, "Resource Reviews");

		this.fields = {
			"Resource": "fldoKdDOtgeQKPXiP",
			"Reviewer": "fldduuYHlrIAJzjB4",
			"Rating": "fldq0jqP6yHXjbOol",
			"Review": "fld6fBCrVg9MdNcPg",
			"Reviewer Name": "flduMcr5qAXZbMqsV"
		};
	}
}
