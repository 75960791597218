import AirtableTable from "../AirtableTable";

export class CreditCodesTable extends AirtableTable {
	constructor(instance) {
		super(instance, "tblxuMYICXjrvaRfe");

		this.fields = {
			"Code": "fldGmKk317FpaDNdr",
            "Credits": "fldxl5FkO9EYB8aMt",
            "Code Expiry": "fldbplx8Lj8jEPHmu",
			"Credit Expiry": "fldYmmCnjygWneeeC",
            "Uses": "fld31zR39hGq1LxC7",
			"Description": "fldXcOBuQ34j0Yxh0",
			"Claimed by": "fldwAqKqPq6DxBSHT",
			"Claimed Teacher IDs": "fldZZSy7ZuHGfe6AQ"
		}
	}
}
