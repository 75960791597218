import axios from 'axios';
import { TEACHER_FIELDS } from '../pages/OnboardTeacher/constants/teacher-fields';

const { NAME, EMAIL, LANGUAGE, INDIGENOUS_GROUPS } = TEACHER_FIELDS;

export const addTeacherToMailchimp = async (teacher) => {
  try {
    const response = await axios.post(
      'https://us-central1-connected-north-staging.cloudfunctions.net/addTeacherToMailchimp',
      {
        teacher: {
          email: teacher[EMAIL],
          fullName: teacher[NAME],
          id: teacher.id,
          schoolPT: teacher["School Province or Territory"],
          preferredLanguage: teacher[LANGUAGE],
          indigenousGroups: teacher[INDIGENOUS_GROUPS]
        }
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error adding teacher to Mailchimp:', error.response?.data || error.message);
    throw error;
  }
};
