import {
	FormControl,
	Select,
	MenuItem,
	styled,
	CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAvailableReportDates } from "../hooks/useAvailableReportDates";
import { useEffect } from "react";

const DropdownItem = styled(MenuItem)(() => ({
	color: "#0D1120",
	fontFamily: "Effra, sans-serif",
	fontWeight: "500",
}));

const DEFAULT_MONTH = "12";
const DEFAULT_YEAR = "2024";

export const ReportingLayout = ({
	children,
	selectedMonth,
	selectedYear,
	onDateSelect,
	schoolId,
}) => {
	const { dates, loading, newestDate } = useAvailableReportDates(schoolId);

	// Set the newest date when:
	// 1. Component first loads (no selectedMonth/Year)
	// 2. School changes
	// 3. Dates become available
	useEffect(() => {
		if (newestDate && (!selectedMonth || !selectedYear)) {
			onDateSelect(newestDate.value);
		}
	}, [newestDate, schoolId, selectedMonth, selectedYear, onDateSelect]);

	const handleDateChange = (event) => {
		const value = event.target.value;
		onDateSelect(value);
	};

	// Use selected values if available, otherwise use default or newest date
	const currentValue =
		selectedMonth && selectedYear
			? `${selectedYear}-${selectedMonth.padStart(2, "0")}`
			: newestDate?.value || `${DEFAULT_YEAR}-${DEFAULT_MONTH}`;

	return (
		<div className="reporting-layout">
			<div className="header-section">
				<h2>Report Highlights</h2>
				<div className="filters">
					<FormControl
						sx={{
							minWidth: 250,
							width: "auto",
							backgroundColor: "#FFF",
							overflow: "hidden",
							borderRadius: "1.25rem",
							border: "1px solid #9DE0F8",
							padding: "0",
						}}
					>
						{loading ? (
							<div
								style={{
									padding: "0.5rem",
									display: "flex",
									justifyContent: "center",
								}}
							>
								<CircularProgress size={24} />
							</div>
						) : (
							<Select
								value={currentValue === "all-time" ? "all-time" : currentValue}
								onChange={handleDateChange}
								IconComponent={ExpandMoreIcon}
								displayEmpty
								sx={{
									color: "#0D1120",
									fontFamily: "Effra, sans-serif",
									fontWeight: "500",
									padding: "0",
									"& fieldset": {
										border: "none",
									},
									"& .MuiSelect-select": {
										padding: "0.5rem",
										marginLeft: "0.5rem",
									},
								}}
							>
								<DropdownItem value="all-time">Year To Date</DropdownItem>
								{dates.map(({ value, label }) => (
									<DropdownItem key={value} value={value}>
										{label}
									</DropdownItem>
								))}
							</Select>
						)}
					</FormControl>
				</div>
			</div>
			{children}
		</div>
	);
};
