import React from "react";
import { getPluralString } from "../../../../utils/array";
import "./styles.scss";

const StatusMap = {
  "Pending Approval": "New Request",
  "Shipped": "Received",
  "Rejected": "Rejected",
  "Approved": "Ordered"
}

const StatusClass = {
  "Pending Approval": "new-request",
  "Shipped": "received",
  "Rejected": "denied",
  "Approved": "ordered"
}

const ResourceOrderItem = ({ data }) => {

  const dateString = new Date(data["Date"]).toLocaleString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });

  return (
    <div className="resource-order-item">
      <div className="order-date">{dateString}</div>
      <div className="resource-info">
        <span className="req-no">Order #2000{data["Order ID"]}</span>
        <span>{getPluralString(data["# Resources"], "Resource")}</span>
      </div>
      <div className={`resource-status ${StatusClass[data["Status"]]}`}>
        <span>{StatusMap[data["Status"]]}</span>
      </div>
    </div>
  )
}

export default ResourceOrderItem;