import React from 'react';

export const TeacherFocus = ({
  grades,
  subjects,
  regions
}) => {

  return (
    <section
      className="w-max text-white teacher-focus"
      style={{
        backgroundColor: "#0E6BA8",
        borderRadius: "15px",
        boxShadow: "10px 12px 24px 0px rgba(206, 222, 238, 0.47)",
      }}
    >
      <h3 className="text-white h4 font-weight-bold">
        My Teaching Focus
      </h3>
      <div>
        <div className="visible-print-inline my-3">
          <h6 className="text-white font-weight-bold d-inline">
            Grade:&nbsp;
          </h6>
          <p className="d-inline"><Formater arr={grades} /></p>
        </div>
        <div className="visible-print-inline my-3">
          <h6 className="text-white font-weight-bold d-inline">
            Subject Area:&nbsp;
          </h6>
          <p className="d-inline"><Formater arr={subjects} /></p>
        </div>
        <div className="visible-print-inline my-3">
          <h6 className="text-white font-weight-bold d-inline">
            Province/Territory:&nbsp;
          </h6>
          <p className="d-inline"><Formater arr={regions} /></p>
        </div>
        <p>
          * You are viewing Curriculum Expectations from the province
          or territory of your school.
        </p>
      </div>
      <a
        className="btn"
        href="/cn/mysettings"
        style={{ backgroundColor: "white", color: "#2B4F70" }}
      >
        Change Settings
      </a>
    </section>
  )
}

const Formater = ({ arr }) => arr && arr.join(",")