import { Breadcrumb as BC, BreadcrumbItem } from "reactstrap";
import useResourceDetail from "./hooks/use-resource-detail";
import SectionLoader from "../../components/SectionLoader";
import { Rating } from "@mui/material";
import placeholderImg from "../../assets/img/placeholder.png";
import InfoIcon from "../../assets/img/icons/menu/user.svg";
import ResourceReview from "./components/ResourceReview";
import { useSelector } from "react-redux";
import RequestResourceModal from "../modals/RequestResource";
import { Fragment, useEffect, useState } from "react";
import SchoolResourceReview from "../modals/SchoolResourceReview";
import Slider from "react-slick";
import ResourceItem from "./components/ResourceItem";
import { ResourceSliderSettings } from "../../utils/constants";
import SessionInfo from "../../components/SessionInfo";
import { getActionMenus, getDisplayInfos } from "../SessionInfoDisplay";
import Carousel from "react-multi-carousel";
import Loading from "../../components/Loading";

const responsiveSessions = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 2000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 2000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 480 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 480, min: 0 },
    items: 1,
  },
};

const Screen = (props) => {
  const { gnUserInfo, myResources, userType } = useSelector(state => state.appInfo);
  const resourceId = props.match.params.resourceId;
  const {
    loading,
    resource,
    updateResource,
    toggleFavourite,
    favProcessing,
    reviews,
    author,
    similarResources,
    relatedSessions,
    providerSessions
  } = useResourceDetail(resourceId);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [canReview, setCanReview] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);

  useEffect(() => {
    if (!resource) {
      window.document.title = `Learning Resources - Connected North`;
    } else {
      window.document.title = `${resource["Title"]} - Learning Resources - Connected North`;
    }

    return () => {
      window.document.title = "Connected North";
    }
  }, [resource])

  useEffect(() => {
    if (!myResources || myResources.length === 0) return;
    const rIds = (myResources || []).map(r => r.id);
    if (rIds.indexOf(resourceId) >= 0) setCanReview(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myResources])

  const setPlaceholderImage = (event) => {
    event.target.src = placeholderImg;
  }

  const renderAuthor = () => {
    if (!author) return null;

    return (
      <div className="resource-author mt-5">
        <h2>About the Author</h2>
        <div className="resource-author-content">
          <div className="resource-author-avatar">
            <img src={author["Logo/Photo"][0].url} alt="Content Provider" />
          </div>
          <div className="resource-author-info">
            <h4>{author['Name']}</h4>
            <span>
              {[
                author['Indigenous Group(s)']?.length > 0
                  ? (author['Indigenous Group(s)'].length === 2
                    ? author['Indigenous Group(s)'].join(" and ")
                    : author['Indigenous Group(s)'].slice(0, -1).join(", ") +
                    (author['Indigenous Group(s)'].length > 2 ? ", and " : "") +
                    author['Indigenous Group(s)'][author['Indigenous Group(s)'].length - 1])
                  : null,
                author['Cultural Group(s)']?.length > 0
                  ? (author['Cultural Group(s)'].length === 2
                    ? author['Cultural Group(s)'].join(" and ")
                    : author['Cultural Group(s)'].slice(0, -1).join(", ") +
                    (author['Cultural Group(s)'].length > 2 ? ", and " : "") +
                    author['Cultural Group(s)'][author['Cultural Group(s)'].length - 1])
                  : null,
                author['Indigenous Communities Text']
              ].filter(x => x).join(" | ")}
            </span>
            <p>{author["Introduction/Bio"]}</p>
            <button
              className="btn btn-outline-primary alt"
              onClick={() => window.location.href = `/cn/provider/${author.id}`}
            >
              View Profile
            </button>
          </div>
        </div>
      </div>
    )
  }

  const renderReviews = () => {
    if (!reviews || reviews.length === 0) return null;

    return (
      <div className="resource-detail-reviews mt-5">
        <h2>What Teachers Are Saying</h2>
        <div className="resource-review-list">
          {reviews.slice(0, 2).map((item, index) => (
            <ResourceReview key={index} data={item} />
          ))}
        </div>
      </div>
    )
  }

  const renderSimilarResources = () => {
    if (!similarResources || similarResources.length === 0) return null;

    return (
      <div className="related-resources resources-slider mt-5">
        <h2>Similar Resources</h2>
        <div className="related-resource-list">
          <Slider {...ResourceSliderSettings}>
            {similarResources.map((item, i) => (
              <div className="resource-item-wrapper" key={i}>
                <ResourceItem data={item} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    )
  }

  const renderRelatedSessions = () => {
    if (!relatedSessions || relatedSessions.length === 0) return null;

    return (
      <div className="related-sessions session-carousel-wrap mt-5">
        <h2>Related Sessions</h2>
        <Carousel
          responsive={responsiveSessions}
          renderButtonGroupOutside={true}
          infinite={false}
          partialVisible={true}
          containerClass="carousel-container"
          itemClass="carouselItem"
        >
          {relatedSessions.map((session, i) => (
            <SessionInfo
              key={i}
              displayInfos={getDisplayInfos(session, userType)}
              actionMenu={getActionMenus(session, userType)}
              sessionDetailLink={session.id}
              requestSupport={(e) => {
                e.preventDefault();
                window.location.href = "tel:8679920055";
              }}
              session={session}
            />
          ))}
        </Carousel>
      </div>
    )
  }

  const renderProviderSessions = () => {
    if (!providerSessions || providerSessions.length === 0) return null;

    return (
      <div className="provider-sessions session-carousel-wrap mt-5">
        <h2>Sessions by this provider</h2>
        <Carousel
          responsive={responsiveSessions}
          renderButtonGroupOutside={true}
          infinite={false}
          partialVisible={true}
          containerClass="carousel-container"
          itemClass="carouselItem"
        >
          {providerSessions.map((session, i) => (
            <SessionInfo
              key={i}
              displayInfos={getDisplayInfos(session, userType)}
              actionMenu={getActionMenus(session, userType)}
              sessionDetailLink={session.id}
              requestSupport={(e) => {
                e.preventDefault();
                window.location.href = "tel:8679920055";
              }}
              session={session}
            />
          ))}
        </Carousel>
      </div>
    )
  }

  if (loading || !gnUserInfo) {
    return (
      <SectionLoader />
    )
  }

  const imageData = resource.Image ? resource.Image[0].url : placeholderImg;
  const rating = resource["Average Rating"] || 0;
  return (
    <div className="main-container detail-resource-page">
      <BC>
        <BreadcrumbItem>
          <a href="/">Home</a>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <a href="/cn/school-resources">Resources</a>
        </BreadcrumbItem>
        <BreadcrumbItem active>{resource["Title"]}</BreadcrumbItem>
      </BC>
      <div className="resource-detail-info">
        <div className="resource-detail-beta-info">
          <div className="beta-info-content">
            {!!resource.Author && (
              <div className="beta-info-item">
                <img src={InfoIcon} alt="resource author" />
                <span>Author: <span>{resource["Author"]}</span></span>
              </div>
            )}

            {!!resource["Language"] && (
              <div className="beta-info-item">
                <img src={InfoIcon} alt="resource author" />
                <span>Language: <span>{resource["Language"] && resource["Language"].join(", ")}</span></span>
              </div>
            )}

            {!!resource.Type && (
              <div className="beta-info-item">
                <img src={InfoIcon} alt="resource author" />
                <span>Type: <span>{resource["Type"]}</span></span>
              </div>
            )}

            {!!resource["Grade Level"] && (
              <div className="beta-info-item">
                <img src={InfoIcon} alt="resource author" />
                <span>Grade: <span>{resource["Grade Level"]}</span></span>
              </div>
            )}

            {!!resource["Strand"] && (
              <div className="beta-info-item">
                <img src={InfoIcon} alt="resource author" />
                <span>Strand: <span>{resource["Strand"]}</span></span>
              </div>
            )}

            {!!resource["Item Number"] && (
              <div className="beta-info-item">
                <img src={InfoIcon} alt="resource author" />
                <span>Item No: <span>{resource["Item Number"]}</span></span>
              </div>
            )}

            {!!resource["ISBN"] && (
              <div className="beta-info-item info-isbn">
                <img src={InfoIcon} alt="resource author" />
                <span>ISBN No: <span>{resource["ISBN"]}</span></span>
              </div>
            )}
          </div>
        </div>
        <div className="resource-detail-content">
          <div className="resource-detail-image">
            <div className="resource-image">
              <img
                src={imageData}
                onError={setPlaceholderImage}
                alt={"name of resource"}
              />
            </div>
            <div className="resource-actions">
              <button
                className="btn btn-primary btn-sm"
                onClick={() => setShowRequestModal(true)}
              >
                <i className="fa fa-plus" />{` Request Resource`}
              </button>
              {(resource["Favourited By"] && resource["Favourited By"].indexOf(gnUserInfo.id) >= 0) ? (
                <button
                  className="btn btn-outline-danger alt"
                  onClick={() => toggleFavourite()}
                >
                  {favProcessing ? (
                    <Loading size={22} />
                  ) : (
                    <Fragment><i className="fa fa-heart" />&nbsp;Remove from Favorites</Fragment>
                  )}
                </button>
              ) : (
                <button
                  className="btn btn-outline-primary alt"
                  onClick={() => toggleFavourite()}
                >
                  {favProcessing ? (
                    <Loading size={22} />
                  ) : (
                    <Fragment><i className="fa fa-heart-o" />&nbsp;Save to Favorites</Fragment>
                  )}
                </button>
              )}
              {canReview && (
                <button
                  className="btn btn-secondary btn-sm"
                  onClick={() => setShowReviewModal(true)}
                >
                  Leave Review
                </button>
              )}
            </div>
          </div>
          <div className="resource-title">
            <h2>{resource.Title}</h2>
          </div>
          <div className="resource-other-content">
            {!!resource?.Author && (
              <h3>Author: {resource?.Author}</h3>
            )}
            {!!rating && (
              <div className="resource-review">
                <span>{rating}/5 Stars</span>
                <Rating
                  value={rating}
                  max={5}
                  readOnly
                />
              </div>
            )}
            {!!resource?.Description && (
              <Fragment>
                <h4>Description</h4>
                <p>{resource?.Description}</p>
              </Fragment>
            )}
          </div>
        </div>
      </div>

      {renderReviews()}
      {renderAuthor()}
      {renderSimilarResources()}
      {renderRelatedSessions()}
      {renderProviderSessions()}

      {!!resource && showRequestModal && (
        <RequestResourceModal
          resource={resource}
          toggle={() => setShowRequestModal(!showRequestModal)}
        />
      )}

      {showReviewModal && (
        <SchoolResourceReview
          data={resource}
          toggle={() => setShowReviewModal(!showReviewModal)}
          onDone={(ur) => updateResource(ur)}
        />
      )}
    </div>
  )
}

export default Screen;