import React from "react";
import "./styles.scss"
import { Button } from "reactstrap";

const StatusMap = {
  "Pending Approval": "New Request",
  "Shipped": "Received",
  "Rejected": "Rejected",
  "Approved": "Ordered"
}

const StatusClass = {
  "Pending Approval": "new-request",
  "Shipped": "received",
  "Rejected": "denied",
  "Approved": "ordered"
}

const ViewButtonLabels = {
  "Pending Approval": "Review Request",
  "Shipped": "Received",
  "Rejected": "Rejected",
  "Approved": "Ordered"
}

const SchoolResourceOrderItem = ({ data, onView }) => {
  const dateString = new Date(data["Date"]).toLocaleString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });

  return (
    <div className="school-resource-order-item">
      <div className="order-teacher">{data["Teacher Name"]}</div>
      <div className="order-date">{dateString}</div>
      <div className="order-info">
        <span className="order-no">Order #2000{data["Order ID"]}</span>
        <span>{data["Requests"].length} Resources</span>
      </div>
      <div className="order-status">
        <span className={StatusClass[data["Status"]]}>
          {StatusMap[data["Status"]]}
        </span>
      </div>
      <div className="order-actions">
        <Button
          size="sm"
          color="outline-primary"
          type="button"
          onClick={() => onView()}
        >{ViewButtonLabels[data["Status"]]}</Button>
      </div>
    </div>
  )
}

export default SchoolResourceOrderItem;