import DateInput from "./DateInput";
import FileInput from "./FileInput";
import NumberInput from "./NumberInput";
import RadioInput from "./RadioInput";
import MultiSelectInput from "./MultiSelectInput";
import SingleSelectInput from "./SingleSelectInput";
import LongTextInput from "./LongTextInput";
import ShortTextInput from "./ShortTextInput";
import CheckboxInput from "./CheckboxInput";
import { Fragment } from "react";

function getInputByType(type) {
	switch (type) {
		case "short text":
			return ShortTextInput;
		case "long text":
			return LongTextInput;
		case "number":
			return NumberInput;
		case "date":
			return DateInput;
		case "file":
			return FileInput;
		case "single select":
			return SingleSelectInput;
		case "multi select":
			return MultiSelectInput;
		case "radio":
			return RadioInput;
		case "checkbox":
			return CheckboxInput;
		default:
			return Fragment;
	}
}

export const InputField = ({ type, ...props }) => {
	if (!type) return null;
	const Input = getInputByType(type);
	if (!Input) {
		return <div>Unknown Input type: {type}</div>;
	}
	return <Input {...props} />;
};

export {
	DateInput,
	FileInput,
	NumberInput,
	RadioInput,
	MultiSelectInput,
	SingleSelectInput,
	LongTextInput,
	ShortTextInput,
	CheckboxInput,
};
