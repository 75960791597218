import AirtableTable from "../../AirtableTable";

export class CalendarDaysTable extends AirtableTable {
  constructor(instance) {
    super(instance, "Calendar Days");

    this.fields = {
      "ID": "fldq5KpKy73OoO0NT",
      "Display": "fldKrq6eejcWznrgm",
      "Calendar": "fld28MHZCZPHlmkj3",
      "Name": "fldPsIwjPK5lvCsab",
      "Year": "fldeaAcsLELXksRRi",
      "Date": "fldqni2heNm8uuPsE",
      "Type": "fldFenlEkDHrm44bG",
      "Length": "fldaHgV4BUVZj9km3",
      "Minutes": "fldyj5CzWtmA0r7o7",
    };
  }

  getGenericCalendarDays = (startDate, endDate) => {
    // Validate input dates
    if (!startDate || !endDate) {
      console.warn('Invalid dates provided to getGenericCalendarDays:', { startDate, endDate });
      return [];
    }

    // Parse dates and set to noon to avoid timezone issues
    const parsedStartDate = new Date(startDate);
    parsedStartDate.setHours(12, 0, 0, 0);
    const parsedEndDate = new Date(endDate);
    parsedEndDate.setHours(12, 0, 0, 0);

    // Validate parsed dates
    if (isNaN(parsedStartDate.getTime()) || isNaN(parsedEndDate.getTime())) {
      console.warn('Invalid date format provided to getGenericCalendarDays:', { startDate, endDate });
      return [];
    }

    // Format the start and end dates in ISO format (YYYY-MM-DD)
    const formattedStartDate = parsedStartDate.toISOString().split('T')[0];  // YYYY-MM-DD
    const formattedEndDate = parsedEndDate.toISOString().split('T')[0];  // YYYY-MM-DD

    const condition = {
      filterByFormula: `AND(
        {Date} >= "${formattedStartDate}",
        {Date} <= "${formattedEndDate}",
        NOT({Calendar})
      )`,
      sort: [{ field: "Date", direction: "asc" }],
    };

    return this.list(condition);
  };

}
