import React, { createContext, useContext } from "react";
import { useResponseHandler } from "../hooks/use-response-handler";
import { useFormNavigation } from "../hooks/use-form-navigation.hook";

const ProgressContext = createContext();

export const ProgressProvider = ({ form, children }) => {
	const {
		handleFormResponseCreation,
		handleUpdateResponse,
		handleUpdateField,
		handleAddInstance,
		handleDeleteInstance,
		handleChangeGroupCompletion,
		handleAgreeToTerms,
		updateProgress,
	} = useResponseHandler(form);

	const {
		routes,
		activeRoute,
		handleRouteChange,
		handleRouteNext,
		handleRoutePrev,
	} = useFormNavigation(form);

	const response = {
		status: form.response_status,
		dea_comment: form.dea_comment,
		dea_user_id: form.dea_user_id,
		user_id: form.user_id,
		user_comment: form.user_comment,
		submitted_at: form.submitted_at,
		created_at: form.created_at,
		last_saved_at: form.last_saved_at,
		dea_motion_number: form.dea_motion_number,
		dea_comment_date: form.dea_comment_date,
	};
	return (
		<ProgressContext.Provider
			value={{
				form,
				response,
				progress: form.progress,
				status: form.response_status,
				handleFormResponseCreation,
				handleUpdateResponse,
				handleUpdateField,
				handleAddInstance,
				handleDeleteInstance,
				handleChangeGroupCompletion,
				updateProgress,
				activeRoute,
				routes,
				handleRouteNext,
				handleRoutePrev,
				handleRouteChange,
				handleAgreeToTerms,
			}}
		>
			{children}
		</ProgressContext.Provider>
	);
};

export const useProgress = () => {
	return useContext(ProgressContext);
};
