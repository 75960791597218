export * from "./kitikmeot_calendar";
export * from "./kivalliq_calendar";
export * from "./qikiqtani_calendar";

export const years = [
	// "2023-24",
	"2024-25",
	"2025-26",
	"2026-27",
];
