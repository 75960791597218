import { useEffect, useState } from "react";
import Airtable from "../../../airtables/PDAirtable";
import CNAirtable from "../../../airtables";
import useResource from "./use-resource";

const useResourceDetail = (resId) => {
  const [loading, setLoading] = useState(true);
  const [resource, setResource] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [author, setAuthor] = useState(null);
  const [similarResources, setSimilarResources] = useState([]);
  const [relatedSessions, setRelatedSessions] = useState([]);
  const [providerSessions, setProviderSessions] = useState([]);
  const [favProcessing, setFavProcessing] = useState(false);
  const { toggleFavourite } = useResource(resId);

  useEffect(() => {
    if (!resId) return;
    initialize(resId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resId]);

  const initialize = async (resId) => {
    try {
      setLoading(true);
      const res = await Airtable.resources.select(resId);
      setResource(res);
      setLoading(false);

      if (res["Resource Reviews"]) {
        const rvs = await Airtable.resourceReviews.listByIds(res["Resource Reviews"]);
        setReviews(rvs);
      }

      configProvider(res);

      const smRes = await Airtable.resources.getSimilarResources(
        res.id,
        res["Strand"],
        res["Grade Level"],
        res["Language"]
      );

      if (res["Related Sessions"]) {
        const ss = await Airtable.sessions.listByIds(res["Related Sessions"]);
        const sessionIds = ss.map(session => session["Record ID"]);
        const sessions = await CNAirtable.providerSessions.listByIds(sessionIds)
        setRelatedSessions(sessions);
      }
      setSimilarResources(smRes);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }

  const configProvider = async (res) => {
    if (!res["Content Provider"]) return;

    const pInfo = await Airtable.providers.select(res["Content Provider"][0]);
    if (!pInfo) return;

    const provider = await CNAirtable.providers.select(pInfo["Record ID"]);
    setAuthor(provider);

    if (!provider || !provider["Provider Sessions"]) return;
    const pSessions = await CNAirtable.providerSessions.listByIds(provider["Provider Sessions"]);
    setProviderSessions(pSessions);
  }

  const toggleFavouriteSync = async () => {
    if (favProcessing) return;
    setFavProcessing(true);
    const nRes = await toggleFavourite();
    setResource(nRes);
    setFavProcessing(false);
  }

  return {
    loading,
    resource,
    reviews,
    updateResource: setResource,
    similarResources,
    author,
    relatedSessions,
    providerSessions,
    toggleFavourite: toggleFavouriteSync,
    favProcessing
  }
}

export default useResourceDetail;