import { useState } from "react";
import Airtable from "../../../airtables";

export function useRedeemCode(initialCode = "") {
  const [code, setCode] = useState(initialCode);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  async function redeemCode(teacherId) {
    if (!code) return;
    
    setLoading(true);
    setError("");
    
    try {
      // First check if teacher has already claimed this code
      const alreadyClaimed = await Airtable.creditCodes.list({
        filterByFormula: `AND(
          {Code} = '${code}',
          FIND('${teacherId}', ARRAYJOIN({Claimed Teacher IDs}, ",")) > 0
        )`
      });

      if (alreadyClaimed && alreadyClaimed.length > 0) {
        throw new Error("Oops! You've already redeemed this Promotional Code and received these extra Credits.");
      }

      // Query the Credit Codes table for valid codes
      const results = await Airtable.creditCodes.list({
        filterByFormula: `AND(
          {Code} = '${code}',
          OR(
            {Code Expiry} = '',
            {Code Expiry} > '${new Date().toISOString()}'
          ),
          {Uses} > {Claims}
        )`
      });

      if (!results || results.length === 0) {
        throw new Error("Sorry, this code is invalid, expired, or has reached its maximum uses.");
      }

      const creditCode = results[0];
      
      // Create credit record
      await Airtable.credit.create({
        "Teacher": [teacherId],
        "Credits": creditCode["Credits"],
        "Action": "Promotional Code Redeemed",
        "Display Note": creditCode["Description"] || `Redeemed promo code: ${code}`,
        "Credit Codes": [creditCode.id],
        "Expiry Date": creditCode["Credit Expiry"]
      });

      // Get current record to access existing claimed teachers
      const currentRecord = await Airtable.creditCodes.select(creditCode.id);
      const existingTeachers = currentRecord["Claimed by"] || [];
      
      // Update the Credit Code record by appending the new teacher
      await Airtable.creditCodes.update(creditCode.id, {
        "Claimed by": [...existingTeachers, teacherId]
      });

      setSuccess(true);
      setCode("");
      
      // Reset success message after 5 seconds
      setTimeout(() => setSuccess(false), 5000);
      
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }

  return {
    code,
    setCode,
    loading,
    error,
    success,
    redeemCode
  };
} 