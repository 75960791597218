import { useSelector } from "react-redux";
import Carousel from "../../../components/Carousel";
import * as Achievements from "../assets/achievement";
import { Card } from "../../../components/Card";
import { nanoid } from "nanoid";
import { useTranslation } from "react-i18next";
import s from "../style.module.scss";

const responsiveAchievements = {
	superLargeDesktop: {
		breakpoint: { max: 4000, min: 3000 },
		items: 4,
		partialVisibilityGutter: 0,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 4,
		partialVisibilityGutter: 0,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
		partialVisibilityGutter: 0,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		partialVisibilityGutter: 0,
	},
};

export function AchievementBadges() {
	const {
		ratedSessions,
		ratedBadges,
		reviewedSessions,
		reviewedBadges,
		uniqueProviders,
		uniqueProvidersBadges,
		appLocale,
		settingsUpdated,
	} = useAchivements();

	const { t } = useTranslation();
	const isFr = appLocale === "fr";

	return (
		<Carousel
			responsive={responsiveAchievements}
			title={t("dashboard-page.your-achievements")}
			infinite={false}
			containerClass="achievements-carousel"
		>
			{/* SETTING UPDATED */}
			<AchievementCard
				title={t("dashboard-page.badge.setting-updated")}
				badge={Achievements.settingsUpdated.badge}
				value={settingsUpdated ? 1 : 0}
				achieved={settingsUpdated}
				target={1}
			>
				{/* earned */}
				{settingsUpdated ? (
					isFr ? (
						<p>
							You updated your settings and earned{" "}
							<strong>5 credits</strong>!
						</p>
					) : (
						<p>
							You updated your settings and earned{" "}
							<strong>5 credits</strong>!
						</p>
					)
				) : (
					<>
						{/* not earned */}
						{isFr ? (
							<p>
								Vous avez mis à jour vos paramètres et gagné
								<strong>5 crédits</strong>!
							</p>
						) : (
							<p>
								Update your settings and earn{" "}
								<strong>5 credits</strong>!
							</p>
						)}
					</>
				)}
			</AchievementCard>

			{/* RATED SESSIONS */}
			{ratedBadges.map((r) => (
				<AchievementCard
					key={nanoid(10)}
					title={t("dashboard-page.badge.rated-sessions", {
						sessions: r.target,
					})}
					badge={r.badge}
					value={ratedSessions}
					target={r.target}
					achieved={r.earned}
				>
					{r.earned ? (
						<>
							{/* achievement earned */}
							{isFr ? (
								<p>
									You rated {r.target} sessions this year and
									earned{" "}
									<strong>{r.credits} credit(s)</strong>!
								</p>
							) : (
								<p>
									You rated {r.target} sessions this year and
									earned{" "}
									<strong>{r.credits} credit(s)</strong>!
								</p>
							)}
						</>
					) : (
						<>
							{/* achievement not earned */}
							{isFr ? (
								<p>
									Évalué {r.target} sessions cette année et a
									gagné
									<strong>${r.credits} crédits</strong>!
								</p>
							) : (
								<p>
									Rate {r.target} sessions this year and earn{" "}
									<strong>{r.credits} credit(s)</strong>!
								</p>
							)}
						</>
					)}
				</AchievementCard>
			))}

			{/* REVIEW SESSIONS */}
			{reviewedBadges.map((r) => (
				<AchievementCard
					title={t("dashboard-page.badge.reviewed-sessions", {
						sessions: r.target,
					})}
					badge={r.badge}
					key={nanoid(10)}
					value={reviewedSessions}
					target={r.target}
					achieved={r.earned}
				>
					{r.earned ? (
						<>
							{/* achievement earned */}
							{isFr ? (
								<p>
									You reviewed {r.target} sessions this year
									and earned{" "}
									<strong>{r.credits} credit(s)</strong>!
								</p>
							) : (
								<p>
									You reviewed {r.target} sessions this year
									and earned{" "}
									<strong>{r.credits} credit(s)</strong>!
								</p>
							)}
						</>
					) : (
						<>
							{/* achievement not earned */}
							{isFr ? (
								<p>
									Passez en revue {r.target} sessions cette
									année et a obtenu{" "}
									<strong>{r.credits} crédits</strong>!
								</p>
							) : (
								<p>
									Review {r.target} sessions this year and
									earn <strong>{r.credits} credit(s)</strong>!
								</p>
							)}
						</>
					)}
				</AchievementCard>
			))}

			{/* UNIQUE PROVIDER */}
			{uniqueProvidersBadges.map((r) => (
				<AchievementCard
					title={t("dashboard-page.badge.unique-providers", {
						providers: r.target,
					})}
					badge={r.badge}
					key={nanoid(10)}
					value={uniqueProviders}
					target={r.target}
					achieved={r.earned}
				>
					{r.earned ? (
						<>
							{/* achievement earned */}
							{isFr ? (
								<p>
									You booked sessions from {r.target} unique
									providers this year and earned{" "}
									<strong>{r.credits} credits</strong>!
								</p>
							) : (
								<p>
									You booked sessions from {r.target} unique
									providers this year and earned{" "}
									<strong>{r.credits} credits</strong>!
								</p>
							)}
						</>
					) : (
						<>
							{/* achievement not earned */}
							{isFr ? (
								<p>
									Réservez des sessions de {r.target}{" "}
									fournisseurs uniques cette année et
									gagnez&nbsp;
									<strong>{r.credits} crédits</strong>!
								</p>
							) : (
								<p>
									Book sessions from {r.target} unique
									providers this year and earn&nbsp;
									<strong>{r.credits} credits</strong>!
								</p>
							)}
						</>
					)}
				</AchievementCard>
			))}
		</Carousel>
	);
}

function useAchivements() {
	const { userInfo, appLocale } = useSelector((s) => s.appInfo);

	/** @type {number} */
	const ratedSessions = userInfo["Rated Sessions TY"];
	const ratedBadges = achievedBadges(ratedSessions, Achievements.ratings);

	/** @type {number} */
	const reviewedSessions = userInfo["Reviewed Sessions TY"];
	const reviewedBadges = achievedBadges(
		reviewedSessions,
		Achievements.reviews,
	);

	/** @type {number} */
	const uniqueProviders = userInfo["Unique Providers TY"];
	const uniqueProvidersBadges = achievedBadges(
		uniqueProviders,
		Achievements.uniqueProviders,
	);

	// settings updated
	/** @type {boolean} */
	const settingsUpdated = JSON.parse(
		userInfo["Settings Updated TY"] ?? "false",
	);

	return {
		ratedBadges,
		ratedSessions,
		reviewedSessions,
		reviewedBadges,
		uniqueProviders,
		uniqueProvidersBadges,
		appLocale,
		settingsUpdated,
	};
}

/**
 * @typedef {Object} EarnedAchievement
 * @property {boolean} earned
 * @property {number} target
 * @property {string} badge
 *
 *
 * @param {number} value
 * @param {import("../assets/achievement").Achievement[]} achievements
 * @returns {EarnedAchievement[]}
 */
function achievedBadges(value, achievements) {
	const last = achievements.length - 1;
	let i = last;
	while (true) {
		if (value >= achievements[i].target || i === 0) {
			break;
		}
		i--;
	}

	/** @type {EarnedAchievement[]} */
	const buf = [];
	// first badge
	buf.push({
		earned: value >= achievements[i].target,
		target: achievements[i].target,
		credits: achievements[i].credits,
		badge: achievements[i].badge,
	});

	// check to see:
	// if the first user has earned the first achievement or
	// if the achievement badge is the last one
	if (!buf[0].earned || i === last) {
		return buf;
	}

	i++;
	buf.push({
		earned: value >= achievements[i].target,
		target: achievements[i].target,
		credits: achievements[i].credits,
		badge: achievements[i].badge,
	});

	return buf;
}

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {string} props.badge
 * @param {boolean} props.achieved
 * @param {number} props.value
 * @param {number} props.target
 * @param {string} props.title
 */
function AchievementCard({ badge, children, value, title, achieved, target }) {
	const { appLocale } = useSelector((s) => s.appInfo);
	const completion = parseInt((value / target) * 100);
	return (
		<Card className={s.badge} data-achieved={achieved ? "ok" : ""}>
			<div className={s.badgeHeader}>
				<img src={badge} alt="" />
				<h3>{title}</h3>
			</div>

			{children}

			{!achieved ? (
				<div className={s.badgeProgress}>
					{appLocale === "fr"
						? `Terminé à ${completion}%`
						: `${completion}% Completed`}
				</div>
			) : (
				<></>
			)}
		</Card>
	);
}
