import supabase from "../../../database";
import { transformResponseValues } from "../util/transform-response-values";

const createInstance = (responseId, fields, instanceNumber) => {
	return {
		instance_number: instanceNumber,
		fields: fields.map((field) => ({
			response_id: responseId,
			field_id: field.id,
			value: "",
			instance_number: instanceNumber,
			completed: field.required ? false : true,
		})),
	};
};

export const createInitialValues = async (
	responseId,
	fields,
	previousValues,
) => {
	const groupedFields = fields.reduce((acc, field) => {
		const groupIndex = acc.findIndex(
			(group) => group.group_id === field.group_id,
		);
		if (groupIndex === -1) {
			acc.push({ group_id: field.group_id, fields: [field] });
		} else {
			acc[groupIndex].fields.push(field);
		}
		return acc;
	}, []);

	const updatedValues = groupedFields.map((group) => {
		const groupValues = previousValues.filter((value) =>
			group.fields.some(
				(field) => field.id === value.field_id && field.auto_populate === true,
			),
		);
		const instanceNumbers = [
			...new Set(groupValues.map((value) => value.instance_number)),
		];

		if (instanceNumbers.length === 0) {
			instanceNumbers.push(1);
		}

		const instances = instanceNumbers.map((instanceNumber) => {
			const instance = createInstance(responseId, group.fields, instanceNumber);
			groupValues.forEach((value) => {
				instance.fields.forEach((field) => {
					if (
						value.field_id === field.field_id &&
						value.instance_number === field.instance_number
					) {
						field.value = value.value;
						field.completed = value.completed;
					}
				});
			});
			return instance;
		});

		return instances;
	});

	const newValues = updatedValues
		.flat()
		.map((instance) => instance.fields)
		.flat();

	try {
		const response =
			await supabase.formResponseValues.createMultiple(newValues);
		return response.map(transformResponseValues) || [];
	} catch (error) {
		console.error("Error:", error);
		return [];
	}
};
