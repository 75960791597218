import React, { useMemo } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

// Utility function to process data
const stripEmptyData = (rawLabels, rawData, rawSecondaryData, rawTertiaryData) => {
  const labels = [];
  const data = [];
  const secondaryData = [];
  const tertiaryData = [];

  if (!rawLabels || !rawData) {
    return { labels, data, secondaryData, tertiaryData };
  }

  rawLabels.forEach((label, index) => {
    const primaryValue = rawData[index] || 0;
    const secondaryValue = rawSecondaryData?.[index] || 0;
    const tertiaryValue = rawTertiaryData?.[index] || 0;

    if (primaryValue !== 0 || secondaryValue !== 0 || tertiaryValue !== 0) {
      labels.push(label);
      data.push(primaryValue);
      secondaryData.push(secondaryValue);
      tertiaryData.push(tertiaryValue);
    }
  });

  return { labels, data, secondaryData, tertiaryData };
};

const ReportsArea = ({
  label: rawLabel,
  primaryData: rawPrimaryData,
  secondaryData: rawSecondaryData,
  tertiaryData: rawTertiaryData,
  primaryDataLabel,
  secondaryDataLabel,
  tertiaryDataLabel,
  displayLegend = true,
  title = "",
}) => {
  // Process data using memoization
  const { labels, data, secondaryData, tertiaryData } = useMemo(
    () => stripEmptyData(rawLabel, rawPrimaryData, rawSecondaryData, rawTertiaryData),
    [rawLabel, rawPrimaryData, rawSecondaryData, rawTertiaryData]
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: true,
          color: "rgba(0, 0, 0, 0.1)",
          drawTicks: false
        },
        ticks: {
          font: { weight: "400", size: 14, family: "Effra, sans-serif" },
          color: "#0D1120",
        },
        stacked: true
      },
      x: {
        grid: {
          display: false
        },
        ticks: {
          font: { weight: "400", size: 14, family: "Effra, sans-serif" },
          color: "#0D1120",
        }
      }
    },
    plugins: {
      legend: {
        display: displayLegend,
        position: "bottom",
        align: "start",
        labels: {
          font: { weight: "400", size: 14, family: "Effra, sans-serif" },
          color: "#0D1120",
          usePointStyle: true,
          pointStyle: "circle",
          boxWidth: 5,
          boxHeight: 5,
        },
      },
      title: {
        display: Boolean(title),
        text: title,
        position: "left",
        font: { weight: "500", size: 14, family: "Effra, sans-serif" },
        color: "#0D1120",
      },
    },
  };

  const chartData = {
    labels,
    datasets: [
      data.length > 0 && {
        label: primaryDataLabel || "Dataset 1",
        data: data,
        backgroundColor: "rgba(99, 171, 253, 0.2)",
        borderColor: "#63ABFD",
        pointBackgroundColor: "#63ABFD",
        borderWidth: 2,
        tension: 0.4,
        fill: true,
      },
      secondaryData.length > 0 && {
        label: secondaryDataLabel || "Dataset 2",
        data: secondaryData,
        backgroundColor: "rgba(230, 151, 255, 0.2)",
        borderColor: "#E697FF",
        pointBackgroundColor: "#E697FF",
        borderWidth: 2,
        tension: 0.4,
        fill: true,
      },
      tertiaryData.length > 0 && {
        label: tertiaryDataLabel || "Dataset 3",
        data: tertiaryData,
        backgroundColor: "rgba(255, 185, 71, 0.2)",
        borderColor: "#FFB947",
        pointBackgroundColor: "#FFB947",
        borderWidth: 2,
        tension: 0.4,
        fill: true,
      }
    ].filter(Boolean)
  };

  return (
    <div style={{ height: "400px", width: "100%" }}>
      <Line options={options} data={chartData} />
    </div>
  );
};

export default ReportsArea;
