import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getPluralString } from "../../utils/array";
import ResourceRequestItem from "./components/ResourceRequestItem";
import noResultImg from "../../assets/img/no-result.png";
import useResource from "./hooks/use-resource";
import { hideLoading, showLoading } from "../../utils/loading";
import { confirmAlert } from "react-confirm-alert";

const Screen = () => {
  const { resourceCart } = useSelector(state => state.appInfo);
  const [cartData, setCartData] = useState([]);
  const {
    createResourceOrderByMultipleRequests,
    removeRequest
  } = useResource();

  useEffect(() => {
    window.document.title = `My Cart - School Resources - Connected North`;

    return () => window.document.title = "Connected North";
  }, [])

  useEffect(() => {
    setCartData([...resourceCart]);
  }, [resourceCart])

  const updateCartItem = (no, value) => {
    const carts = [...resourceCart];
    carts[no]["Quantity"] = value;
    setCartData(carts);
  }

  const completeOrder = () => {
    confirmAlert({
      title: 'Connected North',
      message: "Are you sure you want to complete and request your order? Your Principal will be notified to review and approve it.",
      buttons: [{
        label: `Confirm`,
        onClick: async () => {
          showLoading("Completing");
          await createResourceOrderByMultipleRequests(cartData);
          hideLoading();
        }
      },
      {
        label: "Cancel",
        onClick: () => { }
      }]
    });
  }

  const renderCartContent = () => {
    if (!cartData || cartData.length === 0) {
      return (
        <div className="no-result">
          <img src={noResultImg} alt="No Result" />
          <h1><b>No Cart</b></h1>
        </div>
      )
    }

    return (
      <div className="resource-request-list">
        {cartData.map((request, i) => (
          <ResourceRequestItem
            key={i}
            data={request}
            edit={true}
            updateQuantity={(value) => updateCartItem(i, value)}
            remove={() => {
              confirmAlert({
                title: "Connected North",
                message: "Are you sure you want to remove this item from your cart?",
                buttons: [
                  {
                    label: "OK",
                    onClick: async () => {
                      showLoading();
                      await removeRequest(request.id)
                      hideLoading();
                    },
                  },
                  {
                    label: "Cancel",
                    onClick: () => { },
                  },
                ],
              });
            }}
          />
        ))}
        <div className="actions">
          <button
            className="btn btn-primary btn-sm"
            onClick={() => completeOrder()}
          >
            <i className="fa fa-check" />{` Complete & Request Order`}
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className="main-container cart-resources">
      <h1>My Cart</h1>
      {(!!cartData && cartData.length > 0) && (
        <p>You have {getPluralString(cartData.length, "Resource")} in your cart. Once you complete & request your order, your Principal will be notified to approve your order before it is shipped.</p>
      )}
      {renderCartContent()}
    </div>
  )
}

export default Screen;