import { useState, useEffect } from "react";
import airtable from "../../../airtables";

export const useUserName = (userId) => {
	const [userName, setUserName] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchUserName = async () => {
			if (!userId) {
				setUserName("");
				return;
			}

			try {
				setLoading(true);
				const record = await airtable.instance.table("Teachers").find(userId);

				if (record) {
					setUserName(record.fields["Teacher Name"]);
				} else {
					setUserName("Unknown User");
				}
			} catch (err) {
				console.error("Error fetching user name:", err);
				setError(err.message);
				setUserName("Unknown User");
			} finally {
				setLoading(false);
			}
		};

		fetchUserName();
	}, [userId]);

	return { userName, loading, error };
};
