import React, { useState, useEffect } from "react";
import { Box, Select, MenuItem } from "@mui/material";
import InputWrapper from "./InputWrapper";

const SingleSelectInput = ({
	id,
	value = "",
	selectedValue,
	onChange = () => {},
	error = false,
	options = [],
	disabled,
	sx = {},
	heading,
	description,
	helperText,
}) => {
	const [inputValue, setInputValue] = useState(selectedValue || value);
	const [inputError, setInputError] = useState(error);
	const filledInput = inputValue.length > 0;

	useEffect(() => {
		setInputValue(selectedValue || value);
	}, [selectedValue, value]);

	const handleInputChange = (e) => {
		const { value } = e.target;

		setInputValue(value);
		setInputError(false);

		onChange(value);
	};

	return (
		<InputWrapper
			title={heading}
			description={description}
			sx={{
				maxWidth: "32rem",
				...sx,
			}}
			id={id}
			inputError={inputError}
			filledInput={filledInput}
			helperText={helperText}
		>
			<Box>
				<Select
					id={id}
					name={id}
					aria-describedby="helper-text"
					error={inputError}
					onChange={(e) => handleInputChange(e)}
					value={inputValue}
					disabled={disabled}
					fullWidth
				>
					{options.map(({ value, label }) => {
						return (
							<MenuItem key={value} value={value}>
								{label}
							</MenuItem>
						);
					})}
				</Select>
			</Box>
		</InputWrapper>
	);
};

export default SingleSelectInput;
