import SupabaseTable from "../SupabaseTable";

const bucket = "form_uploads";

export class FormResponseValuesTable extends SupabaseTable {
	deleteInstance = async (responseId, ids, instance_number) => {
		try {
			const { error } = await this.supabase
				.from(this.tableName)
				.delete()
				.in("id", ids)
				.eq("response_id", responseId)
				.eq("instance_number", instance_number);
			if (error) {
				throw new Error(error.message);
			}
		} catch (error) {
			throw error;
		}
	};
	deleteMultiple = async (ids) => {
		try {
			const { error } = await this.supabase
				.from(this.tableName)
				.delete()
				.in("id", ids);
			if (error) {
				throw new Error(error.message);
			}
		} catch (error) {
			throw error;
		}
	};
	listByResponseAndFields = async (responseId, fieldIds) => {
		try {
			const { data, error } = await this.supabase
				.from(this.tableName)
				.select("*")
				.eq("response_id", responseId)
				.in("field_id", fieldIds);

			if (error) {
				throw new Error(error.message);
			}

			return data;
		} catch (error) {
			throw error;
		}
	};

	listByResponse = async (responseId) => {
		try {
			const { data, error } = await this.supabase
				.from(this.tableName)
				.select("*")
				.eq("response_id", responseId);

			if (error) {
				throw new Error(error.message);
			}

			return data;
		} catch (error) {
			throw error;
		}
	};
	uploadFile = async (filePath, file) => {
		try {
			const { data, error } = await this.supabase.storage
				.from(bucket)
				.upload(filePath, file);

			if (error) {
				throw new Error(error.message);
			}

			return data;
		} catch (error) {
			throw error;
		}
	};
	removeFile = async (filePath) => {
		try {
			const { data, error } = await this.supabase.storage
				.from(bucket)
				.remove([filePath]);

			if (error) {
				throw new Error(error.message);
			}

			return data;
		} catch (error) {
			throw error;
		}
	};

	selectByFieldId = async (fieldId) => {
		try {
			const { data, error } = await this.supabase
				.from(this.tableName)
				.select("*")
				.eq("field_id", fieldId);

			if (error) {
				throw new Error(error.message);
			}

			return data;
		} catch (error) {
			throw error;
		}
	};

	selectByFieldIdBySchoolId = async (fieldId, formId) => {
    try {
      const { data, error } = await this.supabase
        .from("form_response_values")
        .select(`
          *,
          form_responses(*)
        `)
        .eq("field_id", fieldId)
        .eq("response_id", formId)
        .eq("form_responses.id", formId);
  
      if (error) {
        throw new Error(error.message);
      }
  
      return data;
    } catch (error) {
      throw error;
    }
  };
}
