import React, { useState, useEffect } from "react";
import { Box, Select, MenuItem } from "@mui/material";
import Chip from "@mui/material/Chip";
import InputWrapper from "./InputWrapper";

const MultiSelectInput = ({
	id,
	value = [],
	onChange = () => {},
	error = false,
	options = [],
	disabled,
	sx = {},
	heading,
	description,
	helperText,
}) => {
	const [selectOptions, setSelectOptions] = useState([]);

	const [inputValue, setInputValue] = useState(value);

	const [inputError, setInputError] = useState(error);
	const filledInput = inputValue.length > 0;

	useEffect(() => {
		const fetchFilteredTeachersData = async () => {
			let teacherOptions;

			switch (options) {
				default:
					teacherOptions = options;
					setSelectOptions(teacherOptions);
					return;
			}
		};

		fetchFilteredTeachersData();
	}, [options]);

	const handleInputChange = (event) => {
		const value = event.target.value;
		setInputValue(value);
		setInputError(false);
		onChange(value);
	};

	return (
		<InputWrapper
			title={heading}
			description={description}
			sx={{
				maxWidth: "32rem",
				...sx,
			}}
			id={id}
			inputError={inputError}
			filledInput={filledInput}
			helperText={helperText}
		>
			<Box>
				<Select
					id={id}
					name={id}
					aria-describedby="helper-text"
					error={inputError}
					onChange={handleInputChange}
					value={inputValue}
					disabled={disabled}
					fullWidth
					displayEmpty
					multiple
					renderValue={(selected) => (
						<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
							{Array.isArray(selected) &&
								selected.map((value) => {
									const filterSelectedLabel = selectOptions.filter(
										(option) => option.value === value,
									);
									return filterSelectedLabel[0] ? (
										<Chip key={value} label={filterSelectedLabel[0].label} />
									) : null;
								})}
						</Box>
					)}
				>
					{selectOptions.map(({ value, label }) => (
						<MenuItem key={value} value={value}>
							{label}
						</MenuItem>
					))}
				</Select>
			</Box>
		</InputWrapper>
	);
};

export default MultiSelectInput;
