import supabase from "../../../database";

export const removeFile = async (file, fieldId) => {
	try {
		const filePath = `${fieldId}/${file.name}`;

		const response = await supabase.formResponseValues.removeFile(filePath);

		if (response) {
			return response;
		}
		return null;
	} catch (e) {
		console.log("Error:", e);
		return null;
	}
};
