import AirtableTable from "../../AirtableTable";

export class SchoolCalendarTable extends AirtableTable {
  constructor(instance) {
    super(instance, "School Calendar");

    this.fields = {
      "ID": "fldUW34JPI2IXlr5D",
      "School": "fldhrVKdYOtGrqETj",
      "Status": "fldWvt5eaUTGwSHop",
      "Approval Motion": "fldVQjfZTX2lCRxF9",
      "Year": "fldoOWfL8IAtUSVHs",
      "First Day (Principal)": "fldzll1IpyuKF3mHQ",
      "First Day (Teachers)": "fldUG1gHZa9Eg7mEQ",
      "First Day (Students)": "fld8jeGqA2IrpPMJ3",
      "Last Day (Students)": "fldc65Qvw7ul7kPXz",
      "Last Day (Teachers)": "fldmC4Sbsp7cFFVMl",
      "Last Day (Principal)": "fldS03iEuijxTC3v6",
      "Calendar Days": "fldoDZh4yWMuEnexM",
      "O Days": "fldwEqjVN85qYMrBD",
      "D Days": "fldhTphrfxVFxAY9h",
      "School Schedule": "fldmqz6sSoaiLq4iK",
      "Custom Calendar Motion": "fld2yrt6X8iO2pC2R"
    };
  }

  async getRecordsFromLinkedTable(linkedTable, recordIds) {
    const batches = [];
    for (let i = 0; i < recordIds.length; i += 100) {
      batches.push(recordIds.slice(i, i + 100));
    }

    const results = await Promise.all(
      batches.map(async (batch) => {
        const filter = batch.map((id) => `RECORD_ID() = '${id}'`).join(",");
        const formula = `OR(${filter})`;

        const records = await this.instance(linkedTable)
          .select({
            filterByFormula: formula,
          })
          .firstPage();

        return records.map((record) => ({
          id: record.id,
          ...record.fields,
        }));
      })
    );

    return results.flat();
  }

  async getSchoolCalendarByYear(school, year) {
    return new Promise((resolve, reject) => {
      this.selectOneByCondition({
        filterByFormula: `AND({School} = '${school}', {Year} = '${year}')`,
      })
        .then(async (calendarRecord) => {
          if (!calendarRecord) {
            resolve(null);
            return;
          }

          // Process the "School Schedule" field
          const scheduleIds = calendarRecord["School Schedule"];
          if (scheduleIds && scheduleIds.length > 0) {
            calendarRecord["School Schedule"] = await this.getRecordsFromLinkedTable(
              "School Schedule",
              scheduleIds
            );
          }

          // Process the "Calendar Days" field
          const dayIds = calendarRecord["Calendar Days"];
          if (dayIds && dayIds.length > 0) {
            calendarRecord["Calendar Days"] = await this.getRecordsFromLinkedTable(
              "Calendar Days",
              dayIds
            );
          }

          resolve(calendarRecord);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }


}
