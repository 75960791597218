export const qikiqtani_options = [
	{
		id: "option-1",
		header: "Option 1: Early August",
		years: {
			"2024-25": {
				start_days: {
					leader: "2024-08-02",
					staff: "2024-08-12",
					student: "2024-08-15",
				},
				end_days: {
					leader: "2025-06-10",
					staff: "2025-06-06",
					student: "2025-06-05",
				},
			},
			"2025-26": {
				start_days: {
					leader: "2025-08-06",
					staff: "2025-08-13",
					student: "2025-08-18",
				},
				end_days: {
					leader: "2026-06-09",
					staff: "2026-06-05",
					student: "2026-06-04",
				},
			},
			"2026-27": {
				start_days: {
					leader: "2026-08-13",
					staff: "2026-08-20",
					student: "2026-08-25",
				},
				end_days: {
					leader: "2027-06-16",
					staff: "2027-06-14",
					student: "2027-06-11",
				},
			},
		},
	},
	{
		id: "option-2",
		header: "Option 2: Mid August",
		years: {
			"2024-25": {
				start_days: {
					leader: "2024-08-12",
					staff: "2024-08-19",
					student: "2024-08-22",
				},
				end_days: {
					leader: "2025-06-17",
					staff: "2025-06-13",
					student: "2025-06-12",
				},
			},
			"2025-26": {
				start_days: {
					leader: "2025-08-13",
					staff: "2025-08-20",
					student: "2025-08-25",
				},
				end_days: {
					leader: "2026-06-19",
					staff: "2026-06-17",
					student: "2026-06-16",
				},
			},
			"2026-27": {
				start_days: {
					leader: "2026-08-20",
					staff: "2026-08-27",
					student: "2026-09-01",
				},
				end_days: {
					leader: "2027-06-23",
					staff: "2027-06-21",
					student: "2027-06-18",
				},
			},
		},
	},
	{
		id: "option-3",
		header: "Option 3: Late August",
		years: {
			"2024-25": {
				start_days: {
					leader: "2024-08-21",
					staff: "2024-08-28",
					student: "2024-09-03",
				},
				end_days: {
					leader: "2025-06-25",
					staff: "2025-06-23",
					student: "2025-06-20",
				},
			},
			"2025-26": {
				start_days: {
					leader: "2025-08-20",
					staff: "2025-08-27",
					student: "2025-09-02",
				},
				end_days: {
					leader: "2026-06-24",
					staff: "2026-06-22",
					student: "2026-06-19",
				},
			},
			"2026-27": {
				start_days: {
					leader: "2026-08-26",
					staff: "2026-09-02",
					student: "2026-09-08",
				},
				end_days: {
					leader: "2027-07-05",
					staff: "2027-06-30",
					student: "2027-06-29",
				},
			},
		},
	},
];
