import supabase from "../../../database";

export const deleteValues = async (ids) => {
	try {
		const response = await supabase.formResponseValues.deleteMultiple(ids);

		if (response.error) throw response.error;
	} catch (e) {
		console.log("Error:", e);
	}
};
