import React from "react";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	AccordionActions,
	Button,
	styled,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import { confirmAlert } from "react-confirm-alert";

const ButtonText = styled("span")({
	textAlign: "center",
	fontFamily: "Effra, sans-serif",
	fontSize: "1rem",
	textTransform: "none",
	color: "#000",
	paddingLeft: "0.3rem",
});

export const RepeatableAccordion = ({
	header,
	children,
	id,
	disableDelete = "false",
	onDeleteEvent = () => {},
	expanded = false,
	onChange = () => {},
}) => {
	const { t } = useTranslation();

	const handleDelete = () => {
		confirmAlert({
			title: t("delete_confirmation.title"),
			message: t("delete_confirmation.message"),
			buttons: [
				{
					label: t("delete_confirmation.confirm"),
					onClick: () => onDeleteEvent(),
				},
				{
					label: t("delete_confirmation.cancel"),
					onClick: () => {},
				},
			],
		});
	};

	return (
		<Accordion
			expanded={expanded}
			onChange={onChange}
			sx={{
				border: "1px solid #96ddf8",
				backgroundColor: "#f3f9ff",
				boxShadow: "0.625rem 0.75rem 1.5rem 0rem rgba(206, 222, 238, 0.47)",
				"&.Mui-focused, & .MuiAccordionSummary-root:focus-visible": {
					outline: "2px solid #1976d2",
					borderRadius: "0.625rem",
				},
				"&.MuiAccordion-root": {
					borderRadius: "0.625rem",
				},

				mb: "1.25rem",
			}}
		>
			<AccordionSummary
				expandIcon={
					<ExpandMoreIcon
						sx={{
							color: "#2b4f70",
							fontSize: "1.875rem",
						}}
					/>
				}
				aria-controls={`${header}-content`}
				id={id}
				sx={{
					fontFamily: "Effra, sans-serif",
					fontSize: "1.25rem",
					fontWeight: "700",
					color: "#0D1120",
				}}
			>
				{header}
			</AccordionSummary>
			<AccordionDetails>{children}</AccordionDetails>
			<AccordionActions
				sx={{
					position: "relative",
					marginTop: "1rem",
				}}
			>
				<Button
					disabled={disableDelete}
					onClick={handleDelete}
					sx={{
						padding: "0.5rem 1.1rem",
						borderLeft: "1px solid #b0e4f7b3",
						borderTop: "1px solid #b0e4f7b3",
						borderRadius: "0.25rem 0 0.625rem 0",
						backgroundColor: "#b0e4f7b3",
						position: "absolute",
						bottom: "0",
						right: "0",
						color: "#000",
					}}
				>
					<DeleteIcon />
					<ButtonText>{t("form_buttons.delete")}</ButtonText>
				</Button>
			</AccordionActions>
		</Accordion>
	);
};
