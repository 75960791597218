import { NUNAVUT_CLUSTER } from "../../PDPlan/constants/pd-fields";
const TEST_MODE = process.env.REACT_APP_TEST_MODE === "TRUE";
const nunavutClusterId = NUNAVUT_CLUSTER;
const allowedUserIds = [
	"rec6rkdIxhr0Nrg3E",
	"recAv3fj6U3M9rsRn",
	"rec1GeXCvr6qA14xI",
	"recCxn5WKxWf7LdWV",
	"receJBAixwffiSUgQ",
	"recNPFMTS05QTZIko",
];

export const checkNunavutTeacher = (userInfo) => {
	if (!userInfo) return false;

	if (allowedUserIds.includes(userInfo["Record ID"])) {
		return true;
	}
	if (TEST_MODE) {
		return true;
	}
	if (userInfo["Cluster"] && userInfo["Cluster"].length) {
		return userInfo["Cluster"].includes(nunavutClusterId);
	}
	return false;
};

export const checkNunavutPrincipal = (userInfo) => {
	if (!userInfo) return false;

	if (allowedUserIds.includes(userInfo["Record ID"])) {
		return true;
	}

	if (TEST_MODE) {
		return true;
	}
	if (
		userInfo["School Leader?"] === "true" &&
		userInfo["Cluster"] &&
		userInfo["Cluster"].length
	) {
		return userInfo["Cluster"].includes(nunavutClusterId);
	}

	return false;
};
