import React, { useState } from "react";
import { Button, Input, InputGroup, InputGroupText, Modal } from "reactstrap";
import { toast } from "react-toastify";
import Loading from "../../../components/Loading";
import "./styles.scss"
import useResource from "../../SchoolResources/hooks/use-resource";

const RequestResourceModal = ({
  resource,
  toggle
}) => {
  const [processing, setProcessing] = useState(false);
  const [amount, setAmount] = useState(0);
  const { createResourceRequest, createResourceOrder } = useResource(resource.id);

  const addToCart = async () => {
    if (!amount) {
      toast.error("Please enter the amount of resources you want to request.");
      return;
    }

    setProcessing(true);
    await createResourceRequest(amount);
    setProcessing(false);
    toast.success("Resource added to cart successfully.");
    toggle();
  }

  const addAndCheckout = async () => {
    if (!amount) {
      toast.error("Please enter the amount of resources you want to request.");
      return;
    }

    setProcessing(true);
    await createResourceOrder(amount);
    setProcessing(false);
    toast.success("Resource was ordered successfully.");
    toggle();
  }

  const info = [];
  if (resource["Type"]) info.push(resource["Type"]);
  if (resource["Grade Level"]) info.push(resource["Grade Level"]);
  if (resource["Language"]) info.push(resource["Language"]);

  return (
    <Modal
      isOpen={true}
      className="modal-dialog-centered resource-request-modal"
      toggle={toggle}
      size="md"
    >
      <div className="modal-header">
        <h3 className="modal-title">
          {resource["Title"]}
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={toggle}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="resource-request-content">
          <span className="resource-author">By {resource["Author"]}</span>
          <span className="resource-info">
            {info.map((item, index) => (
              <span key={index}>{item}</span>  
            ))}
          </span>
          <InputGroup className="resource-request-amount">
            <InputGroupText>Amount:</InputGroupText>
            <Input
              type="number"
              min={1}
              value={amount}
              onChange={e => setAmount(parseInt(e.target.value))}
            />
          </InputGroup>
        </div>
      </div>
      <div className="modal-footer justify-content-center">
        <Button
          color="primary"
          type="button"
          onClick={() => addToCart()}
        ><i className="fa fa-plus" />&nbsp;Add to Cart</Button>
        <Button
          color="secondary"
          type="button"
          onClick={() => addAndCheckout()}
        ><i className="fa fa-arrow-right" />&nbsp;Add and Checkout</Button>
        <Button
          data-dismiss="modal"
          color="outline-primary"
          type="button"
          onClick={() => toggle()}
        >Cancel</Button>
      </div>
      {processing && (
        <div className="modal-loading">
          <Loading size={32} />
        </div>
      )}
    </Modal>
  )
}

export default RequestResourceModal;
