import React from "react";
import { Box, FormHelperText, Typography, FormControl } from "@mui/material";

const InputWrapper = ({
	children,
	helperText,
	title,
	description,
	sx,
	id,
	inputError,
	filledInput,
}) => {
	return (
		<FormControl
			sx={{
				width: "100%",
				marginBottom: "1.88rem",
				"& .MuiOutlinedInput-root": {
					backgroundColor: filledInput ? "#F3F9FF" : "#fff",
					"& fieldset": {
						borderRadius: "0.38rem",
						border: "1px solid #0E6BA8",
					},
					"&.Mui-focused": {
						backgroundColor: "#fff",
					},
					"&.Mui-focused fieldset": {
						boxShadow: "10px 12px 24px 0px rgba(206, 222, 238, 0.47)",
						borderColor: "rgb(25, 118, 210)",
					},
					"&.Mui-error:not(.Mui-focused)": {
						"& fieldset": {
							border: "1px solid #EB5757",
							backgroundColor: "rgba(235, 87, 87, .1)",
							boxShadow: "10px 12px 24px 0px rgba(206, 222, 238, 0.47)",
						},
					},
				},
				"& .base-NumberInput-root": {
					backgroundColor: filledInput ? "#F3F9FF" : "#fff",
				},
				...sx,
			}}
		>
			{title && (
				<Box mb="0.5rem">
					<Typography
						variant="h4"
						component="label"
						htmlFor={id}
						fontFamily="Effra, sans-serif"
						fontSize="1.125rem"
						fontWeight="700"
						color="#000"
					>
						{title}
					</Typography>
				</Box>
			)}
			{description && (
				<Box mb="0.5rem">
					<Typography
						variant="body1"
						fontFamily="Effra, sans-serif"
						fontSize="1rem"
						fontWeight="400"
						color="#000"
					>
						{description}
					</Typography>
				</Box>
			)}
			{children}

			{helperText && inputError && (
				<FormHelperText
					id="helper-text"
					sx={{
						margin: "0",
						paddingTop: "10px",
					}}
				>
					<Typography variant="body2" color="#CE2222" component="span">
						{helperText}
					</Typography>
				</FormHelperText>
			)}
		</FormControl>
	);
};

export default InputWrapper;
