import React, { useEffect, useState } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Modal
} from 'reactstrap';
import Loading from '../../../components/Loading';
import airtable from '../../../airtables';
import { DAY_LIMITATIONS_MAP } from '../../../utils/constants';
import { isValidDateOnPicker } from '../../../utils/time';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

const SessionReject = ({ sessionId, onToggle, onPost }) => {
  const [submitting, setSubmitting] = useState(false);
  const [comment, setComment] = useState("");
  const [startDate, setStartDate] = useState();
  const [alterDate, setAlterDate] = useState();
  const [preferredTimeValid, setPreferredTimeValid] = useState([]);
  const [alternativeTimeValid, setAlternativeTimeValid] = useState([]);
  const [commentValid, setCommentValid] = useState();
  const [dayLimitations, setDayLimitations] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    initialize();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const initialize = async () => {
    const sess = await airtable.sessions.select(sessionId);
    const providerInfo = await airtable.providers.select(sess["Provider"][0]);
    if (providerInfo["Day Limitations"]) {
      const dl = providerInfo["Day Limitations"].map(l => DAY_LIMITATIONS_MAP[l]);
      setDayLimitations(dl)
    } else {
      setDayLimitations([]);
    }
  }

  const renderTimeValidation = (timeValid) => {
    const renderTimeValidationItem = (item) => {
      if (item === 'valid') {
        return (
          <span style={{ color: 'green', fontSize: 12 }}>This time appears to be available in the provider's calendar.</span>
        )
      }

      if (item === 'invalid') {
        return (
          <span style={{ color: 'red', fontSize: 12 }}>The provider's calendar appears busy at this time. We suggest selecting an alternate time to propose!</span>
        )
      }

      if (item === 'incorrect') {
        return (
          <span style={{ color: '#cda535', fontSize: 12 }}>Warning: This time seems to be very early or very late. Please ensure you have selected the correct AM or PM option!</span>
        )
      }

      if (item === 'past-time') {
        return (
          <span style={{ color: 'red', fontSize: 12 }}>Oops! You've selected a date that has already passed.</span>
        )
      }

      return null;
    }

    return (
      <>
        {
          timeValid.map((item, i) => <div key={i}>
            {renderTimeValidationItem(item)}
          </div>)
        }
      </>
    )
  }

  const onSubmit = async () => {
    if (submitting) return;
    if (commentValid === 'invalid') return;
    if (!startDate) return;
    if (!alterDate) return;

    if (preferredTimeValid.indexOf('invalid') >= 0) {
      alert("The Preferred Date & Time is invalid.");
      return;
    }

    setSubmitting(true);

    const recentSessionInfo = await airtable.sessions.select(sessionId);
    const schedulingAttempts = (recentSessionInfo["Scheduling Attempts"] || 0) + 1

    airtable.sessions.update(
      sessionId,
      {
        "Teacher Booking Response": `${comment}`,
        "Session Start Date/Time": `${startDate.toString()}`,
        "Alternative Date/Time": `${alterDate.toString()}`,
        "Status": "Pending Provider Response",
        "Notify Teacher of Provider Response": false,
        "Scheduling Attempts": schedulingAttempts
      }
    ).then(async (res) => {
      await fetch(`https://hooks.zapier.com/hooks/catch/89715/37q7as9?sessionID=${sessionId}&action=reject`, {
        method: "GET",
        mode: "no-cors",
      });
      onToggle();
      if (onPost) onPost();
    }).catch(error => {
      alert(error.toString());
    });
  }

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={true}
      toggle={onToggle}
      backdrop={false}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="modal-title-default">Propose Alternates (Neither time is available)</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            if (submitting) return;
            onToggle()
          }}
        ><span aria-hidden={true}>×</span></button>
      </div>
      <div className="modal-body">
        <label>Please provide details on future availability/ideal booking times for the provider:</label>
        <Form>
          <FormGroup>
            <Input
              type="textarea"
              style={{ minHeight: 100 }}
              onChange={(e) => {
                setComment(e.target.value)
                setCommentValid(!e.target.value ? 'invalid' : 'valid')
              }}
              invalid={commentValid === 'invalid'}
              disabled={submitting}
            />
          </FormGroup>
          <label>
            Can you propose two ideal dates and times for the provider to review?
          </label>
          <FormGroup>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label={t("sessionrequest.prefer-time")}
                shouldDisableDate={(currentDate) => !isValidDateOnPicker(dayLimitations, currentDate)}
                minutesStep={5}
                ampm
                value={startDate ? dayjs(startDate) : null}
                onChange={(e) => {
                  if (typeof e !== 'string') {
                    const valids = [];
                    const selectedDate = e.toDate();
                    const selectedHour = selectedDate.getHours();
                    if (selectedDate < new Date()) valids.push("past-time")
                    if (selectedHour < 8 || selectedHour >= 17) { valids.push("incorrect"); }
                    setPreferredTimeValid(valids)
                    setStartDate(selectedDate);
                  }
                }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: preferredTimeValid.indexOf('invalid') >= 0,
                    disabled: submitting,
                    className: "cn-datetime-picker",
                  },
                }}
              />
            </LocalizationProvider>
            {renderTimeValidation(preferredTimeValid)}
          </FormGroup>
          <FormGroup>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label={t("sessionrequest.alternative-time")}
                shouldDisableDate={(currentDate) => !isValidDateOnPicker(dayLimitations, currentDate)}
                minutesStep={5}
                ampm
                value={alterDate ? dayjs(alterDate) : null}
                onChange={(e) => {
                  if (typeof e !== 'string') {
                    const valids = [];
                    const selectedDate = e.toDate();
                    const selectedHour = selectedDate.getHours();
                    if (selectedDate < new Date()) valids.push("past-time")
                    if (selectedHour < 8 || selectedHour >= 17) { valids.push("incorrect"); }
                    setAlternativeTimeValid(valids);
                    setAlterDate(selectedDate);
                  }
                }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: alternativeTimeValid.indexOf('invalid') >= 0,
                    disabled: submitting,
                    className: "cn-datetime-picker",
                  }
                }}
              />
            </LocalizationProvider>
            {renderTimeValidation(alternativeTimeValid)}
          </FormGroup>
        </Form>
      </div>
      <div className="modal-footer">
        <Button
          className="ml-auto"
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={onToggle}
        >Close</Button>
        <Button
          color="success"
          type="button"
          onClick={() => onSubmit()}
          disabled={submitting}
        >{submitting && (<Loading size={14} />)}Submit Response</Button>
      </div>
    </Modal>
  )
}

export default SessionReject;
