import React, { Fragment, useState } from "react";
import { Pagination } from "../../../components/Pagination";
import ResourceItem from "../components/ResourceItem";
import noResultImg from "../../../assets/img/no-result.png";

const ResourceList = ({ resources, featuredLabel = "" }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const pagesize = 12;

  if (!resources || resources.length === 0) {
    return (
      <div className="no-result">
        <img src={noResultImg} alt="No Result" />
        <h1><b>No {featuredLabel} Resources</b></h1>
      </div>
    )
  }

  const pageCount = Math.ceil(resources.length / pagesize);
  const resourcesToShow = resources.slice(currentPage, (currentPage + 1) * pagesize);

  return (
    <Fragment>
      <div className="resource-list">
        {resourcesToShow.map((item, i) => (
          <ResourceItem data={item} key={i} />
        ))}
      </div>
      <div className="paginate pb-4">
        {resources.length > pagesize && (
          <Pagination
            page={currentPage}
            setPage={(p) => {
              setCurrentPage(p);
            }}
            pageCount={pageCount}
          />
        )}
      </div>
    </Fragment>
  )
}

export default ResourceList;