import supabase from "../../../database";
import { transformForm } from "../util/transform-form";

export const fetchActiveForm = async () => {
	try {
		const response = await supabase.forms.selectActive();
		if (!response) return null;
		return transformForm(response);
	} catch (e) {
		console.log("Error:", e);
		return null;
	}
};

export const fetchFormById = async (formId) => {
	try {
		const response = await supabase.forms.select(formId);
		if (!response) return null;
		return transformForm(response);
	} catch (e) {
		console.log("Error:", e);
		return null;
	}
};
