const filterCompleted = (dataArray) => {
	return Array.isArray(dataArray)
		? dataArray.filter((item) => item.completed === true)
		: [];
};
const gradeValue = (value) => {
	let index;
	switch (value) {
		case "0":
		case "a":
			index = 0;
			break; // Grade K
		case "1":
		case "b":
			index = 1;
			break; // Grade 1
		case "2":
		case "c":
			index = 2;
			break; // Grade 2
		case "3":
		case "d":
			index = 3;
			break; // Grade 3
		case "4":
		case "e":
			index = 4;
			break; // Grade 4
		case "5":
		case "f":
			index = 5;
			break; // Grade 5
		case "6":
		case "g":
			index = 6;
			break; // Grade 6
		case "7":
		case "h":
			index = 7;
			break; // Grade 7
		case "8":
		case "i":
			index = 8;
			break; // Grade 8
		case "9":
		case "j":
			index = 9;
			break; // Grade 9
		case "10":
		case "k":
			index = 10;
			break; // Grade 10
		case "11":
		case "l":
			index = 11;
			break; // Grade 11
		case "12":
		case "m":
			index = 12;
			break; // Grade 12
		default:
			break;
	}
	return index;
};
const transformClosureReasons = (closureReasons) => {
	if (!closureReasons || !Array.isArray(closureReasons)) {
		return { counts: new Array(8).fill(0), rawData: [] };
	}

	const completedReasons = filterCompleted(closureReasons);
	const counts = new Array(8).fill(0);

	completedReasons.forEach((reason) => {
		const value = reason.value.toLowerCase();

		let index;
		switch (value) {
			case "a":
				index = 0;
				break; // Weather
			case "b":
				index = 1;
				break; // Operational Requirement
			case "c":
				index = 2;
				break; // DEA Health/Safety
			case "d":
				index = 3;
				break; // Facility Issue
			case "e":
				index = 4;
				break; // Funeral
			case "f":
				index = 5;
				break; // Water Sewage Issue
			case "g":
				index = 6;
				break; // Staff Capacity
			default:
				index = 7;
				break; // Other
		}
		counts[index]++;
	});

	return {
		counts,
		rawData: completedReasons,
	};
};

const transformSuspensionData = (
	rawSuspensionGradeData,
	rawExpulsionData,
	rawStaffData,
	rawStudentData,
	rawHoursInData,
	rawHoursOutData,
) => {
	let suspensionGradeData = new Array(13).fill(0);
	let expulsionGradeData = new Array(13).fill(0);
	let suspensionViolenceStaffData = 0;
	let suspensionViolenceStudentsData = 0;
	let suspensionHoursInSchoolData = new Array(13).fill(0);
	let suspensionHoursOutOfSchoolData = new Array(13).fill(0);

	const suspensionData = rawSuspensionGradeData.map((suspension) => {
		const instance = suspension.instance_number;
		const suspensionGrade = suspension.value;
		const isExpulsion = !!rawExpulsionData.find(
			(expulsion) =>
				expulsion.instance_number === instance && expulsion.value === "a",
		);
		const isStaffViolence = !!rawStaffData.find(
			(staff) => staff.instance_number === instance && staff.value === "a",
		);
		const isStudentViolence = !!rawStudentData.find(
			(student) =>
				student.instance_number === instance && student.value === "a",
		);
		const hoursInSchoolData = rawHoursInData.find(
			(hoursIn) => hoursIn.instance_number === instance,
		)?.value;
		const hoursInSchool = isNaN(Number(hoursInSchoolData))
			? 0
			: Number(hoursInSchoolData);
		const hoursOutOfSchoolData = rawHoursOutData.find(
			(hoursOut) => hoursOut.instance_number === instance,
		)?.value;
		const hoursOutOfSchool = isNaN(Number(hoursOutOfSchoolData))
			? 0
			: Number(hoursOutOfSchoolData);

		return {
			suspensionGrade,
			isExpulsion,
			isStaffViolence,
			isStudentViolence,
			hoursInSchool,
			hoursOutOfSchool,
		};
	});

	suspensionData.forEach((suspension) => {
		const gradeIndex = gradeValue(suspension.suspensionGrade);
		suspensionGradeData[gradeIndex]++;

		if (suspension.isExpulsion) {
			expulsionGradeData[gradeIndex]++;
		}
		if (suspension.isStaffViolence) {
			suspensionViolenceStaffData++;
		}
		if (suspension.isStudentViolence) {
			suspensionViolenceStudentsData++;
		}
		suspensionHoursInSchoolData[gradeIndex] += Number(suspension.hoursInSchool);
		suspensionHoursOutOfSchoolData[gradeIndex] += Number(
			suspension.hoursOutOfSchool,
		);
	});

	return {
		suspensionGradeData,
		expulsionGradeData,

		suspensionViolenceStaffData,
		suspensionViolenceStudentsData,

		suspensionHoursInSchoolData,
		suspensionHoursOutOfSchoolData,
	};
};

export const transformUseAllFormResponseValues = (responses, loading) => {
	if (loading) {
		return {
			graduatingStudentsData: [],
			studentPopulationData: [],
			communityPopulationData: [],
			schoolClosureDateData: [],
			schoolClosureReasonData: { counts: new Array(8).fill(0), rawData: [] },
			suspensionDateData: [],
			suspensionGradeData: { counts: new Array(13).fill(0), rawData: [] },
			expulsionData: [],
			expulsionGradeData: { counts: new Array(13).fill(0), rawData: [] },
			suspensionViolenceStudentsData: [],
			suspensionViolenceStaffData: [],
			suspensionHoursInSchoolData: [],
			suspensionHoursOutOfSchoolData: [],
		};
	}

	const {
		GRADUATING_STUDENTS: rawGraduatingStudentsData = [],
		STUDENT_POPULATION: rawStudentPopulationData = [],
		COMMUNITY_POPULATION: rawCommunityPopulationData = [],
		SCHOOL_CLOSURE_DATE: rawSchoolClosureDateData = [],
		SCHOOL_CLOSURE_REASON: rawSchoolClosureReasonData = [],
		SUSPENSION_DATE: rawSuspensionDateData = [],
		SUSPENSION_GRADE: rawSuspensionGradeData = [],
		EXPULSION: rawExpulsionData = [],
		//EXPULSION_GRADE: rawExpulsionGradeData = [],
		SUSPENSION_VIOLENCE_STUDENTS: rawSuspensionViolenceStudentsData = [],
		SUSPENSION_VIOLENCE_STAFF: rawSuspensionViolenceStaffData = [],
		SUSPENSION_HOURS_IN_SCHOOL: rawSuspensionHoursInSchoolData = [],
		SUSPENSION_HOURS_OUT_OF_SCHOOL: rawSuspensionHoursOutOfSchoolData = [],
	} = responses || {};

	const {
		suspensionGradeData,
		expulsionGradeData,

		suspensionViolenceStaffData,
		suspensionViolenceStudentsData,

		suspensionHoursInSchoolData,
		suspensionHoursOutOfSchoolData,
	} = transformSuspensionData(
		filterCompleted(rawSuspensionGradeData),
		filterCompleted(rawExpulsionData),
		filterCompleted(rawSuspensionViolenceStaffData),
		filterCompleted(rawSuspensionViolenceStudentsData),
		filterCompleted(rawSuspensionHoursInSchoolData),
		filterCompleted(rawSuspensionHoursOutOfSchoolData),
	);

	return {
		graduatingStudentsData: filterCompleted(rawGraduatingStudentsData),
		studentPopulationData: filterCompleted(rawStudentPopulationData),
		communityPopulationData: filterCompleted(rawCommunityPopulationData),
		schoolClosureDateData: filterCompleted(rawSchoolClosureDateData),
		schoolClosureReasonData: transformClosureReasons(
			rawSchoolClosureReasonData,
		),

		suspensionDateData: filterCompleted(rawSuspensionDateData),
		suspensionGradeData,
		expulsionGradeData,

		expulsionData: [],
		suspensionViolenceStudentsData,
		suspensionViolenceStaffData,

		suspensionHoursInSchoolData,
		suspensionHoursOutOfSchoolData,
	};
};
