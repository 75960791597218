import React from "react";
import { Box, LinearProgress, Typography } from "@mui/material";

export const ProgressBar = ({
	percentage,
	label,
	color = "#0e6ba8",
	id,
	labelPosition = "above",
	labelAlignment = "left",
	labelBold = true,
	sx = {},
}) => {
	return (
		<Box
			sx={{
				width: "100%",
				display: "flex",
				flexDirection: labelPosition === "above" ? "column" : "column-reverse",
				gap: "0.625rem",
				marginY: "0.5rem",
				...sx,
			}}
		>
			{label && (
				<Typography
					variant="body1"
					sx={{
						fontSize: "1rem",
						fontFamily: "Effra, sans-serif",
						fontWeight: labelBold ? "700" : "400",
						color: "#0D1120",
						textAlign: labelAlignment,
					}}
				>
					{label}
				</Typography>
			)}
			<Box
				sx={{
					border: "1px solid #0e6ba8",
					borderRadius: "0.9375rem",
					padding: "0.125rem",
				}}
				role="progressbar"
				id={id}
				aria-label={label}
				aria-valuenow={percentage}
				aria-valuemin={0}
				aria-valuemax={100}
			>
				<LinearProgress
					variant="determinate"
					value={percentage}
					sx={{
						borderRadius: "0.625rem",
						height: "0.625rem",
						"& .MuiLinearProgress-bar": {
							backgroundColor: color,
						},
						backgroundColor: "#FFF",
					}}
				/>
			</Box>
		</Box>
	);
};
