import AirtableTable from "../../AirtableTable";

export class SchoolScheduleTable extends AirtableTable {
  constructor(instance) {
    super(instance, "School Schedule");

    this.fields = {
      "Name": "fldrVRt6pQGwcl0vm",
      "Calendar": "fldbgEhEinWhb4dv9",
      "Grade Grouping": "fldIl1IEhZQdXDoMw",
      "AM Start": "fldL0J1bsnfRv4VRq",
      "AM Finish": "fldjTJOTV3FsP3XF9",
      "Recess 1 Start": "fldLeLAly2qk08B2q",
      "Recess 1 Finish": "fld3VxPgNhF4xT2ix",
      "PM Start": "fldz4gSVgl3om23od",
      "PM Finish": "fld3FUQQgtgBUlXnX",
      "Recess 2 Start": "fldl6Q1Lx2JK4Jv99",
      "Recess 2 Finish": "fldEmJCZyfchLbdWH",
      "Rotational Minutes": "fldmMtGZ3FciFYBbI",
      "Minutes of Instruction": "fld1rVkVN2zyn3e5l",
    };
  }

}
