import { useEffect, useState } from "react";
import airtable from "../../../airtables/PDAirtable";

const useHook = (orderId, requestIds, refresher) => {
  const [loading, setLoading] = useState(true);
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialize = async () => {
    const res = await airtable.resourceRequests.listByIds(requestIds)
    setRequests([...res]);
    setLoading(false);
  }

  const updateRequestQuantity = (reqId, value) => {
    const reqNo = requests.findIndex(req => req.id === reqId);
    const reqlist = [...requests];
    reqlist[reqNo]["Quantity"] = value;
    setRequests(reqlist);
  }

  const removeRequest = async (reqId) => {
    await airtable.resourceRequests.delete(reqId);
    const reqlist = requests.filter(req => req.id === reqId);
    setRequests(reqlist);
  }

  const rejectOrder = async () => {
    await airtable.resourceOrders.update(orderId, {
      "Status": "Rejected"
    });
    refresher();
  }

  const approveOrder = async () => {
    await airtable.resourceRequests.multipleUpdate(requests.map(req => ({
      id: req.id,
      param: {
        "Quantity": req.Quantity
      }
    })));
    await airtable.resourceOrders.update(orderId, {
      "Status": "Approved"
    });
    refresher();
  }

  return {
    loading,
    requests,
    updateRequestQuantity,
    removeRequest,
    rejectOrder,
    approveOrder
  }
}

export default useHook;