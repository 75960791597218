import { useMutation } from "@tanstack/react-query";
import { createInstance } from "../mutations/create-instance";
import { updateFormResponseValue } from "../mutations/update-response-value";
import { deleteValues } from "../mutations/delete-values";
import { findInstanceValueIds } from "../util/find-instance-value-ids";
import { updateResponse } from "../mutations/update-response";

export const useResponseHandler = (form) => {
	const calculateProgress = () => {
		const groupProgress = form.pages.flatMap((page) =>
			page.groups.map((group) => {
				if (group.is_completed)
					return {
						group_id: group.id,
						is_completed: true,
					};
				const isCompleted = group.repeatable
					? group.is_completed
					: group.fields.every((field) =>
							group.instances.some((instance) =>
								instance.fields.some(
									(response) =>
										response.field_id === field.id && response.completed,
								),
							),
						);

				return { group_id: group.id, is_completed: isCompleted };
			}),
		);
		const pageProgress = form.pages.map((page) => {
			const completedGroups = page.groups.filter((group) =>
				groupProgress.some((gp) => gp.group_id === group.id && gp.is_completed),
			).length;

			const progressPercentage =
				(completedGroups / page.groups.length) * 100 || 0;
			return { page_id: page.id, completed_percentage: progressPercentage };
		});

		const completedPages = pageProgress.filter(
			(pp) => pp.completed_percentage === 100,
		).length;
		const formProgress = (completedPages / form.pages.length) * 100 || 0;
		const agreedToTerms = form.progress?.agreed_to_terms || false;
		return {
			form_progress: formProgress,
			page_progress: pageProgress,
			group_progress: groupProgress,
			agreed_to_terms: agreedToTerms,
		};
	};

	/**
	 * Update form progress and sync with the backend if changed.
	 */
	const updateProgress = () => {
		const newProgress = calculateProgress();
		if (JSON.stringify(form.progress) !== JSON.stringify(newProgress)) {
			form.progress = newProgress;
			handleUpdateResponse({ progress: form.progress });
		}
	};

	const updateFormResponse = useMutation({
		mutationFn: (data) => updateResponse(form.response_id, data),
		onSuccess: (newResponse) => {
			form.updateWithResponse(newResponse);
		},
		onError: (error) => {
			console.error("Error updating form response:", error);
		},
	});

	const handleUpdateResponse = (data) => {
		updateFormResponse.mutate(data);
	};

	const updateField = useMutation({
		mutationFn: ({ id, value }) => updateFormResponseValue(id, value, true),
		onSuccess: async (newField) => {
			await form.updateField(newField.id, newField.value);
			updateProgress();
		},
		onError: (error) => {
			console.error("Error updating field:", error);
		},
	});

	const handleUpdateField = (id, value) => {
		updateField.mutate({ id, value });
	};

	/**
	 * Manage repeatable group instances and recalculate progress.
	 */
	const createNewInstance = useMutation({
		mutationFn: (group) => createInstance(group, form.response_id),
		onSuccess: (newInstance, group) => {
			group.addInstance(newInstance);
		},
		onError: (error) => {
			console.error("Error creating instance:", error);
		},
	});

	const deleteFormInstance = useMutation({
		mutationFn: ({ group, instance_number }) =>
			deleteValues(findInstanceValueIds(group, instance_number)),
		onSuccess: (newInstance, variables) => {
			const { group, instance_number } = variables;
			if (group && typeof group.deleteInstance === "function") {
				group.deleteInstance(instance_number);
			} else {
				console.error("Group or deleteInstance function is not defined");
			}
		},
		onError: (error) => {
			console.error("Error creating instance:", error);
		},
	});

	const handleAddInstance = (group) => {
		createNewInstance.mutate(group);
	};

	const handleDeleteInstance = (group, instance_number) => {
		deleteFormInstance.mutate({ group, instance_number });
	};

	const handleAgreeToTerms = (agreed) => {
		form.progress.agreed_to_terms = agreed;

		const newProgress = calculateProgress();
		form.progress = newProgress;
		handleUpdateResponse({ progress: form.progress });
	};

	/**
	 * Mark a group as complete and recalculate progress.
	 */
	const handleChangeGroupCompletion = (group) => {
		group.is_completed = !group.is_completed;
		updateProgress();
	};

	return {
		handleUpdateResponse,
		handleUpdateField,
		handleAddInstance,
		handleDeleteInstance,
		handleChangeGroupCompletion,
		handleAgreeToTerms,
		updateProgress,
	};
};
