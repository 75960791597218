import React, { useState } from "react";
import { TextField } from "@mui/material";
import InputWrapper from "./InputWrapper";

const LongTextInput = ({
	id,
	value = "",
	disabled,
	onChange = () => {},
	error = false,
	multiple = true,
	rows = 2,
	max_length,
	sx = {},
	heading,
	description,
	placeholder,
	helperText,
}) => {
	const [inputValue, setInputValue] = useState(value);
	const [inputError, setInputError] = useState(error);

	const filledInput = inputValue?.length > 0;

	const handleInputChange = (e) => {
		const { value } = e.target;

		setInputValue(value);
		setInputError(false);

		onChange(value);
	};

	return (
		<InputWrapper
			title={heading}
			description={description}
			sx={sx}
			id={id}
			inputError={inputError}
			filledInput={filledInput}
			helperText={helperText}
		>
			<TextField
				id={id}
				name={id}
				aria-describedby="helper-text"
				placeholder={placeholder}
				multiline={multiple}
				rows={rows}
				value={inputValue}
				disabled={disabled}
				onChange={(e) => handleInputChange(e)}
				error={inputError}
				inputProps={{ maxLength: max_length }}
			/>
		</InputWrapper>
	);
};

export default LongTextInput;
