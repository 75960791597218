import React from "react";
import Loading from "../Loading";

const SectionLoader = () => {
  return (
    <div style={{ padding: 40, textAlign: "center" }}>
      <Loading size={32} />
    </div>
  )
}

export default SectionLoader;
