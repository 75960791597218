import React, { Fragment, useState } from "react";
import "./styles.scss";
import { Button, Modal } from "reactstrap";
import useHook from "./use-hook";
import SectionLoader from "../../../components/SectionLoader";
import ResourceRequestItem from "../../SchoolResources/components/ResourceRequestItem";
import { confirmAlert } from "react-confirm-alert";
import { hideLoading, showLoading } from "../../../utils/loading";

const ReviewResourceRequestModal = ({
  resourceOrder,
  toggle,
  refresher
}) => {
  const [error, setError] = useState("");
  const {
    loading,
    requests,
    updateRequestQuantity,
    removeRequest,
    rejectOrder,
    approveOrder
  } = useHook(resourceOrder.id, resourceOrder["Requests"], refresher);
  const canEdit = resourceOrder["Status"] === "Pending Approval";

  const reject = async () => {
    try {
      showLoading("Rejecting");
      await rejectOrder();
      hideLoading();
      toggle();
    } catch (error) {
      setError("Failed: unknown issue");
    }
  }

  const approve = async () => {
    try {
      showLoading("Approving");
      await approveOrder();
      hideLoading();
      toggle();
    } catch (error) {
      setError("Failed: unknown issue");
    }
  }

  return (
    <Modal
      className="modal-dialog-centered review-resource-request-modal"
      isOpen
      toggle={toggle}
      size="lg"
    >
      <div className="modal-header">
        <h5 className="modal-title" id="modal-title-default">{resourceOrder["Teacher Name"]} (#2000{resourceOrder["Order ID"]})</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={toggle}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <center><p>Below are the resource(s) and quantity of each requested in this order:</p></center>
        {loading ? (
          <SectionLoader />
        ) : (
          <div className="review-request-list">
            {requests.map((request, i) => (
              <ResourceRequestItem
                key={i}
                data={request}
                edit={canEdit}
                updateQuantity={(value) => updateRequestQuantity(request.id, value)}
                remove={() => {
                  confirmAlert({
                    title: "Connected North",
                    message: "Are you sure you want to remove this item?",
                    buttons: [
                      {
                        label: "OK",
                        onClick: async () => {
                          showLoading();
                          await removeRequest(request.id)
                          hideLoading();
                        },
                      },
                      {
                        label: "Cancel",
                        onClick: () => { },
                      },
                    ],
                  });
                }}
              />
            ))}
          </div>
        )}
        <div className="error">{error}</div>
      </div>
      <div className="modal-footer">
        {canEdit ? (
          <Fragment>
            <Button
              color="outline-primary"
              type="button"
              onClick={reject}
            >X Reject Request</Button>
            <Button
              color="primary"
              type="button"
              onClick={approve}
            ><i className="fa fa-check" />&nbsp;Approve Request & Submit</Button>
          </Fragment>
        ) : (
          <Button
            color="outline-primary"
            type="button"
            onClick={() => toggle()}
          >Close</Button>
        )}
      </div>
    </Modal>
  )
}

export default ReviewResourceRequestModal;