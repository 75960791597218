const form_status = [
	"draft",
	"active",
	"archived",
	"template",
	"test",
	"inactive",
];
const unit_type = ["dollars", "hours", "days"];
const field_types = [
	"short text",
	"long text",
	"number",
	"date",
	"file",
	"single select",
	"multi select",
	"radio",
	"checkbox",
];
const option_types = ["single select", "multi select", "radio", "checkbox"];
const response_status = ["not-started", "draft", "submitted", "archived"];

export { form_status, unit_type, field_types, option_types, response_status };
