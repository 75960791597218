import React, { useState, useEffect } from 'react';
import { Form } from 'reactstrap';
import { Box } from '@mui/material';
import { RepeatableAccordion } from '../components/RepeatableAccordion';
import DailySchedule from '../components/DailySchedule';
import { PrevNextButton } from '../../PrincipalReporting/components/PrevNextButton';

const GradesHour = ({school, schoolHours, onTimesChange, handleNext, handlePrev}) => {
  // Separate state for each grade level
  const [timesKindergarten, setTimesKindergarten] = useState({
    "AM Start": '',
    "AM Finish": '',
    "Recess 1 Start": '',
    "Recess 1 Finish": '',
    "PM Start": '',
    "PM Finish": '',
    "Recess 2 Start": '',
    "Recess 2 Finish": ''
  });

  const [times1To6, setTimes1To6] = useState({
    "AM Start": '',
    "AM Finish": '',
    "Recess 1 Start": '',
    "Recess 1 Finish": '',
    "PM Start": '',
    "PM Finish": '',
    "Recess 2 Start": '',
    "Recess 2 Finish": ''
  });

  const [times7To12, setTimes7To12] = useState({
    "AM Start": '',
    "AM Finish": '',
    "Recess 1 Start": '',
    "Recess 1 Finish": '',
    "PM Start": '',
    "PM Finish": '',
    "Recess 2 Start": '',
    "Recess 2 Finish": '',
    "Rotational Minutes": ''
  });

  const [expanded, setExpanded] = useState();

  const displayKindergarten = school["SG"] === "K";
  const display1To6 = (school["SG"] === "K" && school["EG"] >= 1) || school["SG"] <=6;
  const display7To12 = school["EG"] >= 7;

  // Function to handle time updates and send data to parent
  const handleTimeChange = (grade, newTimes) => {
    onTimesChange({...schoolHours, [grade]: newTimes });
  };

  useEffect(() => {
    if(schoolHours) {
      if (display1To6 && schoolHours["1To6"]) setTimes1To6(schoolHours["1To6"]);
      if (display7To12 && schoolHours["7To12"]) setTimes7To12(schoolHours["7To12"]);
      if (displayKindergarten && schoolHours["Kindergarten"]) setTimesKindergarten(schoolHours["Kindergarten"]);
    }
  }, [schoolHours, display1To6, display7To12, displayKindergarten])

  useEffect(() => {
    document.title = "Grade Groupings - School Calendar - Connected North";
  }, []);
  
  return (
    <>
      <h2>Select Hours for Grade Groupings</h2>
      <p className="mt-4">
        Below, you can enter the start and end times for each of your grade groupings, including recess breaks. Your AM finish time should be when your school breaks for lunch, and your PM start time should be when your school returns from lunch break.
      </p>

      <Form>
        <Box sx={{ maxWidth: '800px', m: 'auto', pt: "1rem" }}>
          { displayKindergarten && (
            <Box sx={{ mb: 2 }}>
              <RepeatableAccordion header="Kindergarten" id="Kindergarten" expanded={expanded === "Kindergarten"} onChange={() => setExpanded("Kindergarten")}>
                <DailySchedule
                  times={timesKindergarten}
                  setTimes={(newTimes) => handleTimeChange('Kindergarten', newTimes)}
                  maxTime={360}
                  showRotationalMinutes={false}
                />
              </RepeatableAccordion>
            </Box>
          )}

          { display1To6 && (
            <Box sx={{ mb: 2 }}>
              <RepeatableAccordion header="Grade 1-6" id="Grade 1-6" expanded={expanded === "Grade 1-6"} onChange={() => setExpanded("Grade 1-6")}>
                <DailySchedule
                  times={times1To6}
                  setTimes={(newTimes) => handleTimeChange('1To6', newTimes)}
                  maxTime={360}
                  showRotationalMinutes={false}
                />
              </RepeatableAccordion>
            </Box>
          )}

          { display7To12 && (
            <Box sx={{ mb: 2 }}>
              <RepeatableAccordion header="Grade 7-12" id="Grade 7-12" expanded={expanded === "Grade 7-12"} onChange={() => setExpanded("Grade 7-12")}>
                <DailySchedule
                  times={times7To12}
                  setTimes={(newTimes) => handleTimeChange('7To12', newTimes)}
                  maxTime={390}
                  showRotationalMinutes={true}
                />
              </RepeatableAccordion>
            </Box>
          )}
        </Box>

        <Box sx={{ marginTop: { xs: "1.25rem", md: "1.875rem" } }}>
          <PrevNextButton
            onNextClick={handleNext}
            onPrevClick={handlePrev}
          />
        </Box>
      </Form>
    </>
  );
};

export default GradesHour;
