import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Grid } from "@mui/material";
import { OverviewPage } from "./OverviewPage";
import { FormPage } from "./FormPage";
import { SideBar } from "./SideBar";
import { useProgress } from "./ProgressContext";
import { disabledSections, errorSections } from "../constants/mock-sections";
import { Group } from "./Group";
import { GROUP_KEYS, INSTANCE_KEYS } from "../constants/form-keys";
import { Instance } from "./Instance";
import { RepeatableButtons } from "./RepeatableButtons";
import { debounce } from "lodash";
import {
	convertTypeToString,
	convertStringToType,
} from "../util/convert-input-value-type";
import { useTranslation } from "react-i18next";
import { InputField } from "./inputs";
import { option_types, response_status } from "../constants/enumerated-types";
import { uploadFile } from "../mutations/upload-file";
import { removeFile } from "../mutations/remove-file";
import airtableInstance from "../../../airtables";

export const FormLayout = ({ restricted, options: fieldOptions }) => {
	const { t } = useTranslation();
	const {
		response,
		activeRoute,
		routes,
		handleRouteNext,
		handleRouteChange,
		handleRoutePrev,
		handleUpdateResponse,
		handleUpdateField,
		handleAddInstance,
		handleDeleteInstance,
		handleChangeGroupCompletion,
		handleAgreeToTerms,
		updateProgress,
		progress,
		status,
	} = useProgress();

	const editMode = status !== "submitted" && !restricted;
	const isLastRoute = routes.indexOf(activeRoute) === routes.length - 1;

	const debouncedUpdatesRef = useRef({});

	const updateField = useCallback(
		(responseId, updatedValue, type) => {
			const stringValue = convertTypeToString(updatedValue, type);
			handleUpdateField(responseId, stringValue);
		},
		[handleUpdateField],
	);

	const debouncedUpdateField = useCallback(
		(responseId, updatedValue, type) => {
			if (debouncedUpdatesRef.current[responseId]) {
				debouncedUpdatesRef.current[responseId].cancel();
			}

			debouncedUpdatesRef.current[responseId] = debounce(() => {
				updateField(responseId, updatedValue, type);
			}, 300);

			debouncedUpdatesRef.current[responseId]();
		},
		[updateField],
	);

	const handleInputChange = (responseId, updatedValue, type) => {
		const valueToSave =
			type === "multi select"
				? Array.isArray(updatedValue)
					? updatedValue
					: []
				: updatedValue;
		debouncedUpdateField(responseId, valueToSave, type);
	};

	const handleUpdateResponseStatus = async (status) => {
		if (response_status.includes(status)) {
			// Only increment Principal Reports Submitted if this is the first submission
			if (status === "submitted" && !response.submitted_at) {
				const teacher = await airtableInstance.teachers.select(response.user_id);
				if (teacher) {
					await airtableInstance.teachers.update(teacher.id, {
						"Principal Reports Submitted": (teacher["Principal Reports Submitted"] || 0) + 1
					});
				}
			}

			handleUpdateResponse({
				status,
				submitted_at: "NOW()",
			});
		}
	};

	const [expandedAccordions, setExpandedAccordions] = useState({});

	const handleAccordionToggle = (groupId, instanceNumber) => {
		setExpandedAccordions((prevState) => ({
			...prevState,
			[groupId]: {
				...prevState[groupId],
				[instanceNumber]: prevState[groupId]?.[instanceNumber] ? false : true,
			},
		}));
	};

	const handleInstanceAddition = async (group) => {
		await handleAddInstance(group);
	};

	const debouncedUpdateResponse = debounce((data) => {
		handleUpdateResponse(data);
	}, 300);

	// Function to dynamically resolve headings for instances
	const getInstanceHeading = (firstInstance, fields) => {
		if (!fields) return firstInstance.value;

		const field = fields.find((field) => field.id === firstInstance.field_id);
		if (!field) return firstInstance.value;

		// Handle MultiSelectInput and SingleSelectInput with translations
		if (field.type === "multi select" || field.type === "single select") {
			if (field.function_option === "gradesBySchoolId") {
				const translatedOptions = t("grade-list", {
					returnObjects: true,
					defaultValue: {},
				});
				const label =
					typeof firstInstance.value === "string"
						? translatedOptions[firstInstance.value]
						: firstInstance.value
								.map((val) => translatedOptions[val])
								.filter(Boolean)
								.join(", ");

				return label || firstInstance.value;
			} else {
				const translatedOptions = t(
					`db_fields.${field.translation_id || "default"}.options`,
					{ returnObjects: true, defaultValue: {} },
				);

				// Find the label for the current value
				const label =
					typeof firstInstance.value === "string"
						? translatedOptions[firstInstance.value]
						: firstInstance.value
								.map((val) => translatedOptions[val])
								.filter(Boolean)
								.join(", ");

				return label || firstInstance.value;
			}
		}
		return firstInstance.value;
	};

	useEffect(() => {
		updateProgress();
	}, [updateProgress]);

	useEffect(() => {
		const currentDebouncedUpdates = debouncedUpdatesRef.current;
		return () => {
			Object.values(currentDebouncedUpdates).forEach((debouncedFn) => {
				if (debouncedFn?.cancel) debouncedFn.cancel();
			});
		};
	}, []);

	return (
		<Grid
			container
			spacing={4}
			sx={{
				display: "flex",
				flexDirection: { xs: "column-reverse", md: "row" },
			}}
		>
			<Grid item xs={12} md={8}>
				<Box>
					{activeRoute ? (
						activeRoute?.id === "overview" ? (
							<>
								<OverviewPage
									response={response}
									agreeToTerms={progress?.agreed_to_terms}
									handleRouteNext={handleRouteNext}
									handleAgreement={handleAgreeToTerms}
									handleUpdateResponse={debouncedUpdateResponse}
								/>
							</>
						) : (
							<>
								<FormPage
									translation_id={`activeRoute?.translation_id`}
									heading={t(
										`db_group_pages.${activeRoute.translation_id || "default"}.heading`,
									)}
									description={t(
										`db_group_pages.${activeRoute.translation_id || "default"}.description`,
									)}
									handleRouteNext={handleRouteNext}
									handleRoutePrev={handleRoutePrev}
									hasNext={!isLastRoute}
								>
									{activeRoute?.groups?.map((group) => {
										const gradeOptions = t("grade-list", {
											returnObjects: true,
											defaultValue: {},
										});

										const gradeOrder = Object.keys(gradeOptions);

										const { fields, is_completed, required } = group;

										const sortedInstances =
											group.fields[0].function_option === "gradesBySchoolId"
												? [...group[GROUP_KEYS.INSTANCES]].sort((a, b) => {
														const gradeA = a.fields[0].value;
														const gradeB = b.fields[0].value;

														const indexA = gradeOrder.indexOf(gradeA);
														const indexB = gradeOrder.indexOf(gradeB);

														const adjustedIndexA =
															indexA === -1 ? Number.MAX_SAFE_INTEGER : indexA;
														const adjustedIndexB =
															indexB === -1 ? Number.MAX_SAFE_INTEGER : indexB;

														if (adjustedIndexA === adjustedIndexB) {
															return (
																group[GROUP_KEYS.INSTANCES].indexOf(a) -
																group[GROUP_KEYS.INSTANCES].indexOf(b)
															);
														}

														return adjustedIndexA - adjustedIndexB;
													})
												: group[GROUP_KEYS.INSTANCES];
										return (
											<Group
												key={group.id}
												heading={t(
													`db_field_groups.${group.translation_id || "default"}.heading`,
												)}
												description={t(
													`db_field_groups.${group.translation_id || "default"}.description`,
												)}
												buttons={
													group.repeatable ? (
														<RepeatableButtons
															disabled={!editMode}
															onAddEvent={() => handleInstanceAddition(group)}
															onFinishAdding={() =>
																handleChangeGroupCompletion(group)
															}
															addLabel={t("form_buttons.add")}
															finishLabel={
																group.is_completed
																	? t("form_buttons.finished_repeatable")
																	: t("form_buttons.finish_repeatable")
															}
															checked={is_completed}
														/>
													) : null
												}
											>
												{sortedInstances?.map((instance, i) => {
													const firstInstance =
														(instance?.fields?.length && instance.fields[0]) ||
														null;
													const instanceHeading = firstInstance
														? getInstanceHeading(firstInstance, fields)
														: null;
													const disableDelete =
														group[GROUP_KEYS.INSTANCES].length <= 1 &&
														required === true;
													const instance_number =
														instance[INSTANCE_KEYS.INSTANCE_NUMBER];
													return (
														<Instance
															key={i}
															heading={instanceHeading}
															repeatable={group[GROUP_KEYS.REPEATABLE]}
															disableDelete={
																disableDelete || !editMode || is_completed
															}
															onDeleteEvent={() => {
																handleDeleteInstance(
																	group,
																	instance[INSTANCE_KEYS.INSTANCE_NUMBER],
																);
															}}
															expanded={
																expandedAccordions[group.id]?.[
																	`${instance_number}`
																] || false
															}
															onChange={() =>
																handleAccordionToggle(group.id, instance_number)
															}
														>
															{instance[INSTANCE_KEYS.FIELDS]?.map(
																(response, j) => {
																	const field = fields.find(
																		(field) => field.id === response.field_id,
																	);
																	const value = convertStringToType(
																		response.value,
																		field.type,
																	);
																	const hasOptions = option_types.includes(
																		field.type,
																	);
																	const translatedOptions = hasOptions
																		? t(
																				`db_fields.${field.translation_id || "default"}.options`,
																				{
																					returnObjects: true,
																					defaultValue: {},
																				},
																			)
																		: [];
																	const options = Object.entries(
																		translatedOptions,
																	).map(([value, label]) => ({
																		value,
																		label,
																	}));
																	const function_options = field.function_option
																		? fieldOptions[field.function_option]
																		: null;

																	// translation handling for dynamic teacher lists.
																	let translationID =
																		field.function_option ===
																		"inuktutTeachersBySchoolId"
																			? "langTeacherNames"
																			: field.function_option ===
																				  "teachersBySchoolId"
																				? "langOtherTeacherNames"
																				: field.translation_id;

																	return (
																		<InputField
																			id={response.id}
																			key={response.id}
																			type={field.type}
																			value={value}
																			heading={t(
																				`db_fields.${translationID || "default"}.heading`,
																			)}
																			description={t(
																				`db_fields.${field.translation_id || "default"}.description`,
																			)}
																			helperText={t(
																				`db_fields.${field.translation_id || "default"}.helperText`,
																			)}
																			placeholder={t(
																				`db_fields.${field.translation_id || "default"}.placeholder`,
																			)}
																			options={
																				field.function_option
																					? function_options || options
																					: options
																			}
																			unit={field.unit}
																			decimal_places={field.decimal_places}
																			max_value={field.max_value}
																			min_value={field.min_value}
																			required={field.required}
																			disabled={
																				!editMode ||
																				(is_completed && group.repeatable)
																			}
																			error={false}
																			onChange={(updatedValue) =>
																				handleInputChange(
																					response.id,
																					updatedValue,
																					field.type,
																				)
																			}
																			onFileUpload={async (file) => {
																				const upload = await uploadFile(
																					file,
																					response.id,
																				);
																				return upload;
																			}}
																			onFileRemove={async (file) => {
																				const removal = await removeFile(
																					file,
																					response.id,
																				);
																				return removal;
																			}}
																		/>
																	);
																},
															)}
														</Instance>
													);
												})}
											</Group>
										);
									})}
								</FormPage>
							</>
						)
					) : null}
				</Box>
			</Grid>
			<Grid
				item
				xs={12}
				md={3}
				sx={{
					position: "sticky",
					top: 0,
					zIndex: 1,
					// backgroundColor: "#f0f7fe",
				}}
			>
				<Box
					sx={{
						position: "sticky",
						top: { xs: 0, md: "5.9375rem" },
					}}
				>
					<SideBar
						sections={routes}
						currentSection={activeRoute}
						errorSections={errorSections}
						disabledSections={disabledSections}
						handleRouteChange={handleRouteChange}
						buttonText={
							response.submitted_at && status !== "submitted"
								? t("principal.sidebar.resubmit-btn-text")
								: status === "submitted"
								? t("principal.sidebar.edit-btn-text")
								: t("principal.sidebar.submit-btn-text")
						}
						buttonHidden={!isLastRoute && status !== "submitted" && !response.submitted_at}
						buttonDisabled={progress?.form_progress < 100}
						agreedToTerms={progress?.agreed_to_terms}
						onSubmit={() => {
							const isDraft = status === "draft";
							handleUpdateResponseStatus(isDraft ? "submitted" : "draft");
						}}
					/>
				</Box>
			</Grid>
		</Grid>
	);
};
