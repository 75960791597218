const dayColor = {
	FP: "#ff8787",
	LP: "#ff8787",
	FT: "#c49cf8",
	LT: "#c49cf8",
	FS: "#ffe5a5",
	LS: "#ffe5a5",
	P: "#d2e2fc",
	A: "#cbebf8",
	C: "#cef5f2",
	D: "#d8f6d6",
	O: "#ffeabc",
	N: "#f9e0cf",
	H: "#f8d5df",
	S: "#f4d4f9",
};

export default dayColor;
